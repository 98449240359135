<template>

<div id="authentication-modal" ref="authentication-modal" tabindex="-1" aria-hidden="true" class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full">
    <div class="relative p-4 w-full max-w-md h-full md:h-auto">
        <!-- Modal content -->
        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <button type="button" class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white" data-modal-toggle="authentication-modal">
                <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>  
            </button>
            <div class="py-6 px-6 lg:px-8">
                <h3 class="mb-4 text-xl font-medium text-gray-900 dark:text-white">Sign in to our platform</h3>
                <form class="space-y-6" @submit.prevent="loginEmail">
                    <div>
                        <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Your email</label>
                        <input v-model="user.email" type="email" name="email" id="email" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" placeholder="name@company.com" required>
                    </div>
                    <div>
                        <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Your password</label>
                        <input v-model="user.password" type="password" name="password" id="password" placeholder="••••••••" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" required>
                    </div>
                    <div class="flex justify-between">
<!--                         <div class="flex items-start">
                            <div class="flex items-center h-5">
                                <input id="remember" type="checkbox" value="" class="w-4 h-4 bg-gray-50 rounded border border-gray-300 focus:ring-3 focus:ring-blue-300 dark:bg-gray-600 dark:border-gray-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800" required>
                            </div>
                            <label for="remember" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Remember me</label>
                        </div>
 -->                        <a href="#" class="text-sm text-blue-700 hover:underline dark:text-blue-500">Lost Password?</a>
                    </div>
                    <button data-modal-toggle="authentication-modal" type="submit" class="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Login to your account</button>
                </form>

<!--                 <button data-modal-toggle="authentication-modal" 
                      class="mt-5 w-full bg-green-200 hover:bg-green-300 focus:ring-4 focus:outline-none focus:ring-green-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" 
                      @click="login">
                      <img src="../assets/img/google_logo.webp" width="25px" height="25px" />
                      Login with Google
                </button>
 -->
            </div>
        </div>
    </div>
</div> 
</template>

<script>
import { auth } from "../firebaseDb";
//import Vue from 'vue';

export default {
  name: "LoginModal",
  data() {
    return {
      showOK: false,
      showError: false,
      showInUseAlert: false,
      showWrongAlert: false,
      confirmPass: "",
      //showSignupForm: false,
      showForgotForm: false,
      showNoPassAlert: false,
      showNoUserAlert: false,
      user: {
        name: "",
        email: "",
        password: "",
      },
    };
  },
  methods: {
    loginEmail() {
      auth.loginEmail(this.user.email, this.user.password).then((res) => {
        if (res.includes("wrong-password")) {
          this.showNoPassAlert = true;
        } else if (res.includes("user-not-found")) {
          this.showNoUserAlert = true;
        }
      });
    },
    close() {
      this.$refs["authentication-modal"].hide();
    },
    login() {
      auth.login();
    },
    forgetPassword() {
      auth
        .resetEmail(this.user.email)
        .then((res) => {
          console.log(res);
          //alert("Tjek din email for at nulstille dit passord!");
          this.user = {
            email: "",
          };
          if (res == "ok") {
            this.showOK = true;
          } else {
            this.showError = true;
          }
        })
        .catch((error) => {
          alert(error);
        });
    },
  },
  computed: {
    validatePasswordLength() {
      return this.user.password.length == 0 || this.user.password.length > 5;
    },
    validatePasswords() {
      return (
        this.confirmPass.length == 0 || this.user.password == this.confirmPass
      );
    },
  },
};
</script>
