<template>
  <div v-if="admin" class="dashboard p-4">

    <div class="wrapper-button mt-3">
      <select v-model="selectedPath" id="cats"
        class="w-full lg:w-3/12 float-none lg:float-left bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 mr-5 mb-5">
        <option value="" disabled>Please select category</option>
        <option v-if="beepruser && beepruser.uid" :value="'favs:' + beepruser.uid">My Favorites</option>

        <option disabled>──────────EAN Groups──────────</option>
        <option v-if="clientData && clientData.eans" v-for="eangroup in clientData.eans.sort()" :key="eangroup" :value="'eans:'+eangroup.replace(/[\W_]+/g, '') + ':' + eangroup">
          {{ eangroup.charAt(0).toUpperCase() + eangroup.slice(1) }}
        </option>

        <option disabled>──────────Categories──────────</option>
        <option v-if="clientData && clientData.paths" v-for="path in clientData.paths.sort()" :key="path" :value="'paths:'+path.replace(/[\W_]+/g, '') + ':' + path">
          {{ path }}
        </option>

        <option disabled>────────────Brands────────────</option>
        <option v-if="clientData && clientData.brands" v-for="brand in clientData.brands.sort()" :key="brand" :value="'brands:' + brand.replace(/[\W_]+/g, '') + ':' + brand">
        {{ brand }}
      </option>
      </select>
    </div>

    <Multiselect
      v-if="chains.length > 0"
      class="w-full md:w-2/12 md:ml-5 mb-5 float-left"
      v-model="selectedChains"
      mode="tags"
      placeholder="Filter chain/s"
      :close-on-select="false"
      :filter-results="true"
      :min-chars="10000"
      :resolve-on-load="false"
      :infinite="false"
      :clear-on-search="false"
      :delay="0"
      :searchable="true"
      :options="chains"
      :limit="100"
    />

    <Multiselect
      v-if="brands.length > 0"
      class="w-full md:w-2/12 md:ml-5 mb-5 float-left"
      v-model="selectedBrands"
      mode="tags"
      placeholder="Filter brand/s"
      :close-on-select="false"
      :filter-results="true"
      :min-chars="10000"
      :resolve-on-load="false"
      :infinite="false"
      :clear-on-search="false"
      :delay="0"
      :searchable="true"
      :options="brands"
    />

    <Multiselect
      v-if="plabels.length > 0"
      class="w-full md:w-2/12 md:ml-5 mb-5 float-left"
      v-model="selectedLabels"
      mode="tags"
      placeholder="Filter label/s"
      :close-on-select="false"
      :filter-results="true"
      :min-chars="10000"
      :resolve-on-load="false"
      :infinite="false"
      :clear-on-search="false"
      :delay="0"
      :searchable="true"
      :options="plabels"
    />

    <Multiselect
    v-if="units.length > 1" class="w-full md:w-1/12 md:ml-5 mb-5 float-left"
    v-model="selectedUnits"
    mode="tags"
    placeholder="Units"
    :close-on-select="false"
    :filter-results="true"
    :min-chars="10000"
    :resolve-on-load="false"
    :infinite="false"
    :clear-on-search="false"
    :delay="0"
    :searchable="true"
    :options="units"
  />

  <Multiselect
    v-if="uoms.length > 1" class="w-full md:w-1/12 md:ml-5 mb-5 float-left"
    v-model="selectedUoms"
    mode="tags"
    placeholder="UOMs"
    :close-on-select="false"
    :filter-results="true"
    :min-chars="10000"
    :resolve-on-load="false"
    :infinite="false"
    :clear-on-search="false"
    :delay="0"
    :searchable="true"
    :options="uoms"
  />

<!--    <div class="float-right">
        <p class="float-left mr-2 mt-1 text-lg">Show best alternative</p>
        <div @click="extended = !extended"
          class="w-14 h-8 float-right bg-gray-300 rounded-full p-1 duration-300 ease-in-out"
          :class="{ 'bg-green-400': extended }">
          <div class="bg-white w-6 h-6 rounded-full shadow-md transform duration-300 ease-in-out"
            :class="{ 'translate-x-6': extended }"></div>
        </div>
      </div>
 -->

    <div class="w-full clear-both my-5 mr-5">
      <segmentchart v-if="barlabels && barlabels.length > 0" :labels="barlabels" :series="barseries" name="Number of products per package size" key="bc" />
    </div>

    <div class="mt-5 bg-white dark:bg-gray-800 p-5 w-full rounded-md box-border shadow clear-both">
      <h2 class="font-bold float-left text-lg text-gray-800 dark:text-gray-200">
        All competitive products
      </h2>

      <download-excel
        class="float-right"
        :data="formatNumbers"
        :fields="json_fields2"
        :name="ean + '.xls'"
      >
        <Icon icon="iwwa:csv" height="24px" />
      </download-excel>

      <!--       <download-csv
        class="float-right"
        :data="currProducts"
        :fields="['ID', 'Name', 'Brand', 'LowestPrice', 'NormalizedUnit', 'PPU', 'chains']"
        :name="ean + '.csv'"
      >
        <Icon icon="iwwa:csv" height="24px" />
      </download-csv>
 -->

      <Icon
        class="float-right mr-5"
        icon="clarity:scatter-plot-outline-alerted"
        @click="isOpenSingle = true"
        height="24px"
      />

      <Icon
        class="float-right mr-5"
        icon="clarity:scatter-plot-outline-badged"
        @click="isOpenBrand = true"
        height="24px"
      />

      <div v-if="loading" class="wrapping-table mt-10" style="overflow: auto; max-height: 45vh">
        <base-progress :percentage="contentProgress" indeterminate></base-progress>
        <p v-for="i in allProducts" :key="i"></p>
      </div>
      <div v-else class="wrapping-table mt-10" style="overflow: auto; max-height: 45vh">
        <table
          class="w-full text-sm text-left text-gray-500 dark:text-gray-400 lg:overflow-auto overflow-x-scroll"
        >
          <thead
            class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 sticky top-0"
          >
            <tr>
              <th
                style="cursor: pointer"
                scope="col"
                class="uppercase px-6 py-3"
                @click="updateSort('Name')"
              >
                <Icon
                  v-if="sortBy == 'Name' && sortAsc"
                  class="float-left"
                  icon="la:sort-amount-up-alt"
                  height="18px"
                />
                <Icon
                  v-else-if="sortBy == 'Name' && !sortAsc"
                  class="float-left"
                  icon="la:sort-amount-down-alt"
                  height="18px"
                />
                Product
              </th>
              <th
                style="cursor: pointer"
                scope="col"
                class="uppercase px-6 py-3"
                @click="updateSort('Brand')"
              >
                <Icon
                  v-if="sortBy == 'Brand' && sortAsc"
                  class="float-left"
                  icon="la:sort-amount-up-alt"
                  height="18px"
                />
                <Icon
                  v-else-if="sortBy == 'Brand' && !sortAsc"
                  class="float-left"
                  icon="la:sort-amount-down-alt"
                  height="18px"
                />
                Brand
              </th>
              <th
                style="cursor: pointer"
                scope="col"
                class="uppercase px-6 py-3"
                @click="updateSort('LowestPrice')"
              >
                <Icon
                  v-if="sortBy == 'LowestPrice' && sortAsc"
                  class="float-left"
                  icon="la:sort-amount-up-alt"
                  height="18px"
                />
                <Icon
                  v-else-if="sortBy == 'LowestPrice' && !sortAsc"
                  class="float-left"
                  icon="la:sort-amount-down-alt"
                  height="18px"
                />
                Price
              </th>

              <th
                style="cursor: pointer"
                scope="col"
                class="uppercase px-6 py-3"
                @click="updateSort('NormalizedUnit')"
              >
                <Icon
                  v-if="sortBy == 'NormalizedUnit' && sortAsc"
                  class="float-left"
                  icon="la:sort-amount-up-alt"
                  height="18px"
                />
                <Icon
                  v-else-if="sortBy == 'NormalizedUnit' && !sortAsc"
                  class="float-left"
                  icon="la:sort-amount-down-alt"
                  height="18px"
                />
                Units
              </th>
              <th scope="col" class="uppercase px-6 py-3">Unit of Measure</th>
              <th
                style="cursor: pointer"
                scope="col"
                class="uppercase px-6 py-3"
                @click="updateSort('PPU')"
              >
                <Icon
                  v-if="sortBy == 'PPU' && sortAsc"
                  class="float-left"
                  icon="la:sort-amount-up-alt"
                  height="18px"
                />
                <Icon
                  v-else-if="sortBy == 'PPU' && !sortAsc"
                  class="float-left"
                  icon="la:sort-amount-down-alt"
                  height="18px"
                />
                Price per unit
              </th>
              <th scope="col" class="uppercase px-6 py-3">Labels</th>
              <th scope="col" class="uppercase px-6 py-3">Chains</th>

              <th scope="col" class="uppercase px-6 py-3">Graph</th>
            </tr>
          </thead>

          <tbody>
            <tr
              class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50"
              v-for="items in allProducts"
              :key="items.ID"
              :style="ean == items.ID ? 'background-color: aqua' : ''"
            >
              <td class="px-6 py-4">
                <a
                  target="_blank"
                  :href="
                    'https://beepr.'+country+'/products/' +
                    items.ID +
                    '/' +
                    items.Name.toLowerCase()
                      .trim()
                      .replace(/\./g, '')
                      .replace(/ø/g, 'oe')
                      .replace(/æ/g, 'ae')
                      .replace(/å/g, 'aa')
                      .replace(/[^a-z0-9]+/g, '-')
                  "
                  >{{ items.ImportName ? items.ImportName : items.Name }}</a
                >
              </td>
              <td class="px-6 py-4">
                {{ items.Brand }}
              </td>
              <td class="px-6 py-4">
                {{
                  Intl.NumberFormat("da-DK", { style: "currency", currency: "DKK" }).format(
                    items.LowestPrice.toFixed(2)
                  )
                }}
              </td>
              <td class="px-6 py-4">
                {{ items.NormalizedUnit }}
              </td>
              <td class="px-6 py-4">
                {{ items.NormalizedUom }}
              </td>
              <td class="px-6 py-4">
                {{
                  Intl.NumberFormat("da-DK", { style: "currency", currency: "DKK" }).format(
                    items.PPU.toFixed(2)
                  )
                }}
              </td>
              <td class="px-6 py-4">
                <ul v-if="items.Labels">
                  <li
                    style="float: left"
                    class="symbol"
                    v-for="l in items.Labels.sort(
                      (a, b) =>
                        (typeof a.ID === 'string') - (typeof b.ID === 'string') ||
                        a.ID > b.ID ||
                        -(a.ID < b.ID)
                    )"
                    :key="l.ID"
                  >
                    <img
                      style="float: left"
                      class="ml-1"
                      width="16"
                      v-if="
                        l.ID != 'budget' &&
                        l.ID != 'tilbud' &&
                        l.ID != 'frost' &&
                        l.ID != 'avisvare' &&
                        l.ID != 'Specialiteter' &&
                        l.ID != 'atten-plus' &&
                        l.ID != ''
                      "
                      :title="l.DisplayName"
                      :src="getLabelImg(l.ID)"
                    />
                  </li>
                </ul>
              </td>
              <td class="px-6 py-4">
                <img
                  v-for="c in filterChains(items.chains)"
                  :key="items.ID + c"
                  :src="require('../assets/img/' + c + '.webp')"
                  width="16"
                  style="float: left"
                  class="ml-1"
                  alt=""
                />
              </td>

              <td class="px-6 py-4">
                <button
                  @click="
                    getPrices(items.ID, items.chains).then((o) => (series = o));
                    isOpen = {};
                    isOpen[items.ID] = true;
                  "
                  class="text-black"
                  type="button"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    role="img"
                    width="20px"
                    height="20px"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 16 16"
                  >
                    <g fill="currentColor">
                      <path d="M15 13v1H1.5l-.5-.5V0h1v13h13Z" />
                      <path
                        d="M13 3.207L7.854 8.354h-.708L5.5 6.707l-3.646 3.647l-.708-.708l4-4h.708L7.5 7.293l5.146-5.147h.707l2 2l-.707.708L13 3.207Z"
                      />
                    </g>
                  </svg>
                </button>
                <product-modal :isOpen="isOpen" @closemodal="closeModal(items.ID)" :ean="items.ID" :name="items.Name" />

<!--                 <chart-modal
                  v-if="series"
                  :isOpen="isOpen"
                  @closemodal="closeModal(items.ID)"
                  :labels="labels"
                  :series="series"
                  :ean="items.ID"
                  :name="items.Name"
                />
 -->              </td>
            </tr>
          </tbody>

        </table>
      </div>
    </div>

    <scatter-chart-modal
      :isOpen="isOpenSingle"
      @closemodal="closeSingleModal()"
      :series="getScatter"
      :name="'By Chain'"
      :monthsback="monthsback"
    />

    <scatter-brand-modal
      :isOpen="isOpenBrand"
      @closemodal="closeBrandModal()"
      :series="getScatterBrand"
      :name="'By Brand'"
      :monthsback="monthsback"
    />

  </div>
  <div v-else-if="beepruser && beepruser.data">
    <p class="my-5 text-center w-full">You do not have access to this feature and/or EAN</p>
  </div>
</template>

<script>
// @ is an alias to /src
import { Icon } from "@iconify/vue";
import { mapGetters } from "vuex";
//import ChartModal from "./components/chartmodal.vue";
import ScatterChartModal from "./components/scatterchartmodal.vue";
import ScatterBrandModal from "./components/scatterbrandmodal.vue";
import Segmentchart from "./components/segmentchart.vue";
import BaseProgress from "./components/BaseProgress.vue";
import Multiselect from "@vueform/multiselect";
import ProductModal from './ProductModal.vue';

export default {
  name: "Competition",
/*   metaInfo() {
    return {
      title: "Competition"
    };
  },
 */  data() {
    return {
      //['ID','Name','Brand','LowestPrice','NormalizedUnit','PPU','chains']
      /*       json_fields: {
        EAN: "ID",
        Product: "Name",
        Brand: "Brand",
        "Laveste pris": "LowestPrice",
        Unit: "NormalizedUnit",
        "Unit of measure": "NormalizedUom",
        "Price-per-unit": "PPU",
        Chains: "chains",
        "2022-10": "2022-10",
         Labels: {
          field: "Labels.DisplayName",
          callback: (value) => {
            return `${value}`;
          },
        },
       },
 */ brands: [],
      chains: [],
      plabels: [],
      units: [],
      uoms: [],
      barlabels: [],
      barseries: [ { data: [], type:"column", name:"Number of Products" }, { data: [], type:"line", name:"Avg PPU - excluding offers"} ],
      selectedBrands: [],
      selectedLabels: [],
      selectedChains: [],
      selectedUnits: [],
      selectedUoms: [],
      sortBy: "ppu",
      sortAsc: true,
      labels: [],
      brandlabels: [],
      series: [],
      ean: "",
      currProducts: [],
      //selectedPath: "eans",
      isOpen: {},
      isOpenSingle: false,
      isOpenBrand: false,
      monthsback: 6,
      sum: {},
      sumC: {},
      loading: true,
      contentProgress: 20,
      histbrandlabels: [],
      histSeries: [],
    };
    // end chart data line
  },
  components: {
    Icon,
    //ChartModal,
    ScatterChartModal,
    ScatterBrandModal,
    Segmentchart,
    BaseProgress,
    Multiselect,
    ProductModal,
  },

/*   watch: {
    beepruser(newData, old) {
      if( newData ) {
        if (this.$route.query.list && this.$route.query.list.length > 0) {
          if(newData && newData.data && this.$route.query.list == "favs"){
            let favs = newData.data.favs || [];
            this.$store.dispatch("setProductsFromList", favs);
          }
        }
      }
    },
  },
 */
  watch: {
    clientData(newData, old) {
      if( newData ) {
        if (this.beepruser && this.beepruser.uid && this.beepruser.data && this.beepruser.data.favs && this.beepruser.data.favs.length > 0 ) {
          this.selectedPath = "favs:" + this.beepruser.uid;
        }else if (newData.eans && newData.eans.length > 0) {
          this.selectedPath = "eans:" + newData.eans[0].replace(/[\W_]+/g, "") + ":" + newData.eans[0];
        } else if (newData.paths && newData.paths.length > 0) {
          this.selectedPath = "paths:" + newData.paths[0].replace(/[\W_]+/g, "") + ":" + newData.paths[0];
        } else if (newData.brands && newData.brands.length > 0) {
          this.selectedPath = "brands:" + newData.brands[0].replace(/[\W_]+/g, "") + ":" + newData.brands[0];
        }
      }
    }
  },

  methods: {
    getSum(chains, ean, m) {
      let fchains = this.filterChains(chains);
      let sum = 0;
      let sumC = 0;

      fchains.forEach((c) => {
        let chain = c == "føtexhome" ? "Føtex" : c[0].toUpperCase() + c.slice(1);
        if (this.ppm[ean] && this.ppm[ean][m] && this.ppm[ean][m][chain]) {
          sum += this.ppm[ean][m][chain];
          sumC += this.ppm[ean][m][chain + "Count"];
        }
      });

      if (sumC > 0) {
        return sum / sumC;
      }

      return 0;
    },

    getDaysInMonth(year, month) {
      return new Date(year, month, 0).getDate();
    },

    addMonths(input, months) {
      const date = new Date(input);
      date.setDate(1);
      date.setMonth(date.getMonth() + months);
      date.setDate(
        Math.min(input.getDate(), this.getDaysInMonth(date.getFullYear(), date.getMonth() + 1))
      );
      return date;
    },

    getLabelImg(label) {
      switch (label) {
        case "eu-okologi":
          return require("../assets/img/eu-okologi.webp");
        case "o-market":
          return require("../assets/img/ecology.jpg");
        case "noglehulsmarket":
          return require("../assets/img/noglehulsmarket.webp");
        case "fairtrade":
          return require("../assets/img/fairtrade.webp");
        case "svanemarket":
          return require("../assets/img/svanemarket.jpg");
        case "astma-allergi":
          return require("../assets/img/astma-allergi.webp");
        case "ecocert":
        case "eco-cert":
          return require("../assets/img/ecocert.webp");
        case "fuldkornsmarket":
          return require("../assets/img/fuldkornsmarket.jpg");
        case "msc":
          return require("../assets/img/msc.webp");
        case "fsc":
          return require("../assets/img/FSC.webp");
        case "asc":
          return require("../assets/img/ASC.webp");
        case "sukkerfri":
          return require("../assets/img/sukkerfri.webp");
        case "glutenfri":
          return require("../assets/img/glutenfri.webp");
        case "laktosefri":
          return require("../assets/img/laktosefri.webp");
        case "dansk":
          return require("../assets/img/dansk.webp");
        case "veganer":
          return require("../assets/img/veganer.webp");
        case "vegetar":
          return require("../assets/img/vegetarisk.webp");
        case "dyrenes-beskyttelse":
        case "anbefalet_af_dyrenes_beskyttelse":
          return require("../assets/img/anbefalet_af_dyrenes_beskyttelse.webp");
        case "dyrevelfaerd_1":
          return require("../assets/img/dyrevelfaerd1.webp");
        case "dyrevelfaerd_2":
          return require("../assets/img/dyrevelfaerd2.webp");
        case "dyrevelfaerd_3":
          return require("../assets/img/dyrevelfaerd3.webp");
        case "det-ekstra-gode-liv":
          return require("../assets/img/det_ekstragode_liv.webp");
        case "det-gode-okoliv":
          return require("../assets/img/det_gode_oekoliv.webp");
        case "det-gode-staldliv":
          return require("../assets/img/det_gode_staldliv.webp");
        case "det-gode-udeliv":
          return require("../assets/img/det_gode_udeliv.webp");
        case "biodynamisk":
          return require("../assets/img/biodynamisk.webp");
        case "blomstermarket":
          return require("../assets/img/blomstermarket.webp");

        default:
          return require("../assets/img/biconround.webp");
      }
    },

    closeModal(ean) {
      this.isOpen[ean] = false;
      this.series = [];
      this.labels = [];
    },
    closeSingleModal() {
      this.isOpenSingle = false;
    },
    closeBrandModal() {
      this.isOpenBrand = false;
    },
    async getPrices(ean, chains) {
      this.labels = [];
      var dateObj = new Date(
        new Date().getFullYear(),
        new Date().getMonth() - 12,
        new Date().getDate()
      );
      var day = ("0" + dateObj.getDate()).slice(-2);
      var month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
      var year = dateObj.getFullYear();
      let date = year + "-" + month + "-" + day;

      return new Promise((resolve, reject) => {
        this.$store.dispatch("setProductPrices", ean).then(() => {
          //console.log(this.productprices);
          let output = [];
          let tmpobj = [];
          Object.keys(this.productprices["prices"])
            .sort()
            .forEach((key) => {
              Object.keys(this.productprices["prices"][key]).forEach((key2) => {
                if (this.productprices["prices"][key]["X"] > date) {
                  (tmpobj[key2] = tmpobj[key2] || []).push(this.productprices["prices"][key][key2]);
                }
              });
            });

          Object.keys(tmpobj)
            .sort()
            .forEach((key) => {
              let out = { name: key, data: tmpobj[key] };
              //let chain = key == "Føtex" ? "føtexhome" : key.toLocaleLowerCase();
              if (key == "X") {
                this.labels = tmpobj[key];
              //} else if (key != "Postme" && chains.includes(chain)) {
              } else if (key != "Postme") {
                output.push(out);
              }
            });
          //console.log(this.labels);
          //console.log(output)
          resolve(output);
        });
      });
    },
    updateSort(newSort) {
      if (newSort == this.sortBy) {
        this.sortAsc = !this.sortAsc;
      } else {
        this.sortBy = newSort;
        this.sortAsc = true;
      }
    },
    pricePerUnit(price, unit, uom) {
      let ppu = 0.0;
      let normU = unit;
      let norm = uom;
      switch (uom) {
        case "kg":
          ppu = price / unit;
          break;
        case "gr":
          ppu = (price / unit) * 1000;
          normU = unit / 1000;
          norm = "kg";
          break;
        case "g":
          ppu = (price / unit) * 1000;
          normU = unit / 1000;
          norm = "kg";
          break;

        case "ltr":
        case "l":
          ppu = price / unit;
          norm = "l";
          break;
        case "dl":
          ppu = (price / unit) * 10;
          normU = unit / 10;
          norm = "l";
          break;
        case "cl":
          ppu = (price / unit) * 100;
          normU = unit / 100;
          norm = "l";
          break;
        case "ml":
          ppu = (price / unit) * 1000;
          normU = unit / 1000;
          norm = "l";
          break;

        default:
          ppu = price / unit;
          break;
      }
      return [ppu, normU, norm];
    },

    /*     toggleView() {
      this.$router.push({ name: "client" });
    },
 */ filterChains(chains) {
      return chains.filter((a) => a !== "postme");
    },
  },
  computed: {
    ...mapGetters({
      country: "country",
      beepruser: "beepruser",
      client: "client",
      clientData: "clientData",
      currYear: "currYear",
      currMon: "currMon",
      productprices: "productprices",
      selectedPathSuffix: "selectedPathSuffix",
      product: "product",
      products: "products",
      comptype: "comptype",
      allowedEAN: "allowedEAN",
      ppm: "ppm",
      myloading: "loading",
      allchains: "allchains"
    }),

    selectedPath: {
      get() {
        return this.$store.state.beeprStore.selectedPath;
      },
      set(value) {
        this.$store.dispatch("setSelectedPath", value);
        //console.log(value)
        let tmp = value.split(":");
        //console.log(tmp);
        //console.log(this.beepruser)
        if (tmp[0] == "favs") {
          let favs = this.beepruser.data.favs || [];
          this.$store.dispatch("setProductsFromList", favs);
        } else if (tmp[0] == "paths") {
          this.$store.dispatch("setProductsFromPath", tmp[2]);
        } else if (tmp[0] == "brands") {
          this.$store.dispatch("setProductsFromBrand", tmp[2]);
        } else if (tmp[0] == "eans") {
          if (this.clientData && this.clientData[tmp[2]]){ //tmp[1] or tmp[2]?
            let eans = this.clientData[tmp[2]];
            this.$store.dispatch("setProductsFromList", eans);
          }
        }
        //this.$store.dispatch("resetNewCurrent");
        //this.$store.dispatch("setNewCurrent");
      },
    },

    json_fields2() {
      let tmp = {
        EAN: "ID",
        Product: "Name",
        Brand: "Brand",
        "Laveste pris": "LowestPrice",
        Unit: "NormalizedUnit",
        "Unit of measure": "NormalizedUom",
        "Price-per-unit": "PPU",
        Chains: "chains",
      };

      return tmp;
    },

    formatNumbers() {
      let products = {};
      //if (this.showHistory) {
      //  products = JSON.parse(JSON.stringify(this.histProducts));
      //} else {
        products = JSON.parse(JSON.stringify(this.currProducts));
      //}
      products.map((p) => {
        p.ID = `'${p.ID}'`;
        //p.Name = `${p.Name +" "}`;
        //p.Brand = `${p.Brand + " "}`;
        //p.chains = `${p.chains+ " "}`;
        //p.NormalizedUom = `${p.NormalizedUom+ " "}`;
        //p.LowestPrice = Math.round(p.LowestPrice * 100) / 100;
        //p.PPU = Math.round(p.PPU * 100) / 100;
        //p.NormalizedUnit = Math.round(p.NormalizedUnit * 100) / 100;
        p.LowestPrice = Intl.NumberFormat("da-DK", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(p.LowestPrice.toFixed(2));
        p.PPU = p.PPU
          ? Intl.NumberFormat("da-DK", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(p.PPU.toFixed(2))
          : "0,00";
        p.NormalizedUnit = p.NormalizedUnit ? p.NormalizedUnit : "0,00";
      });
      //console.log(products)
      return products;
    },

    getScatterBrand() {
      let mySeries = [];
      console.log("Calling scatter brand");

      if (
        this.currProducts &&
        this.ppm &&
        !this.myloading
        //Object.keys(this.ppm).length > this.currProducts.length - 5
      ) {
        let tmpObj = {};
        //console.log(this.allProducts);
        this.currProducts.forEach((p) => {
          if (p && p.LowestPrice) {
            if (this.selectedChains.length == 0) {
              Object.keys(p.Prices).forEach((c) => {
                //console.log(p.Prices[c].Price + " :: "+ p.Units + " :: " + p.Uom);
                //console.log(this.ppm[p.ID]["2022-12"][c[0].toUpperCase()+c.slice(1)]);

                let myPrice = 0;
                let myPriceCount = 0;

                if (this.ppm[p.ID]) {
                  let keys = Object.keys(this.ppm[p.ID]).sort();
                  let chain = c[0].toUpperCase() + c.slice(1);
                  if (c == "føtexhome") {
                    chain = "Føtex";
                  }
                  for (let index = 0; index < this.monthsback; index++) {
                    if (this.ppm[p.ID][keys[keys.length - index - 1]]) {
                      myPrice += this.ppm[p.ID][keys[keys.length - index - 1]][chain];
                      myPriceCount +=
                        this.ppm[p.ID][keys[keys.length - index - 1]][chain + "Count"];
                    }
                  }
                  //console.log(p.ID + " : " + chain + " : " + keys);
                  //console.log(myPrice + " : " + myPriceCount + " : " + (myPrice/myPriceCount) );
                  let tmp = this.pricePerUnit(myPrice / myPriceCount, p.Units, p.Uom);

                  if (p.Brand && p.Brand.length > 0) {
                    tmpObj[p.Brand] = tmpObj[p.Brand] || [];
                    tmpObj[p.Brand].push({
                      x: tmp[0],
                      y: tmp[1],
                      ean: p.ID,
                      chain: c,
                      name: p.Name,
                      brand: p.Brand,
                      //price: p.Prices[c].Price,
                      price: myPrice / myPriceCount,
                      uom: tmp[2],
                    });
                  } else {
                    tmpObj["NA"] = tmpObj["NA"] || [];
                    tmpObj["NA"].push({
                      x: tmp[0],
                      y: tmp[1],
                      ean: p.ID,
                      chain: c,
                      name: p.Name,
                      brand: "NA",
                      //price: p.Prices[c].Price,
                      price: myPrice / myPriceCount,
                      uom: tmp[2],
                    });
                  }
                }
              });
            } else {
              let filterProducts = Object.keys(p.Prices).filter((c) =>
                this.selectedChains.includes(c)
              );
              filterProducts.forEach((c) => {
                //console.log(p.Prices[c].Price + " :: "+ p.Units + " :: " + p.Uom);
                //let tmp = this.pricePerUnit(p.Prices[c].Price, p.Units, p.Uom);
                //console.log(tmp);

                let myPrice = 0;
                let myPriceCount = 0;

                if (this.ppm[p.ID]) {
                  let keys = Object.keys(this.ppm[p.ID]).sort();
                  let chain = c[0].toUpperCase() + c.slice(1);
                  if (c == "føtexhome") {
                    chain = "Føtex";
                  }
                  for (let index = 0; index < this.monthsback; index++) {
                    if (this.ppm[p.ID][keys[keys.length - index - 1]]) {
                      myPrice += this.ppm[p.ID][keys[keys.length - index - 1]][chain];
                      myPriceCount +=
                        this.ppm[p.ID][keys[keys.length - index - 1]][chain + "Count"];
                    }
                  }
                  //console.log(p.ID + " : " + chain + " : " + keys);
                  //console.log(myPrice + " : " + myPriceCount + " : " + (myPrice/myPriceCount) );
                  let tmp = this.pricePerUnit(myPrice / myPriceCount, p.Units, p.Uom);

                  if (p.Brand && p.Brand.length > 0) {
                    tmpObj[p.Brand] = tmpObj[p.Brand] || [];
                    tmpObj[p.Brand].push({
                      x: tmp[0],
                      y: tmp[1],
                      ean: p.ID,
                      chain: c,
                      name: p.Name,
                      brand: p.Brand,
                      //price: p.Prices[c].Price,
                      price: myPrice / myPriceCount,
                      uom: tmp[2],
                    });
                  } else {
                    tmpObj["NA"] = tmpObj["NA"] || [];
                    tmpObj["NA"].push({
                      x: tmp[0],
                      y: tmp[1],
                      ean: p.ID,
                      chain: c,
                      name: p.Name,
                      brand: "NA",
                      //price: p.Prices[c].Price,
                      price: myPrice / myPriceCount,
                      uom: tmp[2],
                    });
                  }
                }
              });
            }
          }
        });
        //console.log(tmpObj);

        Object.keys(tmpObj)
          .sort()
          .forEach((c) => {
            //console.log(c);
            let tmp = {
              name: c[0].toUpperCase() + c.slice(1),
              data: tmpObj[c].sort((a, b) => a.x - b.x),
            };
            //console.log(tmp);
            mySeries.push(tmp);
          });
        //console.log(mySeries);
      }
      return mySeries;
    },

    getScatter() {
      let tmpObj = {};
      let mySeries = [];

      if (
        this.currProducts &&
        this.ppm &&
        !this.myloading
        //Object.keys(this.ppm).length > this.currProducts.length - 5
      ) {
        this.loading = false;

        //console.log("Calling get scatter?");
        //console.log(this.allProducts);
        this.currProducts.forEach((p) => {
          if (p && p.Prices && p.LowestPrice && p.LowestPrice > 0) {
            if (this.selectedChains.length == 0) {
              Object.keys(p.Prices).forEach((c) => {
                //console.log(p.Prices[c].Price + " :: "+ p.Units + " :: " + p.Uom);
                //let tmp = this.pricePerUnit(p.Prices[c].Price, p.Units, p.Uom);
                //console.log(tmp);

                let myPrice = 0;
                let myPriceCount = 0;

                if (this.ppm[p.ID]) {
                  let keys = Object.keys(this.ppm[p.ID]).sort();
                  let chain = c[0].toUpperCase() + c.slice(1);
                  if (c == "føtexhome") {
                    chain = "Føtex";
                  }
                  for (let index = 0; index < this.monthsback; index++) {
                    if (this.ppm[p.ID][keys[keys.length - index - 1]]) {
                      myPrice += this.ppm[p.ID][keys[keys.length - index - 1]][chain];
                      myPriceCount +=
                        this.ppm[p.ID][keys[keys.length - index - 1]][chain + "Count"];
                    }
                  }
                  //console.log(p.ID + " : " + chain + " : " + keys);
                  //console.log(myPrice + " : " + myPriceCount + " : " + (myPrice/myPriceCount) );
                  let tmp = this.pricePerUnit(myPrice / myPriceCount, p.Units, p.Uom);

                  tmpObj[c] = tmpObj[c] || [];
                  tmpObj[c].push({
                    x: tmp[0],
                    y: tmp[1],
                    ean: p.ID,
                    name: p.Name,
                    brand: p.Brand,
                    //price: p.Prices[c].Price,
                    price: myPrice / myPriceCount,
                    uom: tmp[2],
                  });
                }
              });
            } else {
              let filterProducts = Object.keys(p.Prices).filter((c) =>
                this.selectedChains.includes(c)
              );
              filterProducts.forEach((c) => {
                //let tmp = this.pricePerUnit(p.Prices[c].Price, p.Units, p.Uom);
                //console.log(tmp);

                let myPrice = 0;
                let myPriceCount = 0;

                if (this.ppm[p.ID]) {
                  let keys = Object.keys(this.ppm[p.ID]).sort();
                  let chain = c[0].toUpperCase() + c.slice(1);
                  if (c == "føtexhome") {
                    chain = "Føtex";
                  }
                  for (let index = 0; index < this.monthsback; index++) {
                    if (this.ppm[p.ID][keys[keys.length - index - 1]]) {
                      myPrice += this.ppm[p.ID][keys[keys.length - index - 1]][chain];
                      myPriceCount +=
                        this.ppm[p.ID][keys[keys.length - index - 1]][chain + "Count"];
                    }
                  }
                  //console.log(p.ID + " : " + chain + " : " + keys);
                  //console.log(myPrice + " : " + myPriceCount + " : " + (myPrice/myPriceCount) );
                  let tmp = this.pricePerUnit(myPrice / myPriceCount, p.Units, p.Uom);

                  tmpObj[c] = tmpObj[c] || [];
                  tmpObj[c].push({
                    x: tmp[0],
                    y: tmp[1],
                    ean: p.ID,
                    name: p.Name,
                    brand: p.Brand,
                    //price: p.Prices[c].Price,
                    price: myPrice / myPriceCount,
                    uom: tmp[2],
                  });
                }
              });
            }
          }
        });
        //console.log(tmpObj);

        Object.keys(tmpObj)
          .sort()
          .forEach((c) => {
            //console.log(c);
            let tmp = {
              name: c[0].toUpperCase() + c.slice(1),
              data: tmpObj[c].sort((a, b) => a.x - b.x),
            };
            mySeries.push(tmp);
          });
      }
      //console.log(mySeries);
      return mySeries;
    },

    allProducts() {
      let products = {};

      console.log("Calling get products " + this.products.length)
      //console.log("Calling get products");
      //if (this.products.length > Object.keys(this.ppm).length - 5) {
      if (!this.myloading) {
        if (this.sortBy == "Name" || this.sortBy == "Brand" || this.sortBy == "NormalizedUnit") {
          if (this.sortAsc) {
            products = this.products
              ? this.products.sort((a, b) => a[this.sortBy].localeCompare(b[this.sortBy], "da"))
              : [];
          } else {
            products = this.products
              ? this.products.sort((a, b) => b[this.sortBy].localeCompare(a[this.sortBy], "da"))
              : [];
          }
        } else {
          //console.log(this.sortBy);
          //console.log(this.products);
          if (this.sortAsc) {
            products = this.products
              ? this.products.sort((a, b) => {
                  if (!isFinite(a[this.sortBy]) && !isFinite(b[this.sortBy])) {
                    return isNaN(a[this.sortBy]) && isNaN(b[this.sortBy])
                      ? 1
                      : a[this.sortBy] < b[this.sortBy]
                      ? -1
                      : a[this.sortBy] === b[this.sortBy]
                      ? 0
                      : 1;
                  }
                  if (!isFinite(a[this.sortBy])) {
                    return 1;
                  }
                  if (!isFinite(b[this.sortBy])) {
                    return -1;
                  }
                  return a[this.sortBy] - b[this.sortBy];
                })
              : [];
          } else {
            products = this.products
              ? this.products.sort((a, b) => {
                  if (!isFinite(a[this.sortBy]) && !isFinite(b[this.sortBy])) {
                    return isNaN(a[this.sortBy]) && isNaN(b[this.sortBy])
                      ? 1
                      : a[this.sortBy] < b[this.sortBy]
                      ? -1
                      : a[this.sortBy] === b[this.sortBy]
                      ? 0
                      : 1;
                  }
                  if (!isFinite(a[this.sortBy])) {
                    return 1;
                  }
                  if (!isFinite(b[this.sortBy])) {
                    return -1;
                  }
                  return b[this.sortBy] - a[this.sortBy];
                })
              : [];
          }
        }
        let tmp2 = products
          .filter((p) => p.LowestPrice && p.LowestPrice > 0)
          .map(function (obj) {
            return obj.Brand;
          });
        this.brands = [...new Set(tmp2)].sort();

        tmp2 = products
          .filter((p) => p.LowestPrice && p.LowestPrice > 0)
          .map(function (obj) {
            return [...obj.chains];
          });
        this.chains = [...new Set(tmp2.flat())].sort();

        tmp2 = products
          .filter((p) => p.LowestPrice && p.LowestPrice > 0)
          .map(function (obj) {
            return [...new Set(obj.Labels)];
          });
        //console.log(tmp2);
        let tmp3 = [...new Set(tmp2.flat())]
          .sort()
          .filter(
            (l) =>
              l.DisplayName &&
              l.ID != "budget" &&
              l.ID != "tilbud" &&
              l.ID != "frost" &&
              l.ID != "avisvare" &&
              l.ID != "Specialiteter" &&
              l.ID != "atten-plus" &&
              l.ID != ""
          )
          .flat();
        //console.log([...this.plabels])
        //console.log(this.plabels.filter((o) => o.DisplayName).flat());

        this.plabels = tmp3.map((l) => [{ label: l.DisplayName, value: l.ID }][0]);
        this.plabels = this.plabels.filter(
          (v, i, a) => a.findIndex((v2) => v2.value === v.value) === i
        );

        tmp2 = products.map(function (obj) { return obj.NormalizedUnit; });
      this.units = [...new Set(tmp2)].sort();

      tmp2 = products.map(function (obj) { return obj.NormalizedUom; });
      this.uoms = [...new Set(tmp2)].sort();

        //console.log(this.plabels)

        //filter((o) => o.DisplayName && o.DisplayName == "Tilbud");
        //console.log(this.plabels);

        if (this.selectedChains.length > 0) {
          products = products.filter((o) => o.chains.some((r) => this.selectedChains.includes(r)));
        }

        if (this.selectedBrands.length > 0) {
          products = products.filter((o) => this.selectedBrands.includes(o.Brand));
        }

        if (this.selectedUnits.length > 0) {
          products = products.filter((o) => this.selectedUnits.includes(o.NormalizedUnit));
        }

        if (this.selectedUoms.length > 0) {
          products = products.filter((o) => this.selectedUoms.includes(o.NormalizedUom));
        }

        //console.log(this.selectedLabels)
        if (this.selectedLabels.length > 0) {
          products = products.filter(
            (o) => o.Labels && o.Labels.some((r) => this.selectedLabels.includes(r.ID))
          );
        }
        this.currProducts = products;
      }
      if (products && products.length > 0) {
        //return products;
        products = products.filter((p) => p.LowestPrice && p.LowestPrice > 0);

        let unitcounter = {};
        let unitavg = {};
        this.barlabels = [];
        this.barseries[0].data = [];
        this.barseries[1].data = [];

        products.forEach((p) => {
          //console.log(p);
          if(p.NormalizedUnit && p.NormalizedUom) {
            if (p.Prices) {
              let tmpCounter = 0;
              let tmpSum = 0;
              Object.keys(p.Prices).forEach((key) => {
                if (!p.Prices[key].IsOnDiscount) {
                  tmpSum += p.Prices[key].Price;
                  tmpCounter++;
                } else if(p.Prices[key].IsOnDiscount && p.Prices[key].Saved > 0) {
                  tmpSum += p.Prices[key].Price + p.Prices[key].Saved;
                  tmpCounter++;
                }
              });
              if(tmpCounter > 0 && p.Units > 0 && p.Uom != "") {
                let tmpAvg = (tmpSum / tmpCounter);
                let ppdata = this.pricePerUnit(tmpAvg, p.Units, p.Uom);            
                unitcounter[p.NormalizedUnit + " " + p.NormalizedUom] = unitcounter[p.NormalizedUnit + " " + p.NormalizedUom] + 1 || 1;
                unitavg[p.NormalizedUnit + " " + p.NormalizedUom] = unitavg[p.NormalizedUnit + " " + p.NormalizedUom] + (ppdata[0]) || (ppdata[0]);
              }
            }
          }
        })
        Object.keys(unitcounter).sort().forEach((key) => {
          //console.log(key + " " + unitcounter[key]);
          this.barlabels.push(key);
          let avg = unitavg[key] / unitcounter[key];
          let tmp = { y: unitcounter[key], x: key}
          let tmp2 = { x: key, y: avg.toFixed(2)}
          this.barseries[0].data.push(tmp);
          this.barseries[1].data.push(tmp2);
        });
        console.log(this.barseries);
      }
      return products;
    },

    allProductsCount() {
      return this.products ? this.products.length : 0;
    },

    admin() {
      //console.log(this.allowedEAN)
      if (
        this.beepruser &&
        this.beepruser.data &&
        this.beepruser.data.client &&
        this.beepruser.data.client == "beepr-internal"
      ) {
        return true;
      } else if (
        this.clientData &&
        this.clientData.competition
        //&& this.allowedEAN == this.$route.query.ean
      ) {
        return true;
      } else {
        //this.$router.push({ name: "home"});
        return false;
      }
    },
  },
  mounted() {
    console.log(this.selectedPath)
    //if (this.$route.query.ean && this.$route.query.ean.length > 0) {
    //  this.ean = this.$route.query.ean;
    //  this.$store.dispatch("setProducts", this.$route.query.ean);
    //}

    if(this.selectedPath){
      let tmp = this.selectedPath.split(":");
      if (tmp[0] == "favs") {
        let favs = this.beepruser.data.favs || [];
        this.$store.dispatch("setProductsFromList", favs);
      } else if (tmp[0] == "paths") {
        this.$store.dispatch("setProductsFromPath", tmp[2]);
      } else if (tmp[0] == "brands") {
        this.$store.dispatch("setProductsFromBrand", tmp[2]);
      } else if (tmp[0] == "eans") {
        if (this.clientData && this.clientData[tmp[2]]){ //tmp[1] or tmp[2]?
          let eans = this.clientData[tmp[2]];
          this.$store.dispatch("setProductsFromList", eans);
        }
      }
    }
      //this.$store.dispatch("setProducts", this.$route.query.path);
      //this.$store.dispatch("setProducts", this.$route.query.brand);
  },
};
</script>

<style>
.toggle-checkbox:checked {
  @apply: right-16px;
  right: 16px;
  color: #07c157;
}
</style>
