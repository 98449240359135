<template>
  <div v-show="ean && isOpen" class="fixed z-30 inset-0 overflow-y-auto bg-black bg-opacity-50">
    <div class="flex items-start justify-center min-h-screen pt-2 text-center w-full">

      <div class="bg-white rounded-lg text-left overflow-hidden shadow-xl p-8 w-5/12 mt-40">

        <h2 class="float-left font-bold text-lg text-gray-800 dark:text-gray-200">Report an error to Beepr</h2>

        <button type="button" @click="closemodal()"
          class="float-right text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm mb-3">
          <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"></path>
          </svg>
        </button>

        <div class="clear-both py-5">

          <form class="space-y-6" @submit.prevent="sendError()">

            <div>{{ name + ' - ( EAN: ' + ean +' )' }}</div>

            <label for="product" class="block mb-2 text-md font-medium text-gray-900 dark:text-white">What is wrong?</label>
            <ul class="w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white">
    <li class="w-full border-b border-gray-200 rounded-t-lg dark:border-gray-600">
        <div class="flex items-center pl-3">
            <input id="category-checkbox" v-model="boxes.category" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
            <label for="category-checkbox" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Wrong category</label>
        </div>
    </li>
    <li class="w-full border-b border-gray-200 rounded-t-lg dark:border-gray-600">
        <div class="flex items-center pl-3">
            <input id="price-checkbox" v-model="boxes.price" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
            <label for="price-checkbox" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Wrong price</label>
        </div>
    </li>
    <li class="w-full border-b border-gray-200 rounded-t-lg dark:border-gray-600">
        <div class="flex items-center pl-3">
            <input id="size-checkbox" v-model="boxes.size" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
            <label for="size-checkbox" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Wrong size</label>
        </div>
    </li>
    <li class="w-full border-b border-gray-200 rounded-t-lg dark:border-gray-600">
        <div class="flex items-center pl-3">
            <input id="other-checkbox" v-model="boxes.other" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
            <label for="other-checkbox" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Other error</label>
        </div>
    </li>
</ul>

      <label for="message" class="block mb-2 text-md font-medium text-gray-900 dark:text-white">Description</label>
      <textarea id="message" v-model="boxes.desc" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="What is wrong with the product details?"></textarea>

      <button type="submit" class="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Send</button>

    </form>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { Icon } from "@iconify/vue";
import { mapGetters } from "vuex";

export default {
  name: "ErrorModal",
  props: {
    isOpen: Boolean,
    ean: String,
    name: String,
  },
  data() {
    return {
      boxes: {
        category: false,
        price: false,
        size: false,
        other: false,
        desc: ""
      }
    };
  },
  components: {
    Icon,
  },
/*   watch: {
    ean(newData, old) {
      console.log("ean changed: " + newData);
      this.$store.dispatch("setProduct", newData);
    },
  },
 */  methods: {
    sendError(){
      this.boxes["ean"] = this.ean;
      this.boxes["product"] = this.name;
      this.boxes["email"] = this.beepruser.data.email;
      this.boxes["client"] = this.beepruser.data.client;
      this.boxes["name"] = this.beepruser.data.name;
      this.$store.dispatch("insertError", this.boxes);
      alert("Success! Your error report has been sent to Beepr. Thank you for your assistance in improving our data");
      this.closemodal();
      //console.log(JSON.stringify(this.boxes));
    },
    closemodal() {
      this.boxes = {
        category: false,
        price: false,
        size: false,
        other: false,
        desc: ""
      };

      this.$emit("closemodal", false);
    },
  },
  computed: {
    ...mapGetters({
      country: "country",
      beepruser: "beepruser",
      client: "client",
      clientData: "clientData",
      currYear: "currYear",
      currMon: "currMon",
      product: "product",
    }),
  },
  mounted() {
  },
};
</script>

<style>
.outer-wrapper {
  display: inline-block;
  margin: 10px;
}

.frame {
  width: 120px;
  height: 120px;
  vertical-align: middle;
  text-align: center;
  display: table-cell;
}

</style>

<style src="@vueform/multiselect/themes/default.css">

</style>
