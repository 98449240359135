<template>
  <div class="p-1">
    <div
      class="bg-white dark:bg-gray-800 w-full p-6 font-lexend shadow-md rounded-md mt-1"
    >
      <div class="wrapper-link flex gap-4 text-sm text-gray-400">
        <router-link to="#">Terms and conditions</router-link>
        <router-link to="#">Privacy Policy</router-link>
        <router-link to="#">Cookie Policy</router-link>
        <router-link to="#">Contact</router-link>
      </div>
    </div>
<!--     <footer class="w-full box-border p-1 mt-3 text-center">
      <p class="text-sm dark:text-gray-400">
        Beepr BI
      </p>
    </footer>
 -->  </div>
</template>
