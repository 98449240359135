<template>
  <div class="dashboard p-4">

    <div v-if="cat1StatsCount > 4" class="mt-2 bg-white dark:bg-gray-800 p-5 w-full rounded-md box-border shadow">
      <h2 class="font-bold text-lg text-gray-800 dark:text-gray-200 text-center">
        Number of products per category per chain
      </h2>
      
<!--       <download-csv
        class   = "float-right"
        :data   = "allProducts"
        name    = "report.csv">
        <Icon icon="iwwa:csv" height="24px"/>
      </download-csv>
 -->
      <div class="wrapping-table mt-10 block">
        <table
          class="w-full text-sm text-center text-gray-500 dark:text-gray-400"
        >
          <thead
            class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 sticky top-0"
          >
            <tr>
              <th scope="col">Category</th>
              <th v-for="chain in Object.keys(cat1Stats).sort()" :key="`cat1Stats-${chain}`"
                scope="col"
                class="uppercase px-6 py-3"
              >
                {{ chain }}
              </th>
            </tr>
          </thead>
          <tbody>

            <tr
              class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50"
              v-for="cat in Object.keys(cat1Stats[Object.keys(cat1Stats)[0]]).sort()"
              :key="cat"
            >
              <td v-if="cat != 'TOTAL'" class="px-6 py-4 text-left">
                {{ cat }}
              </td>

              <td v-if="cat != 'TOTAL'" v-for="chain in Object.keys(cat1Stats).sort()" :key="`cat1Stats2-${chain}`">
                {{ (cat1Stats[chain][cat]) ? cat1Stats[chain][cat] : '0' }} ( {{ (cat1Stats[chain][cat]) ? Intl.NumberFormat("da-DK", { style:"percent", maximumFractionDigits: 2}).format(cat1Stats[chain][cat]/cat1Stats[chain]['TOTAL']) : '0 %' }})
              </td>
            </tr>

            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-gray-300">
              <td class="px-6 py-4 text-bold text-left">TOTAL</td>
              <td class="text-bold" v-for="chain in Object.keys(cat1Stats).sort()" :key="`cat1Stats2-${chain}`">
                {{ cat1Stats[chain]['TOTAL'] }}
              </td>
            </tr>

          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { Icon } from "@iconify/vue";
import { mapGetters } from "vuex";
import PercentChartModal from "./components/percentchartmodal.vue";

export default {
  name: "Dashboard",
/*   metaInfo() {
    return {
      title: "Chain Details"
    };
  },
 */  data() {
    return {
      sortBy: "category",
      sortAsc: true,
      labels: [],
      series: [],
      isOpen: {},
      catFilter: "",
    };
    // end chart data line
  },
  components: {
    Icon,
    PercentChartModal,
  },
  methods: {
    updateSort(newSort) {
      if (newSort == this.sortBy) {
        this.sortAsc = !this.sortAsc;
      } else {
        this.sortBy = newSort;
        this.sortAsc = true;
      }
    },
  },
  computed: {
    ...mapGetters({
      beepruser: "beepruser",
      client: "client",
      clientData: "clientData",
      cat1Stats: "cat1Stats"
    }),

/*     allProducts() {
      let tmp = [];
      if (this.sortBy == "category") {
        if (this.sortAsc) {
          tmp = this.current[this.selectedPath]
              ? this.current[this.selectedPath].sort((a, b) =>
                  a[this.sortBy]
                    .localeCompare(b[this.sortBy], "da")
                ).filter((o) => o.number > 9)
            : [];
        } else {
          tmp = this.current[this.selectedPath]
              ? this.current[this.selectedPath].sort((a, b) =>
                  b[this.sortBy].localeCompare(a[this.sortBy], "da")
                ).filter((o) => o.number > 9)
            : [];
        }
      } else {
        if (this.sortAsc) {
          tmp = this.current[this.selectedPath]
              ? this.current[this.selectedPath].sort((a, b) => a[this.sortBy] - b[this.sortBy]).filter((o) => o.number > 9)
            : [];
        } else {
          tmp = this.current[this.selectedPath]
              ? this.current[this.selectedPath].sort((a, b) => b[this.sortBy] - a[this.sortBy]).filter((o) => o.number > 9)
            : [];
        }
      }
    },
 */
    cat1StatsCount() {
      return this.cat1Stats ? Object.keys(this.cat1Stats).length : 0;
    },
  },
  mounted() {
    this.$store.dispatch("setCat1Stats");
  },
};
</script>

<style>
</style>