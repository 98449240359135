//import Vue from "vue";
//import Vuex from "vuex";
//Vue.use(Vuex);
import { createStore } from 'vuex'
import authStore from "./modules/authStore";
import beeprStore from "./modules/beeprStore";

const store = createStore({

  state: {
    locales: ['dk', 'se', 'no', 'gb'], // allowed locales
    country: 'dk', // current locale
    showLoginRequired: false,
  },

  getters: {
    seenTeaser() {
      if (localStorage.getItem('seenTeaser')) {
        return true;
      } else {
        return false;
      }
    },
    country(state) {
      return state.country;
    },
    showLoginRequired(state) {
      return state.showLoginRequired;
    },
    locales(state) {
      return state.country;
    },  
  },

  actions: {
  },

  mutations: {
    TEASER_SEEN(state){
      localStorage.setItem("seenTeaser", true);
    },
    SET_COUNTRY(state, data) {
      state.country = data;
    },
    TOGGLE_LOGIN_REQUIRED (state) {
      state.showLoginRequired = !state.showLoginRequired;
    },
  },

  modules: {
    authStore,
    beeprStore,
  },
});

export default store;