<template>
  <div class="dashboard p-4">
<!--    <label for="cats" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">Select Category
    </label>
 -->
    <div class="wrapper-button mt-3">
      <select v-model="selectedPath" id="cats"
        class="w-full lg:w-3/12 float-none lg:float-left bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 mr-5 mb-5">
        <option value="" disabled>Please select category</option>
        <option v-if="beepruser && beepruser.uid" :value="'favs:' + beepruser.uid">My Favorites</option>

        <option disabled>──────────EAN Groups──────────</option>
        <option v-if="clientData && clientData.eans" v-for="eangroup in clientData.eans.sort()" :key="eangroup" :value="'eans:'+eangroup.replace(/[\W_]+/g, '') + ':' + eangroup">
          {{ eangroup.charAt(0).toUpperCase() + eangroup.slice(1) }}
        </option>

        <option disabled>──────────Categories──────────</option>
        <option v-if="clientData && clientData.paths" v-for="path in clientData.paths.sort()" :key="path" :value="'paths:'+path.replace(/[\W_]+/g, '') + ':' + path">
          {{ path }}
        </option>

        <option disabled>────────────Brands────────────</option>
        <option v-if="clientData && clientData.brands" v-for="brand in clientData.brands.sort()" :key="brand" :value="'brands:' + brand.replace(/[\W_]+/g, '') + ':' + brand">
        {{ brand }}
      </option>
      </select>
    </div>

<!--     <div v-if="chains.length > 0" class="w-full md:w-1/4 mb-5 float-left">
      <label class="block">
        <span class="text-gray-700">Filter by Chain/s</span>
        <button class="float-right" @click="selectedChains = []">Clear</button>
        <select v-model="selectedChains" class="block w-full mt-1 rounded" multiple="">
          <option v-for="chain in chains" :value="chain" :key="chain">{{ (chain == 'føtexhome') ? "Føtex" :
              chain[0].toUpperCase() + chain.slice(1)
          }}</option>
        </select>
      </label>
    </div>

    <div v-if="brands.length > 0" class="w-full md:w-1/4 md:ml-5 mb-5 float-left">
      <label class="block">
        <span class="text-gray-700">Filter by Brand/s</span>
        <button class="float-right" @click="selectedBrands = []">Clear</button>
        <perfect-scrollbar ref="scroll">
          <select v-model="selectedBrands" class="block w-full mt-1 rounded overflow-auto" multiple="">
          <option v-for="brand in brands" :key="brand">{{ brand }}</option>
        </select>
      </perfect-scrollbar>
      </label>
    </div>
 -->
 <Multiselect
    v-if="chains.length > 0" class="w-full md:w-2/12 mb-5 float-left"
    v-model="selectedChains"
    mode="tags"
    placeholder="Filter chains"
    :close-on-select="false"
    :filter-results="true"
    :min-chars="10000"
    :resolve-on-load="false"
    :infinite="false"
    :clear-on-search="false"
    :delay="0"
    :searchable="true"
    :options="chains"
    :limit="100"
  />

    <Multiselect
    v-if="brands.length > 0" class="w-full md:w-2/12 md:ml-5 mb-5 float-left"
    v-model="selectedBrands"
    mode="tags"
    placeholder="Filter brands"
    :close-on-select="false"
    :filter-results="true"
    :min-chars="10000"
    :resolve-on-load="false"
    :infinite="false"
    :clear-on-search="false"
    :delay="0"
    :searchable="true"
    :options="brands"
  />

  <Multiselect
    v-if="units.length > 1" class="w-full md:w-2/12 md:ml-5 mb-5 float-left"
    v-model="selectedUnits"
    mode="tags"
    placeholder="Filter units"
    :close-on-select="false"
    :filter-results="true"
    :min-chars="10000"
    :resolve-on-load="false"
    :infinite="false"
    :clear-on-search="false"
    :delay="0"
    :searchable="true"
    :options="units"
  />

  <Multiselect
    v-if="uoms.length > 1" class="w-full md:w-2/12 md:ml-5 mb-5 float-left"
    v-model="selectedUoms"
    mode="tags"
    placeholder="Filter UOM"
    :close-on-select="false"
    :filter-results="true"
    :min-chars="10000"
    :resolve-on-load="false"
    :infinite="false"
    :clear-on-search="false"
    :delay="0"
    :searchable="true"
    :options="uoms"
  />

<!--   <div
      class="block float-right -mt-8 inline-block w-10 align-middle select-none transition duration-200 ease-in"
      style="color: #07c157"
    >
      <input
        checked
        type="checkbox"
        @click.native="toggleView()"
        name="toggle"
        id="toggle"
        class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
      />
      <label
        for="toggle"
        class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
      ></label>
    </div>
    <label for="toggle" class="block uppercase float-right -mt-7 mr-12 text-sm">ALL PRODUCTS</label>
 -->

   <div class="mt-5 bg-white dark:bg-gray-800 p-5 w-full rounded-md box-border shadow clear-both">
      <h2 class="font-bold float-left text-lg text-gray-800 dark:text-gray-200">
        Products with prices
      </h2>

      <download-excel
        class="float-right"
        :data="formatNumbers"
        :fields="json_fields2"
        :name="selectedPathSuffix + '.xls'"
      >
        <Icon icon="iwwa:csv" height="24px" />
      </download-excel>

      <div class="wrapping-table mt-10">
        <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400 lg:overflow-auto overflow-x-scroll">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 sticky top-0">
            <tr>
              <th style="cursor: pointer" scope="col" class="uppercase px-6 py-3" @click="updateSort('name')">
                <svg v-if="sortBy == 'name' && this.sortAsc" style="float: left" xmlns="http://www.w3.org/2000/svg"
                  width="16" height="16" fill="currentColor" class="bi bi-sort-up" viewBox="0 0 16 16">
                  <path
                    d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z" />
                </svg>

                <svg v-else-if="sortBy == 'name' && !this.sortAsc" style="float: left"
                  xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-sort-down"
                  viewBox="0 0 16 16">
                  <path
                    d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z" />
                </svg>
                Product
              </th>
              <th style="cursor: pointer" scope="col" class="uppercase px-6 py-3" @click="updateSort('brand')">
                <svg v-if="sortBy == 'brand' && this.sortAsc" style="float: left" xmlns="http://www.w3.org/2000/svg"
                  width="16" height="16" fill="currentColor" class="bi bi-sort-up" viewBox="0 0 16 16">
                  <path
                    d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z" />
                </svg>

                <svg v-else-if="sortBy == 'brand' && !this.sortAsc" style="float: left"
                  xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-sort-down"
                  viewBox="0 0 16 16">
                  <path
                    d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z" />
                </svg>

                Brand
              </th>

              <th style="cursor: pointer" scope="col" class="uppercase px-6 py-3" @click="updateSort('price')">
                <svg v-if="sortBy == 'price' && this.sortAsc" style="float: left" xmlns="http://www.w3.org/2000/svg"
                  width="16" height="16" fill="currentColor" class="bi bi-sort-up" viewBox="0 0 16 16">
                  <path
                    d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z" />
                </svg>

                <svg v-else-if="sortBy == 'price' && !this.sortAsc" style="float: left"
                  xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-sort-down"
                  viewBox="0 0 16 16">
                  <path
                    d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z" />
                </svg>

                Price
              </th>

              <th style="cursor: pointer" scope="col" class="uppercase px-6 py-3" @click="updateSort('normUnit')">
                <svg v-if="sortBy == 'normUnit' && this.sortAsc" style="float: left" xmlns="http://www.w3.org/2000/svg"
                  width="16" height="16" fill="currentColor" class="bi bi-sort-up" viewBox="0 0 16 16">
                  <path
                    d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z" />
                </svg>

                <svg v-else-if="sortBy == 'normUnit' && !this.sortAsc" style="float: left"
                  xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-sort-down"
                  viewBox="0 0 16 16">
                  <path
                    d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z" />
                </svg>

                Units
              </th>
              <th scope="col" class="uppercase px-6 py-3">Unit of Measure</th>
              <th style="cursor: pointer" scope="col" class="uppercase px-6 py-3" @click="updateSort('ppu')">
                <svg v-if="sortBy == 'ppu' && this.sortAsc" style="float: left" xmlns="http://www.w3.org/2000/svg"
                  width="16" height="16" fill="currentColor" class="bi bi-sort-up" viewBox="0 0 16 16">
                  <path
                    d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z" />
                </svg>

                <svg v-else-if="sortBy == 'ppu' && !this.sortAsc" style="float: left" xmlns="http://www.w3.org/2000/svg"
                  width="16" height="16" fill="currentColor" class="bi bi-sort-down" viewBox="0 0 16 16">
                  <path
                    d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z" />
                </svg>

                Price per unit
              </th>
              <th scope="col" class="uppercase px-6 py-3">Chains</th>
              <th scope="col" class="uppercase px-1 py-3">Graph</th>
              <th v-if="clientData && clientData.competition" scope="col" class="uppercase px-1 py-3">Comp.</th>
              <th scope="col" class="uppercase px-1 py-3"></th>
            </tr>
          </thead>
          <tbody>
            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50"
              v-for="items in allProducts" :key="items.ean">

              <td class="px-6 py-4">
                <button v-if="beepruser && beepruser.data && beepruser.data.favs && beepruser.data.favs.includes(items.ean)" type="button"
                @click="
                  $store.dispatch('toggleFav', {
                    uid: beepruser.uid,
                    added: false,
                    value: items.ean,
                    yearmon: yearmon,
                    client: client,
                    product: items
                  })
                "
                >
                  <Icon icon="fluent-mdl2:favorite-star-fill" height="20px" />
                </button>
                <button v-else type="button"
                   @click="
                    $store.dispatch('toggleFav', {
                      uid: beepruser.uid,
                      added: true,
                      value: items.ean,
                      yearmon: yearmon,
                      client: client,
                      product: items
                    })
                  ">
                  <Icon icon="fluent-mdl2:favorite-star" height="20px" />
                </button>
                
                <a v-if="beepruser && beepruser.data && beepruser.data.email && beepruser.data.email.includes('@beepr.'+country)" class="float-left mr-2" :href="'https://products.beepr.'+country+'/edit/'+items.ean" target="_blank">
                  <Icon icon="bytesize:edit" height="20px" />
                </a>

                <a class="align-top ml-2" target="_blank" :href="
                  'https://beepr.'+country+'/products/' +
                  items.ean +
                  '/' +
                  items.name
                    .toLowerCase()
                    .trim()
                    .replace(/\./g, '')
                    .replace(/ø/g, 'oe')
                    .replace(/æ/g, 'ae')
                    .replace(/å/g, 'aa')
                    .replace(/[^a-z0-9]+/g, '-')
                ">{{ (items.importName) ? items.importName : items.name }}</a>
                <br />
                <span v-if="(!selectedPath.includes('paths') && items.path && items.path.length > 5)" style="font-size: 9px; color:gray">{{ items.path }}</span>
              </td>

              <td class="px-6 py-4">
                {{ items.brand }}
              </td>

              <td class="px-6 py-4" :style="items.offer ? 'color:green' : ''">
                {{
                    Intl.NumberFormat("da-DK", { style: "currency", currency: "DKK" }).format(
                      (items.price).toFixed(2)
                    )
                }}
              </td>
              <td class="px-6 py-4">
                {{ Intl.NumberFormat("da-DK", {}).format(items.normUnit) }}
              </td>
              <td class="px-6 py-4">
                {{ items.normUom }}
              </td>
              <td class="px-6 py-4">
                {{
                    Intl.NumberFormat("da-DK", { style: "currency", currency: "DKK" }).format(
                      items.ppu.toFixed(2)
                    )
                }}
              </td>
              <td class="px-6 py-4">
                <img v-for="c in filterChains(items.chains)" :key="items.ean + c"
                  :src="require('../assets/img/' + c + '.webp')" width="15" style="float: left" class="ml-1" :alt="c" :title="c" />
              </td>
              <td class="px-1 py-4">
<!--                 <button @click="
                  getPrices(items.ean, items.chains).then((o) => series = o);
                isOpen = {};
                isOpen[items.ean] = true;
                " type="button">
                  <Icon icon="codicon:graph-line" height="22px" />
                </button>
                <chart-modal v-if="series" :isOpen="isOpen" @closemodal="closeModal(items.ean)" :labels="labels"
                  :series="series" :ean="items.ean" :name="items.name" />
 -->              


                 <button @click="isOpen = {}; isOpen[items.ean] = true;" type="button">
                  <Icon class="mt-3" icon="codicon:graph-line" height="22px" />
                </button>
                <product-modal :isOpen="isOpen" @closemodal="closeModal(items.ean)" :ean="items.ean" :name="items.name" />
              </td>

              <td v-if="clientData && clientData.competition" class="px-1 py-4">
                <router-link v-if="items.normUnit && items.normUnit.length > 0" class="float-left mr-2" 
                  @click="allowedEAN = items.ean" 
                  :to="'/competition/?ean='+items.ean+'&unit='+Intl.NumberFormat('da-DK', { minimumFractionDigits: 1, maximumFractionDigits: 2, }).format(items.normUnit)+'&uom='+items.normUom">
                  <Icon icon="mdi:podium-gold" height="22px" />
                </router-link>
                <router-link v-else class="float-left mr-2" @click="allowedEAN = items.ean" :to="'/competition/?ean='+items.ean">
                  <Icon icon="mdi:podium-gold" height="22px" />
                </router-link>
              </td>

              <td class="px-1 py-4">
                <button title="Report a problem" :isOpen="isErrorOpen" @click="isErrorOpen = true; selectedEAN = items.ean; selectedName = items.name" type="button">
                  <Icon class="mt-3" color="red" icon="fluent:tag-error-24-regular" height="22px" />
                </button>
              </td>

            </tr>
          </tbody>
        </table>
      </div>
    </div>

     <div v-if="nopriceProducts && nopriceProducts.length > 0" class="mt-5 bg-white dark:bg-gray-800 p-5 w-full rounded-md box-border shadow clear-both">
      <h2 class="font-bold float-left text-lg text-gray-800 dark:text-gray-200">
        No price found
      </h2>

      <div class="wrapping-table mt-10">
        <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400 lg:overflow-auto overflow-x-scroll">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 sticky top-0">
            <tr>
              <th style="cursor: pointer" scope="col" class="uppercase px-6 py-3" @click="updateSort('name')">
                Product
              </th>
              <th style="cursor: pointer" scope="col" class="uppercase px-6 py-3" @click="updateSort('brand')">
                Brand
              </th>

              <th style="cursor: pointer" scope="col" class="uppercase px-6 py-3" @click="updateSort('price')">
                Price
              </th>

              <th style="cursor: pointer" scope="col" class="uppercase px-6 py-3" @click="updateSort('normUnit')">
                Units
              </th>
              <th scope="col" class="uppercase px-6 py-3">Unit of Measure</th>
              <th style="cursor: pointer" scope="col" class="uppercase px-6 py-3" @click="updateSort('ppu')">
                Price per unit
              </th>
              <th scope="col" class="uppercase px-6 py-3">Chains</th>
              <th scope="col" class="uppercase px-6 py-3">Graph</th>
              <th v-if="clientData && clientData.competition" scope="col" class="uppercase px-6 py-3">Comp.</th>
            </tr>
          </thead>
          <tbody>
            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50"
              v-for="items in nopriceProducts" :key="items.ean">

              <td class="px-6 py-4">
                <a v-if="beepruser && beepruser.data && beepruser.data.email && beepruser.data.email.includes('@beepr.'+country)" class="float-left mr-2" :href="'https://products.beepr.'+country+'/edit/'+items.ean" target="_blank">
                  <Icon icon="bytesize:edit" height="24px" />
                </a>

                <a target="_blank" :href="
                  'https://beepr.'+country+'/products/' +
                  items.ean +
                  '/' +
                  items.name
                    .toLowerCase()
                    .trim()
                    .replace(/\./g, '')
                    .replace(/ø/g, 'oe')
                    .replace(/æ/g, 'ae')
                    .replace(/å/g, 'aa')
                    .replace(/[^a-z0-9]+/g, '-')
                ">{{ (items.importName) ? items.importName : items.name }}</a>

              </td>

              <td class="px-6 py-4">
                {{ items.brand }}
              </td>

              <td class="px-6 py-4">
                {{
                    Intl.NumberFormat("da-DK", { style: "currency", currency: "DKK" }).format(
                      (items.price).toFixed(2)
                    )
                }}
              </td>
              <td class="px-6 py-4">
                {{ Intl.NumberFormat("da-DK", {}).format(items.normUnit) }}
              </td>
              <td class="px-6 py-4">
                {{ items.normUom }}
              </td>
              <td class="px-6 py-4">
                {{
                    Intl.NumberFormat("da-DK", { style: "currency", currency: "DKK" }).format(
                      items.ppu.toFixed(2)
                    )
                }}
              </td>
              <td class="px-6 py-4">
                <img v-for="c in filterChains(items.chains)" :key="items.ean + c"
                  :src="require('../assets/img/' + c + '.webp')" width="24" style="float: left" class="ml-1" alt="" />
              </td>
              <td class="px-6 py-4">
                <button @click="isOpen = {}; isOpen[items.ean] = true;" type="button">
                  <Icon icon="codicon:graph-line" height="22px" />
                </button>
                <product-modal :isOpen="isOpen" @closemodal="closeModal(items.ean)" :ean="items.ean" :name="items.name" />

<!--                 <button @click="
                  getPrices(items.ean, items.chains).then((o) => series = o);
                isOpen = {};
                isOpen[items.ean] = true;
                " type="button">
                  <Icon icon="codicon:graph-line" height="22px" />
                </button>
                <chart-modal v-if="series" :isOpen="isOpen" @closemodal="closeModal(items.ean)" :labels="labels"
                  :series="series" :ean="items.ean" :name="items.name" />
 -->              
            </td>

              <td v-if="clientData && clientData.competition" class="px-6 py-4">
                <router-link v-if="items.normUnit && items.normUnit.length > 0" class="float-left mr-2" 
                  @click="allowedEAN = items.ean" 
                  :to="'/competition/?ean='+items.ean+'&unit='+Intl.NumberFormat('da-DK', { minimumFractionDigits: 1, maximumFractionDigits: 2, }).format(items.normUnit)+'&uom='+items.normUom">
                  <Icon icon="mdi:podium-gold" height="22px" />
                </router-link>
                <router-link v-else class="float-left mr-2" @click="allowedEAN = items.ean" :to="'/competition/?ean='+items.ean">
                  <Icon icon="mdi:podium-gold" height="22px" />
                </router-link>
              </td>

            </tr>
          </tbody>
        </table>
      </div>
    </div>

     <div v-if="notfound[selectedPathSuffix + '-notfound'] && notfound[selectedPathSuffix + '-notfound'].length > 0" class="mt-5 bg-white dark:bg-gray-800 p-5 w-full rounded-md box-border shadow clear-both">
      <h2 class="font-bold float-left text-lg text-gray-800 dark:text-gray-200">
        Not found
      </h2>

      <div class="wrapping-table mt-10">
        <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400 lg:overflow-auto overflow-x-scroll">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 sticky top-0">
            <tr>
              <th scope="col" class="uppercase px-6 py-3">
                EAN
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50"
              v-for="item in notfound[selectedPathSuffix + '-notfound']" :key="item">
              <td class="px-6 py-4">
                {{ item }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
  </div>

  <error-modal :isOpen="isErrorOpen" @closemodal="closeErrorModal()" :ean="selectedEAN" :name="selectedName" />

</template>

<script>
// @ is an alias to /src
import { Icon } from "@iconify/vue";
import { mapGetters } from "vuex";
import ProductModal from "./ProductModal.vue";
import ErrorModal from "./ErrorModal.vue";
//import ChartModal from "./components/chartmodal.vue";
import Multiselect from '@vueform/multiselect'

export default {
  name: "EanDetails",/* 
  metaInfo() {
    return {
      title: "Home"
    };
  }, */
  data() {
    return {
      brands: [],
      chains: [],
      units: [],
      uoms: [],
      selectedBrands: [],
      selectedChains: [],
      selectedUnits: [],
      selectedUoms: [],
      sortBy: "ppu",
      sortAsc: true,
      labels: [],
      series: [],
      //selectedPath: "eans",
      isOpen: {},
      isErrorOpen: false,
      selectedEAN: "",
      selectedName: "",
    };
    // end chart data line
  },
  components: {
    Icon,
    //ChartModal,
    Multiselect,
    ProductModal,
    ErrorModal
  },
  watch: {
    clientData(newData, old) {
      if( newData ) {
        if (this.beepruser && this.beepruser.uid && this.beepruser.data && this.beepruser.data.favs && this.beepruser.data.favs.length > 0 ) {
          this.selectedPath = "favs:" + this.beepruser.uid;
        }else if (newData.eans && newData.eans.length > 0) {
          this.selectedPath = "eans:" + newData.eans[0].replace(/[\W_]+/g, "") + ":" + newData.eans[0];
        } else if (newData.paths && newData.paths.length > 0) {
          this.selectedPath = "paths:" + newData.paths[0].replace(/[\W_]+/g, "") + ":" + newData.paths[0];
        } else if (newData.brands && newData.brands.length > 0) {
          this.selectedPath = "brands:" + newData.brands[0].replace(/[\W_]+/g, "") + ":" + newData.brands[0];
        }
      }
    }
  },
  methods: {
    closeModal(ean) {
      this.isOpen[ean] = false;
      this.series = [];
      this.labels = [];
    },
    closeErrorModal(ean) {
      this.isErrorOpen = false;
      this.selectedEAN = "";
      this.selectedName = "";
    },
    async getPrices(ean, chains) {
      this.labels = [];
      var dateObj = new Date(
        new Date().getFullYear(),
        new Date().getMonth() - 12,
        new Date().getDate()
      );
      var day = ('0' + (dateObj.getDate())).slice(-2);
      var month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
      var year = dateObj.getFullYear();
      let date = year + "-" + month + "-" + day;

      return new Promise((resolve, reject) => {
        this.$store.dispatch("setProductPrices", ean).then(() => {
          //console.log(date);
          //console.log(this.productprices);
          let output = [];
          let tmpobj = [];
          Object.keys(this.productprices["prices"]).sort().forEach((key) => {
            Object.keys(this.productprices["prices"][key]).forEach((key2) => {
              if (this.productprices["prices"][key]["X"] > date) {
                (tmpobj[key2] = tmpobj[key2] || []).push(this.productprices["prices"][key][key2]);
              }
            });
          });

          //console.log(tmpobj)

          let sum = 0;
          let sumCount = 0;

          Object.keys(tmpobj)
            .sort()
            .forEach((key) => {
              let out = { name: key, data: tmpobj[key] };
              let chain = (key == "Føtex") ? "føtexhome" : key.toLocaleLowerCase();
              if (key == "X") {
                this.labels = tmpobj[key];
              } else if (key != "Postme" && (chains.includes(chain)) || chains.length == 0 ) {
                let tmpArr = Object.values(out['data']).filter((v) => v>0 ); 
                //console.log(tmpArr);
                sum += tmpArr.reduce((partialSum, a) => partialSum + a, 0);
                sumCount += tmpArr.reduce((partialSum, a) => partialSum + 1, 0);
                //console.log("key" + key + ":" +sum + " : " + sumCount);
                output.push(out);
              }
            });

            //console.log(sum + " : " + sumCount);
            if (sumCount > 0) {
              let avgArr = [];
              let avg = sum/sumCount;
              for (let index = 0; index < this.labels.length; index++) {
                avgArr[index] = avg;
              }
              let out = { name: 'AVG', data: avgArr };
              output.push(out);
            }
          //console.log(chains);
          //console.log(output)
          resolve(output);
        })
      });
    },
    updateSort(newSort) {
      if (newSort == this.sortBy) {
        this.sortAsc = !this.sortAsc;
      } else {
        this.sortBy = newSort;
        this.sortAsc = true;
      }
    },
    toggleView() {
      this.$router.push({ name: "home" });
    },
    filterChains(chains) {
      return chains.filter(a => a !== 'postme')
    }
  },
  computed: {
    ...mapGetters({
      country: "country",
      beepruser: "beepruser",
      client: "client",
      clientData: "clientData",
      newcurrent: "newcurrent",
      notfound: "notfound",
      noprice: "noprice",
      currYear: "currYear",
      currMon: "currMon",
      yearmon: "yearmon",
      productprices: "productprices",
      selectedPathSuffix: "selectedPathSuffix",
      //ppm: "ppm"
    }),

    json_fields2 () {
      let tmp = {
        EAN: "ean",
        Product: "name",
        Brand: "brand",
        "Lowest price": "price",
        Offer: "offer",
        Unit: "normUnit",
        "Unit of measure": "normUom",
        "Price-per-unit": "ppu",
        Chains: "chains",
      }
      return tmp;
    },

    formatNumbers() {
      let products =  JSON.parse(JSON.stringify(this.allProducts));
      products.map((p) => {
        p.ean = `'${p.ean}'`;
        p.name = (p.importName) ? p.importName : p.name;
        //p.Name = `${p.Name +" "}`;
        //p.Brand = `${p.Brand + " "}`;
        //p.chains = `${p.chains+ " "}`;
        //p.NormalizedUom = `${p.NormalizedUom+ " "}`;
        //p.LowestPrice = Math.round(p.LowestPrice * 100) / 100;
        //p.PPU = Math.round(p.PPU * 100) / 100;
        //p.NormalizedUnit = Math.round(p.NormalizedUnit * 100) / 100;
        p.price = Intl.NumberFormat("da-DK", {  minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(p.price.toFixed(2));
        p.ppu = (p.ppu) ? Intl.NumberFormat("da-DK", { minimumFractionDigits: 2, maximumFractionDigits: 2,}).format(p.ppu.toFixed(2)) : "0,00";
        p.normUnit = (p.normUnit) ? Intl.NumberFormat("da-DK", { minimumFractionDigits: 1, maximumFractionDigits: 2, }).format(p.normUnit) : "0,00";
      });
      //console.log(products)
      return products;
    },

    selectedPath: {
      get() {
        return this.$store.state.beeprStore.selectedPath;
      },
      set(value) {
        this.$store.dispatch("setSelectedPath", value);
        this.$store.dispatch("resetNewCurrent");
        this.$store.dispatch("setNewCurrent");
      },
    },

    allowedEAN: {
      get() {
        return this.$store.state.beeprStore.allowedEAN;
      },
      set(value) {
        this.$store.dispatch("setAllowedEAN", value);
      },
    },

    level: {
      get() {
        return this.$store.state.beeprStore.level;
      },
      set(value) {
        this.$store.dispatch("setLevel", value);
      },
    },

    allProducts() {
      let products = {};

      if (this.sortBy == "name" || this.sortBy == "brand") {
        if (this.sortAsc) {
          products = this.newcurrent[this.selectedPathSuffix]
            ? this.newcurrent[this.selectedPathSuffix].sort((a, b) =>
              a[this.sortBy]
                .localeCompare(b[this.sortBy], "da")
            )
            : [];
        } else {
          products = this.newcurrent[this.selectedPathSuffix]
            ? this.newcurrent[this.selectedPathSuffix].sort((a, b) =>
              b[this.sortBy].localeCompare(a[this.sortBy], "da")
            )
            : [];
        }
      } else {
        if (this.sortAsc) {
          products = this.newcurrent[this.selectedPathSuffix]
            ? this.newcurrent[this.selectedPathSuffix].sort((a, b) => a[this.sortBy] - b[this.sortBy])
            : [];
        } else {
          products = this.newcurrent[this.selectedPathSuffix]
            ? this.newcurrent[this.selectedPathSuffix].sort((a, b) => b[this.sortBy] - a[this.sortBy])
            : [];
        }
      }

      let tmp2 = products.map(function (obj) { return obj.brand; });
      this.brands = [...new Set(tmp2)].sort();

      tmp2 = products.map(function (obj) { return obj.normUnit; });
      this.units = [...new Set(tmp2)].sort();

      tmp2 = products.map(function (obj) { return obj.normUom; });
      this.uoms = [...new Set(tmp2)].sort();

      tmp2 = products.map(function (obj) { return [...obj.chains]; });
      this.chains = [...new Set(tmp2.flat())].sort();

      if (this.selectedChains.length > 0) {
        products = products.filter((o) => o.chains.some((r) => this.selectedChains.includes(r)));
      }

      if (this.selectedBrands.length > 0) {
        products = products.filter((o) => this.selectedBrands.includes(o.brand));
      }

      if (this.selectedUnits.length > 0) {
        products = products.filter((o) => this.selectedUnits.includes(o.normUnit));
      }

      if (this.selectedUoms.length > 0) {
        products = products.filter((o) => this.selectedUoms.includes(o.normUom));
      }

      return products;
    },

    allProductsCount() {
      return this.newcurrent[this.selectedPathSuffix] ? this.newcurrent[this.selectedPathSuffix].length : 0;
    },

     nopriceProducts() {
      let products = {};
          products = this.noprice[this.selectedPathSuffix + "-noprice"]
            ? this.noprice[this.selectedPathSuffix+ "-noprice"]
            : [];

      //let tmp2 = products.map(function (obj) { return [...obj.chains]; });
      //this.chains = [...new Set(tmp2.flat())].sort();

      return products;
    },
/*
    notfoundProducts() {
      let products = {};
          products = this.notfound[this.selectedPathSuffix + "-notfound"]
            ? this.notfound[this.selectedPathSuffix+ "-notfound"]
            : [];
      return products;
    },
 */
  },
  mounted() {
    this.$store.dispatch("setNewCurrent");
    this.$store.commit("CLEAR_PPM");
  },
};
</script>

<style>
.toggle-checkbox:checked {
  @apply: right-20px;
  right: 20px;
  color: #07c157;
}
</style>

<style src="@vueform/multiselect/themes/default.css"></style>