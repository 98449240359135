<template>
  <div class="dashboard p-4">
    <div class="w-full mt-2" style="height: 30px">
      <label for="cats" class="block mb-2 float-left"
        >Select Category
      </label>
      <router-link v-if="admin" class="float-right block uppercase text-sm" :to="{name:'monthreport'}">See Month Report</router-link>
    </div>

    <div v-if="admin" class="wrapper-button mt-3">
      <select
        v-model="level"
        id="levels"
        class="w-full lg:w-1/6 float-none lg:float-left bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 mr-5"
      >
      <option value="" disabled>Please select level</option>
        <option value="Cat1">
          Category 1
        </option>
        <option value="Cat2">
          Category 2
        </option>
      </select>
    </div>

    <div class="wrapper-button mt-3">
      <select
        v-model="selectedPath"
        id="cats"
        class="w-full lg:w-1/3 float-none lg:float-left bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 mr-5"
      >
      <option value="" disabled>Please select category</option>
      <option v-for="eangroup in eans" :key="eangroup" :value="eangroup.replace(/[\W_]+/g, '')">
        {{ eangroup.charAt(0).toUpperCase() + eangroup.slice(1) }}
      </option>
        <option v-for="path in clientData.paths" :key="path" :value="path.replace(/[\W_]+/g, '')">
          {{ path }}
        </option>
      </select>
    </div>

<!--     <div class="wrapper-button mt-3">
      <select
        v-model="yearmon"
        class="w-1/2 lg:w-1/5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5"
      >
        <option :value="currYear + '' + ('0' + currMon.toString().slice(-2))">This month</option>
        <option
          v-for="index in 13" :key="index"
          :value="
            (currMon - index > 0 ? currYear : currYear - 1) +
            (currMon - index > 0
              ? ('0' + (currMon - index).toString()).slice(-2)
              : ('0' + (12 + (currMon - index)).toString()).slice(-2))
          "
        >
          {{
            currMon - index > 0
              ? ("0" + (currMon - index).toString()).slice(-2)
              : ("0" + (12 + (currMon - index)).toString()).slice(-2)
          }}/{{ currMon - index > 0 ? currYear : currYear - 1 }}
        </option>
      </select>
    </div>
 -->
<div class="block float-right inline-block w-10 align-middle select-none transition duration-200 ease-in" style="color: #07C157">
    <input type="checkbox" @click="toggleView()" name="toggle" id="toggle" class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"/>
    <label for="toggle" class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"></label>
</div>
<label for="toggle" class="block uppercase float-right mr-12 text-sm">EAN DETAILS</label>


    <!-- grid wrapper card -->
    <div class="wrapper-card grid grid-cols-1 md:grid-cols-4 gap-2 pt-5 lg:grid-cols-4 clear-both">
      <!-- card  -->
      <div v-if="yearmon == (currYear + '' + ('0' + currMon.toString().slice(-2)))" class="card bg-white dark:bg-gray-800 w-full rounded-md p-5 shadow flex">
        <div class="p-2 max-w-sm">
          <div class="bg-purple-200 rounded-full w-14 h-14 text-lg p-3 text-purple-600 mx-auto">
            <span class="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                role="img"
                width="30px"
                height="30px"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 16 16"
              >
                <g fill="currentColor">
                  <path d="M15 13v1H1.5l-.5-.5V0h1v13h13Z" />
                  <path
                    d="M13 3.207L7.854 8.354h-.708L5.5 6.707l-3.646 3.647l-.708-.708l4-4h.708L7.5 7.293l5.146-5.147h.707l2 2l-.707.708L13 3.207Z"
                  />
                </g>
              </svg>
            </span>
          </div>
        </div>

        <div class="block p-2 w-full">
          <p class="font-semibold text-gray-900 dark:text-gray-200 text-xl">
            {{ offerProductsCount }}
          </p>
          <h2 class="font-normal text-gray-400 text-md mt-1">Products on offer</h2>
        </div>
      </div>
    </div>
    <!-- end wrapper card -->

    <div v-if="yearmon == (currYear + '' + ('0' + currMon.toString().slice(-2)))" class="mt-2 lg:flex block lg:gap-2">
      <div class="mt-2 bg-white dark:bg-gray-800 p-5 w-full rounded-md box-border shadow">
        <h2 class="font-bold text-lg text-gray-800 dark:text-gray-200 float-left">Products currently on offer</h2>
        <download-csv
        class   = "float-right"
        :data   = "offerProducts"
        :name    = "selectedPath+'.csv'">
        <Icon icon="iwwa:csv" height="24px"/>
      </download-csv>

        <p class="text-gray-400 font-lexend font-normal inline-flex" style="width:inherit">
          This is a list of all products currently on offer
        </p>
        <div class="wrapping-table mt-10" style="overflow: auto;">
          <table
            class="w-full text-sm text-left text-gray-500 dark:text-gray-400 lg:overflow-auto overflow-x-scroll"
          >
            <thead
              class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
            >
              <tr>
                <th
                  style="cursor: pointer"
                  scope="col"
                  class="uppercase px-6 py-3"
                  @click="updateSort('name')"
                >
                  <svg
                    v-if="sortBy == 'name' && this.sortAsc"
                    style="float: left"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-sort-up"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"
                    />
                  </svg>

                  <svg
                    v-else-if="sortBy == 'name' && !this.sortAsc"
                    style="float: left"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-sort-down"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"
                    />
                  </svg>
                  Product
                </th>
                <th
                  style="cursor: pointer"
                  scope="col"
                  class="uppercase px-6 py-3"
                  @click="updateSort('brand')"
                >
                  <svg
                    v-if="sortBy == 'brand' && this.sortAsc"
                    style="float: left"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-sort-up"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"
                    />
                  </svg>

                  <svg
                    v-else-if="sortBy == 'brand' && !this.sortAsc"
                    style="float: left"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-sort-down"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"
                    />
                  </svg>

                  Brand
                </th>

                <th
                  style="cursor: pointer"
                  scope="col"
                  class="uppercase px-6 py-3"
                  @click="updateSort('before')"
                >
                  <svg
                    v-if="sortBy == 'before' && this.sortAsc"
                    style="float: left"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-sort-up"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"
                    />
                  </svg>

                  <svg
                    v-else-if="sortBy == 'before' && !this.sortAsc"
                    style="float: left"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-sort-down"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"
                    />
                  </svg>

                  Normal
                </th>

                <th
                  style="cursor: pointer"
                  scope="col"
                  class="uppercase px-6 py-3"
                  @click="updateSort('after')"
                >
                  <svg
                    v-if="sortBy == 'after' && this.sortAsc"
                    style="float: left"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-sort-up"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"
                    />
                  </svg>

                  <svg
                    v-else-if="sortBy == 'after' && !this.sortAsc"
                    style="float: left"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-sort-down"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"
                    />
                  </svg>

                  Offer
                </th>

                <th
                  style="cursor: pointer"
                  scope="col"
                  class="uppercase px-6 py-3"
                  @click="updateSort('chain')"
                >
                  <svg
                    v-if="sortBy == 'chain' && this.sortAsc"
                    style="float: left"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-sort-up"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"
                    />
                  </svg>

                  <svg
                    v-else-if="sortBy == 'chain' && !this.sortAsc"
                    style="float: left"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-sort-down"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"
                    />
                  </svg>
                  Chain
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50"
                v-for="items in offerProducts"
                :key="items.transaction"
              >
                <td class="px-6 py-4">
                <a
                  target="_blank"
                  :href="
                    'https://beepr.'+country+'/products/' +
                    items.ean +
                    '/' +
                    items.name
                      .toLowerCase()
                      .trim()
                      .replace(/\./g, '')
                      .replace(/ø/g, 'oe')
                      .replace(/æ/g, 'ae')
                      .replace(/å/g, 'aa')
                      .replace(/[^a-z0-9]+/g, '-')
                  "
                  >{{ items.name }}</a
                >
                </td>
                <td class="px-6 py-4">
                  {{ items.brand }}
                </td>
                <td class="px-6 py-4">
                  {{
                    Intl.NumberFormat("da-DK", { style: "currency", currency: "DKK" }).format(
                      items.before
                    )
                  }}
                </td>
                <td class="px-6 py-4">
                  {{
                    items.before > items.after || items.quantity < 2
                      ? Intl.NumberFormat("da-DK", { style: "currency", currency: "DKK" }).format(
                          items.after
                        ) + ((items.before == items.after) ? "**" : "")
                      : items.quantity +
                        " for " +
                        Intl.NumberFormat("da-DK", { style: "currency", currency: "DKK" }).format(
                          items.quantity * items.before - items.quantity * items.savedper
                        )
                  }}
                </td>
                <td class="px-6 py-4">
                  <img :src="require('../assets/img/'+items.chain.toLowerCase()+'.webp')" width="30" alt="" />
                </td>
              </tr>
            </tbody>
          </table>
          <p class="text-gray-400 text-sm mt-4">** the store did not provide the 'Normal' price</p>
        </div>
        <!--         <div class="wrapper-button mt-3">
          <select
            name=""
            id=""
            class="w-1/5 dark:bg-gray-800 dark:hover:bg-gray-700 border-gray-200 dark:border-gray-300 border max-w-lg px-4 py-3 block rounded-md text-gray-500 dark:text-gray-400"
          >
            <option value="">Last month</option>
          </select>
        </div>
 -->
      </div>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import { Icon } from "@iconify/vue";
import { mapGetters } from "vuex";

export default {
  name: "Report",
/*   metaInfo() {
    return {
      title: "Product Summary"
    };
  },
 */  data() {
    return {
      sortBy: "name",
      sortAsc: true,

      // for more guide apexchart.js
      // https://apexcharts.com/docs/chart-types/line-chart/
    };
  },
  components: {
    Icon,
  },
  methods: {
    updateSort(newSort) {
      if (newSort == this.sortBy) {
        this.sortAsc = !this.sortAsc;
      } else {
        this.sortBy = newSort;
        this.sortAsc = true;
      }
    },

    toggleView() {
        this.$router.push({ name: "eandetails"});
    }
  },
  computed: {
    ...mapGetters({
      country: "country",
      beepruser: "beepruser",
      client: "client",
      clientData: "clientData",
      newcurrent: "offer",
      currYear: "currYear",
      currMon: "currMon",
    }),

    selectedPath: {
      get() {
        return this.$store.state.beeprStore.selectedPath;
      },
      set(value) {
        this.$store.dispatch("setSelectedPath", value);
        this.$store.dispatch("setData", "offer");
      },
    },

    yearmon: {
      get() {
        return this.$store.state.beeprStore.yearmon;
      },
      set(value) {
        this.$store.dispatch("setYearmon", value);
        this.$store.dispatch("setData", "offer");
      },
    },

    level: {
      get() {
        return this.$store.state.beeprStore.level;
      },
      set(value) {
        this.$store.dispatch("setLevel", value);
      },
    },

    offerProducts() {
      //return this.newcurrent[this.selectedPath + "-offer"]
      //  ? this.newcurrent[this.selectedPath + "-offer"]
      //  : [];

      if (this.sortBy == "name" || this.sortBy == "brand" || this.sortBy == "chain") {
        if (this.sortAsc) {
          return this.newcurrent[this.selectedPath + "-offer"]
            ? this.newcurrent[this.selectedPath + "-offer"].sort((a, b) =>
                a[this.sortBy].localeCompare(b[this.sortBy], "da")
              )
            : [];
        } else {
          return this.newcurrent[this.selectedPath + "-offer"]
            ? this.newcurrent[this.selectedPath + "-offer"].sort((a, b) =>
                b[this.sortBy].localeCompare(a[this.sortBy], "da")
              )
            : [];
        }
      } else {
        if (this.sortAsc) {
          return this.newcurrent[this.selectedPath + "-offer"]
            ? this.newcurrent[this.selectedPath + "-offer"].sort(
                (a, b) => a[this.sortBy] - b[this.sortBy]
              )
            : [];
        } else {
          return this.newcurrent[this.selectedPath + "-offer"]
            ? this.newcurrent[this.selectedPath + "-offer"].sort(
                (a, b) => b[this.sortBy] - a[this.sortBy]
              )
            : [];
        }
      }
    },

    offerProductsCount() {
      return this.newcurrent[this.selectedPath + "-offer"]
        ? this.newcurrent[this.selectedPath + "-offer"].length
        : 0;
    },

    admin() {
      //console.log(this.beepruser.data.client)
      if (this.clientData && this.clientData.beeprreport) {
        //console.log("Push to report");
        //this.$router.push({ name: "report"});
        return true;
      } else {
        this.$router.push({ name: "home"});
        return false;
      }
    },
    eans () {
      if (this.admin && this.clientData && this.clientData.eans) {
        return this.clientData.eans.filter((o) => o.startsWith(this.level)).sort();
      } else {
        return this.clientData.eans;
      }
    }
  },
  mounted() {
  },
};
</script>

<style>
</style>