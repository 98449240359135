<template>
  <div class="dashboard p-4">
    <label for="cats" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">Select Category
    </label>

    <div v-if="admin" class="wrapper-button mt-3">
      <select v-model="level" id="levels"
        class="w-full lg:w-1/6 float-none lg:float-left bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 mr-5">
        <option value="" disabled>Please select level</option>
        <option value="Cat1">
          Category 1
        </option>
        <option value="Cat2">
          Category 2
        </option>
      </select>
    </div>

    <div class="wrapper-button mt-3">
      <select v-model="selectedBeeprPath" id="cats"
        class="w-full lg:w-1/3 float-none lg:float-left bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 mr-5">
        <option value="" disabled>Please select category</option>
        <option v-for="eangroup in eans" :key="eangroup" :value="eangroup.replace(/[\W_]+/g, '')">
          {{ eangroup.charAt(0).toUpperCase() + eangroup.slice(1) }}
        </option>
        <option v-for="path in beeprData.paths" :key="path" :value="path.replace(/[\W_]+/g, '')">
          {{ path }}
        </option>
      </select>
    </div>

    <Multiselect
    v-if="chains.length > 0" class="w-full md:w-1/4 mb-5 mt-5 float-left clear-both"
    v-model="selectedChains"
    mode="tags"
    placeholder="Filter chain/s"
    :close-on-select="false"
    :filter-results="true"
    :min-chars="10000"
    :resolve-on-load="false"
    :infinite="false"
    :clear-on-search="false"
    :delay="0"
    :searchable="true"
    :options="chains"
    :limit="100"
  />

    <Multiselect
    v-if="brands.length > 0" class="w-full md:w-1/4 md:ml-5 mb-5 mt-5 float-left"
    v-model="selectedBrands"
    mode="tags"
    placeholder="Filter brand/s"
    :close-on-select="false"
    :filter-results="true"
    :min-chars="10000"
    :resolve-on-load="false"
    :infinite="false"
    :clear-on-search="false"
    :delay="0"
    :searchable="true"
    :options="brands"
  />

<!--     <div v-if="chains.length > 0" class="w-full md:w-1/4 mt-5 mb-5 float-left clear-both">
      <label class="block">
        <span class="text-gray-700">Filter by Chain/s</span>
        <button class="float-right" @click="selectedChains = []">Clear</button>
        <select v-model="selectedChains" class="block w-full mt-1 rounded" multiple="">
          <option v-for="chain in chains" :value="chain" :key="chain">{{ (chain == 'føtexhome') ? "Føtex" :
              chain[0].toUpperCase() + chain.slice(1)
          }}</option>
        </select>
      </label>
    </div>

    <div v-if="brands.length > 0" class="w-full md:w-1/4 mt-5 md:ml-5 mb-5 float-left">
      <label class="block">
        <span class="text-gray-700">Filter by Brand/s</span>
        <button class="float-right" @click="selectedBrands = []">Clear</button>
        <select v-model="selectedBrands" class="block w-full mt-1 rounded" multiple="">
          <option v-for="brand in brands" :key="brand">{{ brand }}</option>
        </select>
      </label>
    </div>
 -->
    <div class="mt-5 bg-white dark:bg-gray-800 p-5 w-full rounded-md box-border shadow clear-both">
      <h2 class="font-bold float-left text-lg text-gray-800 dark:text-gray-200">
        All products in category
      </h2>
      <download-csv class="float-right" :data="allProducts" :name="selectedBeeprPath + '.csv'">
        <Icon icon="iwwa:csv" height="24px" />
      </download-csv>

      <!--       <p class="text-gray-400 font-lexend font-normal">All the products in the selected category</p>
 -->
      <div class="wrapping-table mt-10" style="overflow: auto; max-height: 55vh">
        <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400 lg:overflow-auto overflow-x-scroll">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 sticky top-0">
            <tr>
              <th style="cursor: pointer" scope="col" class="uppercase px-6 py-3" @click="updateSort('name')">
                <svg v-if="sortBy == 'name' && this.sortAsc" style="float: left" xmlns="http://www.w3.org/2000/svg"
                  width="16" height="16" fill="currentColor" class="bi bi-sort-up" viewBox="0 0 16 16">
                  <path
                    d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z" />
                </svg>

                <svg v-else-if="sortBy == 'name' && !this.sortAsc" style="float: left"
                  xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-sort-down"
                  viewBox="0 0 16 16">
                  <path
                    d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z" />
                </svg>
                Product
              </th>
              <th style="cursor: pointer" scope="col" class="uppercase px-6 py-3" @click="updateSort('brand')">
                <svg v-if="sortBy == 'brand' && this.sortAsc" style="float: left" xmlns="http://www.w3.org/2000/svg"
                  width="16" height="16" fill="currentColor" class="bi bi-sort-up" viewBox="0 0 16 16">
                  <path
                    d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z" />
                </svg>

                <svg v-else-if="sortBy == 'brand' && !this.sortAsc" style="float: left"
                  xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-sort-down"
                  viewBox="0 0 16 16">
                  <path
                    d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z" />
                </svg>

                Brand
              </th>
              <th style="cursor: pointer" scope="col" class="uppercase px-6 py-3" @click="updateSort('price')">
                <svg v-if="sortBy == 'price' && this.sortAsc" style="float: left" xmlns="http://www.w3.org/2000/svg"
                  width="16" height="16" fill="currentColor" class="bi bi-sort-up" viewBox="0 0 16 16">
                  <path
                    d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z" />
                </svg>

                <svg v-else-if="sortBy == 'price' && !this.sortAsc" style="float: left"
                  xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-sort-down"
                  viewBox="0 0 16 16">
                  <path
                    d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z" />
                </svg>

                Price
              </th>

              <th style="cursor: pointer" scope="col" class="uppercase px-6 py-3" @click="updateSort('normUnit')">
                <svg v-if="sortBy == 'normUnit' && this.sortAsc" style="float: left" xmlns="http://www.w3.org/2000/svg"
                  width="16" height="16" fill="currentColor" class="bi bi-sort-up" viewBox="0 0 16 16">
                  <path
                    d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z" />
                </svg>

                <svg v-else-if="sortBy == 'normUnit' && !this.sortAsc" style="float: left"
                  xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-sort-down"
                  viewBox="0 0 16 16">
                  <path
                    d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z" />
                </svg>

                Units
              </th>
              <th scope="col" class="uppercase px-6 py-3">Unit of Measure</th>
              <th style="cursor: pointer" scope="col" class="uppercase px-6 py-3" @click="updateSort('ppu')">
                <svg v-if="sortBy == 'ppu' && this.sortAsc" style="float: left" xmlns="http://www.w3.org/2000/svg"
                  width="16" height="16" fill="currentColor" class="bi bi-sort-up" viewBox="0 0 16 16">
                  <path
                    d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z" />
                </svg>

                <svg v-else-if="sortBy == 'ppu' && !this.sortAsc" style="float: left" xmlns="http://www.w3.org/2000/svg"
                  width="16" height="16" fill="currentColor" class="bi bi-sort-down" viewBox="0 0 16 16">
                  <path
                    d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z" />
                </svg>

                Price per unit
              </th>
              <th scope="col" class="uppercase px-6 py-3">Chains</th>
              <th scope="col" class="uppercase px-6 py-3">Graph</th>
            </tr>
          </thead>
          <tbody>
            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50"
              v-for="items in allProducts" :key="items.ean">
              <td class="px-6 py-4">
                <a target="_blank" :href="
                  'https://beepr.'+country+'/products/' +
                  items.ean +
                  '/' +
                  items.name
                    .toLowerCase()
                    .trim()
                    .replace(/\./g, '')
                    .replace(/ø/g, 'oe')
                    .replace(/æ/g, 'ae')
                    .replace(/å/g, 'aa')
                    .replace(/[^a-z0-9]+/g, '-')
                ">{{ items.name }}</a>
              </td>
              <td class="px-6 py-4">
                {{ items.brand }}
              </td>
              <td class="px-6 py-4">
                {{
                    Intl.NumberFormat("da-DK", { style: "currency", currency: "DKK" }).format(
                      (items.price).toFixed(2)
                    )
                }}
              </td>
              <td class="px-6 py-4">
                {{ Intl.NumberFormat("da-DK", {}).format(items.normUnit) }}
              </td>
              <td class="px-6 py-4">
                {{ items.normUom }}
              </td>
              <td class="px-6 py-4">
                {{
                    Intl.NumberFormat("da-DK", { style: "currency", currency: "DKK" }).format(
                      items.ppu.toFixed(2)
                    )
                }}
              </td>
              <td class="px-6 py-4">
                <img v-for="c in filterChains(items.chains)" :key="items.ean + c"
                  :src="require('../assets/img/' + c + '.webp')" width="24" style="float: left" class="ml-1" alt="" />
              </td>
              <td class="px-6 py-4">
                <button @click="
                  getPrices(items.ean, items.chains).then((o) => series = o);
                isOpen = {};
                isOpen[items.ean] = true;
                " class="text-black" type="button">
                  <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="20px" height="20px"
                    preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16">
                    <g fill="currentColor">
                      <path d="M15 13v1H1.5l-.5-.5V0h1v13h13Z" />
                      <path
                        d="M13 3.207L7.854 8.354h-.708L5.5 6.707l-3.646 3.647l-.708-.708l4-4h.708L7.5 7.293l5.146-5.147h.707l2 2l-.707.708L13 3.207Z" />
                    </g>
                  </svg>
                </button>
                <chart-modal v-if="series" :isOpen="isOpen" @closemodal="closeModal(items.ean)" :labels="labels"
                  :series="series" :ean="items.ean" :name="items.name" />
              </td>

              <!--               <td class="px-6 py-4">
              <app-accordion>
              <template v-slot:title> Click</template>
              
              <template v-slot:content>
                  <chart-modal :isOpen="isOpen" @closemodal="isOpen = false" :labels="labels" :series="series" />
              </template>
            </app-accordion>

              </td>
 -->
            </tr>
          </tbody>
        </table>


        
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { Icon } from "@iconify/vue";
import { mapGetters } from "vuex";
import ChartModal from "./components/chartmodal.vue";
import Multiselect from '@vueform/multiselect'

export default {
  name: "Beepr Report Details",
/*   metaInfo() {
    return {
      title: "Beepr Report Details"
    };
  },
 */  data() {
    return {
      brands: [],
      chains: [],
      selectedBrands: [],
      selectedChains: [],
      sortBy: "ppu",
      sortAsc: true,
      labels: [],
      series: [],
      //selectedBeeprPath: "eans",
      isOpen: {},
    };
    // end chart data line
  },
  components: {
    Icon,
    ChartModal,
    Multiselect
  },
  methods: {
    closeModal(ean) {
      this.isOpen[ean] = false;
      this.series = [];
      this.labels = [];
    },
    async getPrices(ean, chains) {
      this.labels = [];
      var dateObj = new Date(
        new Date().getFullYear(),
        new Date().getMonth() - 12,
        new Date().getDate()
      );
      var day = ('0' + (dateObj.getDate())).slice(-2);
      var month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
      var year = dateObj.getFullYear();
      let date = year + "-" + month + "-" + day;

      return new Promise((resolve, reject) => {
        this.$store.dispatch("setProductPrices", ean).then(() => {
          //console.log(this.productprices);
          let output = [];
          let tmpobj = [];
          Object.keys(this.productprices["prices"]).sort().forEach((key) => {
            Object.keys(this.productprices["prices"][key]).forEach((key2) => {
              if (this.productprices["prices"][key]["X"] > date) {
                (tmpobj[key2] = tmpobj[key2] || []).push(this.productprices["prices"][key][key2]);
              }
            });
          });

          Object.keys(tmpobj)
            .sort()
            .forEach((key) => {
              let out = { name: key, data: tmpobj[key] };
              let chain = (key == "Føtex") ? "føtexhome" : key.toLocaleLowerCase();
              if (key == "X") {
                this.labels = tmpobj[key];
              } else if (key != "Postme" && chains.includes(chain)) {
                output.push(out);
              }
            });
          //console.log(this.labels);
          //console.log(output)
          resolve(output);
        })
      });
    },
    updateSort(newSort) {
      if (newSort == this.sortBy) {
        this.sortAsc = !this.sortAsc;
      } else {
        this.sortBy = newSort;
        this.sortAsc = true;
      }
    },
    toggleView() {
      this.$router.push({ name: "report" });
    },
    filterChains(chains) {
      return chains.filter(a => a !== 'postme')
    }
  },
  computed: {
    ...mapGetters({
      country: "country",
      beepruser: "beepruser",
      client: "client",
      clientData: "clientData",
      beeprData: "beeprData",
      newcurrent: "newcurrent",
      //prices: "prices",
      currYear: "currYear",
      currMon: "currMon",
      productprices: "productprices"
    }),

    selectedBeeprPath: {
      get() {
        return this.$store.state.beeprStore.selectedBeeprPath;
      },
      set(value) {
        this.$store.dispatch("setSelectedBeeprPath", value);
        this.$store.dispatch("resetNewCurrent");
        this.$store.dispatch("setBeeprDetails");
        //this.$store.dispatch("resetPrices");
        //this.$store.dispatch("setPrices", value);
      },
    },

    level: {
      get() {
        return this.$store.state.beeprStore.level;
      },
      set(value) {
        this.$store.dispatch("setLevel", value);
      },
    },

    allProducts() {
      let products = {};

      if (this.sortBy == "name" || this.sortBy == "brand") {
        if (this.sortAsc) {
          products = this.newcurrent[this.selectedBeeprPath]
            ? this.newcurrent[this.selectedBeeprPath].sort((a, b) =>
              a[this.sortBy]
                .localeCompare(b[this.sortBy], "da")
            )
            : [];
        } else {
          products = this.newcurrent[this.selectedBeeprPath]
            ? this.newcurrent[this.selectedBeeprPath].sort((a, b) =>
              b[this.sortBy].localeCompare(a[this.sortBy], "da")
            )
            : [];
        }
      } else {
        if (this.sortAsc) {
          products = this.newcurrent[this.selectedBeeprPath]
            ? this.newcurrent[this.selectedBeeprPath].sort((a, b) => a[this.sortBy] - b[this.sortBy])
            : [];
        } else {
          products = this.newcurrent[this.selectedBeeprPath]
            ? this.newcurrent[this.selectedBeeprPath].sort((a, b) => b[this.sortBy] - a[this.sortBy])
            : [];
        }
      }
      let tmp = products.map(function (obj) { return obj.brand; });
      this.brands = [...new Set(tmp)].sort();

      tmp = products.map(function (obj) { return [...obj.chains]; });
      this.chains = [...new Set(tmp.flat())].sort();

      if (this.selectedChains.length > 0) {
        products = products.filter((o) => o.chains.some((r) => this.selectedChains.includes(r)));
      }

      if (this.selectedBrands.length > 0) {
        products = products.filter((o) => this.selectedBrands.includes(o.brand));
      }
      return products;
    },

    allProductsCount() {
      return this.newcurrent[this.selectedBeeprPath] ? this.newcurrent[this.selectedBeeprPath].length : 0;
    },

    admin() {
      //console.log(this.beepruser.data.client)
      if (this.clientData && this.clientData.beeprreport) {
        //console.log("Push to report");
        //this.$router.push({ name: "report"});
        return true;
      } else {
        this.$router.push({ name: "home"});
        return false;
      }
    },

    eans() {
      if (this.admin && this.beeprData && this.beeprData.eans) {
        return this.beeprData.eans.filter((o) => o.startsWith(this.level)).sort();
      } else {
        return this.clientData.eans;
      }
    }

  },
  mounted() {
    this.$store.dispatch("setBeeprData");
    this.$store.dispatch("setBeeprDetails");
  },
};
</script>

<style>
.toggle-checkbox:checked {
  @apply: right-16px;
  right: 16px;
  color: #07c157;
}
</style>