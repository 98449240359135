<template>
  <div class="dashboard p-4">
    <div class="wrapper-button mt-3">
      <select v-model="selectedPath" id="cats"
        class="w-full lg:w-3/12 float-none lg:float-left bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 mr-5 mb-5">
        <option value="" disabled>Please select category</option>
        <option v-if="beepruser && beepruser.uid" :value="'favs:' + beepruser.uid">My Favorites</option>

        <option disabled>──────────EAN Groups──────────</option>
        <option v-if="clientData && clientData.eans" v-for="eangroup in clientData.eans.sort()" :key="eangroup" :value="'eans:'+eangroup.replace(/[\W_]+/g, '') + ':' + eangroup">
          {{ eangroup.charAt(0).toUpperCase() + eangroup.slice(1) }}
        </option>

        <option disabled>──────────Categories──────────</option>
        <option v-if="clientData && clientData.paths" v-for="path in clientData.paths.sort()" :key="path" :value="'paths:'+path.replace(/[\W_]+/g, '') + ':' + path">
          {{ path }}
        </option>

        <option disabled>────────────Brands────────────</option>
        <option v-if="clientData && clientData.brands" v-for="brand in clientData.brands.sort()" :key="brand" :value="'brands:' + brand.replace(/[\W_]+/g, '') + ':' + brand">
        {{ brand }}
      </option>
      </select>
    </div>

    <div class="wrapper-button mt-3">
      <select
        v-model="yearmon"
        class="w-1/2 lg:w-1/5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5"
      >
        <option :value="currYear + '' + ('0' + currMon.toString()).slice(-2)">This month</option>
        <option
          v-for="index in 13" :key="'m'+index"
          :value="
            (currMon - index > 0 ? currYear : currYear - 1) +
            (currMon - index > 0
              ? ('0' + (currMon - index).toString()).slice(-2)
              : ('0' + (12 + (currMon - index)).toString()).slice(-2))
          "
        >
          {{
            currMon - index > 0
              ? ("0" + (currMon - index).toString()).slice(-2)
              : ("0" + (12 + (currMon - index)).toString()).slice(-2)
          }}/{{ currMon - index > 0 ? currYear : currYear - 1 }}
        </option>
      </select>
    </div>

    <div v-if="selectedPath.includes('favs:')" class="clear-both text-sm">
      ** if you added a product to your favorites today, it will appear here after the daily update tomorrow morning.
    </div>


<!--     <div
      class="block float-right -mt-8 inline-block w-10 align-middle select-none transition duration-200 ease-in"
      style="color: #07c157"
    >
      <input
        type="checkbox"
        @click.native="toggleView()"
        name="toggle"
        id="toggle"
        class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
      />
      <label
        for="toggle"
        class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
      ></label>
    </div>
    <label for="toggle" class="block uppercase float-right -mt-7 mr-12 text-sm">ALL PRODUCTS</label>
 -->
    <div v-if="
        selectedPathSuffix != '' &&
        beepruser.data &&
        beepruser.data.subs &&
        beepruser.data.subs.includes(selectedPathSuffix)
      "
      class="pt-4 pb-3"
      style="cursor: pointer"
      @click="
        $store.dispatch('toggleSubscribe', {
          uid: beepruser.uid,
          added: false,
          value: selectedPathSuffix,
        })
      "
    >
      <svg
        width="30px"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
        style="float: left; enable-background: new 0 0 512 512"
        xml:space="preserve"
      >
        <circle style="fill: #3cb54a" cx="408" cy="336.003" r="103.998" />
        <path
          style="fill: #0e9347"
          d="M495.999,319.999c-8.837,0-16,7.163-16,16c0,39.765-32.236,71.999-71.999,71.999  c-8.837,0-16,7.163-16,16c0,8.837,7.163,16,16,16c57.437,0,104-46.563,104-104C511.999,327.163,504.836,319.999,495.999,319.999z"
        />
        <path
          style="fill: #89c763"
          d="M320,351.999c8.837,0,16-7.163,16-16c0-39.765,32.236-71.999,71.999-71.999c8.837,0,16-7.163,16-16  s-7.163-16-16-16c-57.437,0-104,46.563-104,104C304,344.836,311.163,351.999,320,351.999z"
        />
        <path
          style="fill: #ffffff"
          d="M467.28,292.72c-6.245-6.252-16.375-6.257-22.628-0.013c-0.004,0.004-0.008,0.008-0.013,0.013  L392,345.44l-19.04-18.88c-0.456-0.653-0.964-1.269-1.52-1.84c-6.245-6.252-16.375-6.257-22.628-0.013  c-0.004,0.004-0.008,0.008-0.013,0.013c-6.252,6.245-6.257,16.376-0.013,22.628c0.004,0.004,0.008,0.008,0.013,0.013l0,0l32,32  c6.245,6.252,16.375,6.257,22.628,0.013c0.004-0.004,0.008-0.008,0.013-0.013l63.999-63.999l0,0  c6.207-6.289,6.142-16.419-0.147-22.627C467.288,292.728,467.284,292.724,467.28,292.72z"
        />
        <path
          style="fill: #dddfe1"
          d="M467.28,292.72c-1.369-1.341-2.967-2.425-4.72-3.2c2.709,6.052,1.404,13.147-3.279,17.84l0,0  l-63.999,63.999c-6.245,6.252-16.376,6.257-22.628,0.013c-0.004-0.004-0.008-0.008-0.013-0.013l8,8  c6.245,6.252,16.375,6.257,22.628,0.013c0.004-0.004,0.008-0.008,0.013-0.013l63.999-63.999l0,0  C473.099,309.194,472.741,299.222,467.28,292.72z"
        />
        <path
          style="fill: #0e9347"
          d="M475.28,300.72c-1.369-1.341-2.967-2.425-4.72-3.2c2.709,6.052,1.404,13.147-3.279,17.84l0,0  l-63.999,63.999c-6.245,6.252-16.375,6.257-22.628,0.013c-0.004-0.004-0.008-0.008-0.013-0.013l8,8  c6.245,6.252,16.376,6.257,22.628,0.013c0.004-0.004,0.008-0.008,0.013-0.013l63.999-63.999l0,0  C480.903,317.238,480.363,307.336,475.28,300.72z"
        />
        <path
          style="fill: #ffcb5b"
          d="M273.04,319.199c9.302-74.533,77.263-127.413,151.795-118.11  c27.515,3.434,53.322,15.196,73.964,33.71c3.358,2.873,8.408,2.479,11.279-0.879c1.222-1.428,1.902-3.242,1.921-5.121V96  c0-13.255-10.745-24-24-24H22.64C10.137,72,0,82.136,0,94.639v322.72c0,12.503,10.136,22.64,22.64,22.64l0,0H300.8  c4.418-0.044,7.964-3.66,7.921-8.079c-0.019-1.879-0.698-3.693-1.921-5.121C280.506,397.508,268.191,358.261,273.04,319.199z"
        />
        <path
          style="fill: #ffdb6f"
          d="M503.999,108.56L280,234.719c-14.606,8.21-32.435,8.21-47.04,0L8,108.56  c-4.97-2.869-8.023-8.181-8-13.92l0,0c0-12.503,10.136-22.64,22.64-22.64h466.72c12.503,0,22.64,10.136,22.64,22.64l0,0l0,0  C512.022,100.378,508.97,105.69,503.999,108.56z"
        />
        <path
          style="fill: #f7b84e"
          d="M511.999,95.2c-0.173,5.538-3.2,10.592-8,13.36L280,234.719c-14.606,8.21-32.435,8.21-47.04,0  L8,108.56c-1.196-0.682-2.298-1.516-3.279-2.48c-0.817-0.825-1.541-1.737-2.16-2.72v-0.4c-1.466-2.416-2.266-5.175-2.32-8H0V96v16  c-0.011,5.528,2.832,10.67,7.52,13.6l223.039,139.36l2.56,1.6c14.283,8.772,22.956,24.359,22.88,41.12v51.76  c0,26.819-21.741,48.56-48.56,48.56H72.001c-17.674,0-32,14.327-32,32h260.8c4.418-0.044,7.964-3.66,7.921-8.079  c-0.019-1.879-0.698-3.693-1.921-5.121c-49.896-56.144-44.83-142.105,11.314-191.999c51.525-45.791,129.161-45.791,180.686,0  c3.358,2.873,8.408,2.479,11.279-0.879c1.222-1.428,1.902-3.242,1.921-5.121V96C511.999,96,511.999,95.52,511.999,95.2L511.999,95.2  z"
        />
        <g>
          <path
            style="fill: #ffe278"
            d="M211.92,177.039l-63.04-35.04c-7.733-4.276-10.535-14.011-6.259-21.744   c2.862-5.176,8.346-8.351,14.259-8.256H160c4.418,0,8-3.582,8-8c0-4.418-3.582-8-8-8H64.001c-2.869-0.011-5.525,1.515-6.96,4   c-2.143,3.864-0.749,8.733,3.114,10.877c0.002,0.001,0.004,0.002,0.006,0.003l144,80c3.543,2.639,8.556,1.905,11.194-1.639   c2.639-3.543,1.905-8.556-1.639-11.194C213.162,177.634,212.559,177.296,211.92,177.039z"
          />
          <path
            style="fill: #ffe278"
            d="M192,96h48c4.418,0,8,3.582,8,8l0,0c0,4.418-3.582,8-8,8h-48c-4.418,0-8-3.582-8-8l0,0   C184,99.581,187.582,96,192,96z"
          />
        </g>
        <path
          style="fill: #f19920"
          d="M279.52,234.799c-14.606,8.21-32.435,8.21-47.04,0l-224-125.84c0.827,0.782,1.71,1.504,2.64,2.16  l219.36,136c15.507,9.629,35.133,9.629,50.639,0l219.36-136c0.93-0.656,1.812-1.378,2.64-2.16L279.52,234.799z"
        />
      </svg>

      <p class="ml-10">
        You are subscribed to email updates in this category - click here to cancel
      </p>
    </div>

    <div v-else-if="selectedPathSuffix != ''"
      class="pt-4 pb-3"
      style="cursor: pointer"
      @click="
        $store.dispatch('toggleSubscribe', {
          uid: beepruser.uid,
          added: true,
          value: selectedPathSuffix,
        })
      "
    >
      <transition mode="out-in" name="bounce" appear>
        <svg
          width="30px"
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 512.001 512.001"
          style="float: left; enable-background: new 0 0 512.001 512.001"
          xml:space="preserve"
        >
          <circle style="fill: #ee2c39" cx="408" cy="336.004" r="103.998" />
          <path
            style="fill: #cc202c"
            d="M462.56,329.439c3.623,8.059,0.026,17.531-8.033,21.153c-2.052,0.922-4.276,1.402-6.527,1.407h-80
	c-2.264-0.01-4.5-0.501-6.56-1.44c2.577,5.734,8.273,9.427,14.56,9.44h80c8.837-0.041,15.966-7.237,15.927-16.073
	C471.897,337.684,468.241,332.028,462.56,329.439z"
          />
          <path
            style="fill: #ffffff"
            d="M448,320h-80c-8.837,0-16,7.163-16,16s7.163,16,16,16h80c8.837,0,16-7.163,16-16S456.836,320,448,320
	z"
          />
          <path
            style="fill: #cc202c"
            d="M495.999,320c-8.837,0-16,7.163-16,16c0,39.765-32.236,71.999-71.999,71.999c-8.837,0-16,7.163-16,16
	c0,8.837,7.163,16,16,16c57.437,0,104-46.563,104-104C511.999,327.164,504.836,320,495.999,320z"
          />
          <path
            style="fill: #f16351"
            d="M320,351.999c8.837,0,16-7.163,16-16c0-39.765,32.236-71.999,71.999-71.999c8.837,0,16-7.163,16-16
	s-7.163-16-16-16c-57.437,0-104,46.563-104,104C304,344.836,311.163,351.999,320,351.999z"
          />
          <path
            style="fill: #dddfe1"
            d="M367.999,351.999c0-4.418,3.582-8,8-8h80c2.264-0.01,4.5-0.501,6.56-1.44
	c-2.577,5.734-8.273,9.427-14.56,9.44H367.999z"
          />
          <path
            style="fill: #ffcb5b"
            d="M273.04,319.12c9.342-74.528,77.331-127.371,151.859-118.029
	c27.492,3.446,53.276,15.207,73.902,33.709c3.358,2.873,8.408,2.479,11.279-0.879c1.222-1.428,1.902-3.242,1.921-5.121V96
	c0-13.255-10.745-24-24-24H22.64C10.137,72.001,0,82.136,0,94.64v322.72C0,429.863,10.136,440,22.64,440l0,0H300.8
	c4.418-0.044,7.964-3.66,7.921-8.079c-0.019-1.879-0.698-3.693-1.921-5.121C280.483,397.489,268.167,358.209,273.04,319.12z"
          />
          <path
            style="fill: #ffdb6f"
            d="M503.999,108.56L280,234.719c-14.606,8.21-32.435,8.21-47.04,0L8,108.56
	c-4.97-2.869-8.023-8.181-8-13.92l0,0c0-12.503,10.136-22.64,22.64-22.64h466.72c12.503,0,22.64,10.136,22.64,22.64l0,0l0,0
	C512.022,100.379,508.97,105.691,503.999,108.56z"
          />
          <path
            style="fill: #f7b84e"
            d="M511.999,95.2c-0.173,5.538-3.2,10.592-8,13.36L280,234.719c-14.606,8.21-32.435,8.21-47.04,0
	L8,108.56c-1.196-0.682-2.298-1.516-3.279-2.48c-0.817-0.825-1.541-1.737-2.16-2.72v-0.4c-1.466-2.416-2.266-5.175-2.32-8H0V96v16
	c-0.011,5.528,2.832,10.67,7.52,13.6l223.039,139.36l2.56,1.6c14.283,8.772,22.956,24.359,22.88,41.12v51.76
	c0,26.819-21.741,48.56-48.56,48.56H72.001c-17.674,0-32,14.327-32,32h260.8c4.418-0.044,7.964-3.66,7.921-8.079
	c-0.019-1.879-0.698-3.693-1.921-5.121c-49.896-56.144-44.83-142.105,11.314-191.999c51.525-45.791,129.161-45.791,180.686,0
	c3.358,2.873,8.408,2.479,11.279-0.879c1.222-1.428,1.902-3.242,1.921-5.121V96C511.999,96,511.999,95.52,511.999,95.2L511.999,95.2
	z"
          />
          <g>
            <path
              style="fill: #ffe278"
              d="M211.92,177.04L148.88,142c-7.733-4.276-10.535-14.011-6.259-21.744
		c2.862-5.176,8.346-8.351,14.259-8.256H160c4.418,0,8-3.582,8-8s-3.582-8-8-8H64.001c-2.869-0.011-5.525,1.515-6.96,4
		c-2.143,3.864-0.749,8.733,3.114,10.877c0.002,0.001,0.004,0.002,0.006,0.003l144,80c3.543,2.639,8.556,1.905,11.194-1.639
		c2.639-3.543,1.905-8.556-1.639-11.194C213.162,177.635,212.559,177.297,211.92,177.04z"
            />
            <path
              style="fill: #ffe278"
              d="M192,96h48c4.418,0,8,3.582,8,8l0,0c0,4.418-3.582,8-8,8h-48c-4.418,0-8-3.582-8-8l0,0
		C184,99.581,187.582,96,192,96z"
            />
          </g>
          <path
            style="fill: #f19920"
            d="M279.52,234.719c-14.606,8.21-32.435,8.21-47.04,0L8.48,108.88c0.827,0.782,1.71,1.504,2.64,2.16
	l219.36,136c15.507,9.629,35.133,9.629,50.639,0l219.36-136c0.93-0.656,1.812-1.378,2.64-2.16L279.52,234.719z"
          />
        </svg>
      </transition>
      <p class="ml-10">Click here to receive emails upon change in this category - daily checks</p>
    </div>

    <div v-if="loading" class="wrapping-table mt-10" style="overflow: auto; max-height: 55vh">
      <base-progress indeterminate></base-progress>
    </div>

    <div v-else>
    <!-- grid wrapper card -->
    <div class="wrapper-card grid grid-cols-1 md:grid-cols-2 gap-2 mt-5 lg:grid-cols-5">
      <!-- card  -->
      <div
        v-if="yearmon == currYear + '' + ('0' + currMon.toString()).slice(-2)"
        class="card bg-white dark:bg-gray-800 w-full rounded-md p-5 shadow flex"
      >
        <div class="p-2 max-w-sm">
          <div class="bg-purple-200 rounded-full w-14 h-14 text-lg p-3 text-purple-600 mx-auto">
            <span class="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                role="img"
                width="30px"
                height="30px"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 16 16"
              >
                <g fill="currentColor">
                  <path d="M15 13v1H1.5l-.5-.5V0h1v13h13Z" />
                  <path
                    d="M13 3.207L7.854 8.354h-.708L5.5 6.707l-3.646 3.647l-.708-.708l4-4h.708L7.5 7.293l5.146-5.147h.707l2 2l-.707.708L13 3.207Z"
                  />
                </g>
              </svg>
            </span>
          </div>
        </div>

        <div class="block p-2 w-full">
          <p class="font-semibold text-gray-900 dark:text-gray-200 text-xl">
            {{ offerProductsCount }}
          </p>
          <h2 class="font-normal text-gray-400 text-md mt-1">On offer</h2>
        </div>
      </div>
      <!-- end card -->

      <!-- card  -->
      <a href="#higher">
      <div class="card bg-white dark:bg-gray-800 w-full rounded-md p-5 shadow flex">
        <div class="p-2 max-w-sm">
          <div class="bg-blue-200 rounded-full w-14 h-14 text-lg p-3 text-blue-600 mx-auto">
            <span class="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                role="img"
                width="30px"
                height="30px"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 16 16"
              >
                <g fill="currentColor">
                  <path d="M15 13v1H1.5l-.5-.5V0h1v13h13Z" />
                  <path
                    d="M13 3.207L7.854 8.354h-.708L5.5 6.707l-3.646 3.647l-.708-.708l4-4h.708L7.5 7.293l5.146-5.147h.707l2 2l-.707.708L13 3.207Z"
                  />
                </g>
              </svg>
            </span>
          </div>
        </div>

        <div class="block p-2 w-full">
          <p class="font-semibold text-gray-900 dark:text-gray-200 text-xl">
            {{ higherProductsCount }}
          </p>
          <h2 class="font-normal text-gray-400 text-md mt-1">Higher price</h2>
        </div>
      </div>
    </a>
      <!-- end card -->
      <a href="#lower">
      <div class="card bg-white dark:bg-gray-800 w-full rounded-md p-5 shadow flex">
        <div class="p-2 max-w-sm">
          <div class="bg-green-200 rounded-full w-14 h-14 text-lg p-3 text-green-600 mx-auto">
            <span class="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                role="img"
                width="30px"
                height="30px"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 16 16"
              >
                <g fill="currentColor">
                  <path d="M15 13v1H1.5l-.5-.5V0h1v13h13Z" />
                  <path
                    d="M13 3.207L7.854 8.354h-.708L5.5 6.707l-3.646 3.647l-.708-.708l4-4h.708L7.5 7.293l5.146-5.147h.707l2 2l-.707.708L13 3.207Z"
                  />
                </g>
              </svg>
            </span>
          </div>
        </div>

        <div class="block p-2 w-full">
          <p class="font-semibold text-gray-900 dark:text-gray-200 text-xl">
            {{ lowerProductsCount }}
          </p>
          <h2 class="font-normal text-gray-400 text-md mt-1">Lower price</h2>
        </div>
      </div>
    </a>
      <!-- end card -->
      <a href="#new">
      <div class="card bg-white dark:bg-gray-800 w-full rounded-md p-5 shadow flex">
        <div class="p-2 max-w-sm">
          <div class="bg-yellow-200 rounded-full w-14 h-14 text-lg p-3 text-yellow-600 mx-auto">
            <span class="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                role="img"
                width="30px"
                height="30px"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 16 16"
              >
                <g fill="currentColor">
                  <path d="M15 13v1H1.5l-.5-.5V0h1v13h13Z" />
                  <path
                    d="M13 3.207L7.854 8.354h-.708L5.5 6.707l-3.646 3.647l-.708-.708l4-4h.708L7.5 7.293l5.146-5.147h.707l2 2l-.707.708L13 3.207Z"
                  />
                </g>
              </svg>
            </span>
          </div>
        </div>
        <div class="block p-2 w-full">
          <p class="font-semibold text-gray-900 dark:text-gray-200 text-xl">
            {{ newProductsCount }}
          </p>
          <h2 class="font-normal text-gray-400 text-md mt-1">New</h2>
        </div>
      </div>
      </a>
      <!-- end card -->
      <a href="#gone">
      <div class="card bg-white dark:bg-gray-800 w-full rounded-md p-5 shadow flex">
        <div class="p-2 max-w-sm">
          <div class="bg-red-200 rounded-full w-14 h-14 text-lg p-3 text-red-600 mx-auto">
            <span class="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                role="img"
                width="30px"
                height="30px"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 16 16"
              >
                <g fill="currentColor">
                  <path d="M15 13v1H1.5l-.5-.5V0h1v13h13Z" />
                  <path
                    d="M13 3.207L7.854 8.354h-.708L5.5 6.707l-3.646 3.647l-.708-.708l4-4h.708L7.5 7.293l5.146-5.147h.707l2 2l-.707.708L13 3.207Z"
                  />
                </g>
              </svg>
            </span>
          </div>
        </div>

        <div class="block p-2 w-full">
            <p class="font-semibold text-gray-900 dark:text-gray-200 text-xl">
              {{ goneProductsCount }}
            </p>
            <h2 class="font-normal text-gray-400 text-md mt-1">Discontinued</h2>
        </div>
      </div>
    </a>
      <!-- end card -->
    </div>
    <!-- end wrapper card -->

    <div
      v-if="yearmon == currYear + '' + ('0' + currMon.toString()).slice(-2)"
      class="mt-2 lg:flex block lg:gap-2"
    >
      <div class="mt-2 bg-white dark:bg-gray-800 p-5 w-full rounded-md box-border shadow">
        <h2 class="font-bold text-lg text-gray-800 dark:text-gray-200 float-left">
          Products currently on offer
        </h2>
        <download-csv
          class="float-right"
          :data="offerProducts"
          :name="selectedPathSuffix + '-offer.csv'"
        >
          <Icon icon="iwwa:csv" height="24px" />
        </download-csv>

        <p class="text-gray-400 font-lexend font-normal inline-flex" style="width: inherit">
          This is a list of all products currently on offer
        </p>
        <div class="wrapping-table mt-10" style="overflow: auto">
          <table
            class="w-full text-sm text-left text-gray-500 dark:text-gray-400 lg:overflow-auto overflow-x-scroll"
          >
            <thead
              class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
            >
              <tr>
                <th
                  style="cursor: pointer"
                  scope="col"
                  class="uppercase px-6 py-3"
                  @click="updateSort('name')"
                >
                  <svg
                    v-if="sortBy == 'name' && this.sortAsc"
                    style="float: left"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-sort-up"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"
                    />
                  </svg>

                  <svg
                    v-else-if="sortBy == 'name' && !this.sortAsc"
                    style="float: left"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-sort-down"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"
                    />
                  </svg>
                  Product
                </th>
                <th
                  style="cursor: pointer"
                  scope="col"
                  class="uppercase px-6 py-3"
                  @click="updateSort('brand')"
                >
                  <svg
                    v-if="sortBy == 'brand' && this.sortAsc"
                    style="float: left"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-sort-up"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"
                    />
                  </svg>

                  <svg
                    v-else-if="sortBy == 'brand' && !this.sortAsc"
                    style="float: left"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-sort-down"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"
                    />
                  </svg>

                  Brand
                </th>

                <th
                  style="cursor: pointer"
                  scope="col"
                  class="uppercase px-6 py-3"
                  @click="updateSort('before')"
                >
                  <svg
                    v-if="sortBy == 'before' && this.sortAsc"
                    style="float: left"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-sort-up"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"
                    />
                  </svg>

                  <svg
                    v-else-if="sortBy == 'before' && !this.sortAsc"
                    style="float: left"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-sort-down"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"
                    />
                  </svg>

                  Normal
                </th>

                <th
                  style="cursor: pointer"
                  scope="col"
                  class="uppercase px-6 py-3"
                  @click="updateSort('after')"
                >
                  <svg
                    v-if="sortBy == 'after' && this.sortAsc"
                    style="float: left"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-sort-up"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"
                    />
                  </svg>

                  <svg
                    v-else-if="sortBy == 'after' && !this.sortAsc"
                    style="float: left"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-sort-down"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"
                    />
                  </svg>

                  Offer
                </th>

                <th
                  style="cursor: pointer"
                  scope="col"
                  class="uppercase px-6 py-3"
                  @click="updateSort('chain')"
                >
                  <svg
                    v-if="sortBy == 'chain' && this.sortAsc"
                    style="float: left"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-sort-up"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"
                    />
                  </svg>

                  <svg
                    v-else-if="sortBy == 'chain' && !this.sortAsc"
                    style="float: left"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-sort-down"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"
                    />
                  </svg>
                  Chain
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50"
                v-for="items in offerProducts"
                :key="items.transaction"
              >
                <td class="px-6 py-4">
                  <a
                    target="_blank"
                    :href="
                      'https://beepr.'+country+'/products/' +
                      items.ean +
                      '/' +
                      items.name
                        .toLowerCase()
                        .trim()
                        .replace(/\./g, '')
                        .replace(/ø/g, 'oe')
                        .replace(/æ/g, 'ae')
                        .replace(/å/g, 'aa')
                        .replace(/[^a-z0-9]+/g, '-')
                    "
                    >{{ (items.importName) ? items.importName : items.name }}</a
                  >
                </td>
                <td class="px-6 py-4">
                  {{ items.brand }}
                </td>
                <td class="px-6 py-4">
                  {{
                    Intl.NumberFormat("da-DK", { style: "currency", currency: "DKK" }).format(
                      items.before
                    )
                  }}
                </td>
                <td class="px-6 py-4">
                  {{
                    items.before > items.after || items.quantity < 2
                      ? Intl.NumberFormat("da-DK", { style: "currency", currency: "DKK" }).format(
                          items.after
                        ) + (items.before == items.after ? "**" : "")
                      : items.quantity +
                        " for " +
                        Intl.NumberFormat("da-DK", { style: "currency", currency: "DKK" }).format(
                          items.quantity * items.before - items.quantity * items.savedper
                        )
                  }}
                </td>
                <td class="px-6 py-4">
                  <img
                    :src="require('../assets/img/' + items.chain.toLowerCase() + '.webp')"
                    width="30"
                    alt=""
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <p class="text-gray-400 text-sm mt-4">** the store did not provide the 'Normal' price</p>
        </div>
        <!--         <div class="wrapper-button mt-3">
          <select
            name=""
            id=""
            class="w-1/5 dark:bg-gray-800 dark:hover:bg-gray-700 border-gray-200 dark:border-gray-300 border max-w-lg px-4 py-3 block rounded-md text-gray-500 dark:text-gray-400"
          >
            <option value="">Last month</option>
          </select>
        </div>
 -->
      </div>
    </div>

    <div class="mt-2 lg:flex block lg:gap-2">
      <div id="higher" class="mt-2 bg-white dark:bg-gray-800 p-5 w-full rounded-md box-border shadow">
        <h2 class="font-bold text-lg float-left text-gray-800 dark:text-gray-200">
          Products with higher price
        </h2>
        <download-csv
          class="float-right"
          :data="higherProducts"
          :name="selectedPathSuffix + '-higher.csv'"
        >
          <Icon icon="iwwa:csv" height="24px" />
        </download-csv>

        <p class="text-gray-400 font-lexend font-normal inline-flex" style="width: inherit">
          This is a list of all products with higher price in any chain
        </p>
        <div class="wrapping-table mt-10" style="overflow: auto">
          <table
            class="w-full text-sm text-left text-gray-500 dark:text-gray-400 lg:overflow-auto overflow-x-scroll"
          >
            <thead
              class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
            >
              <tr>
                <th scope="col" class="uppercase px-6 py-3">Product</th>
                <th scope="col" class="uppercase px-6 py-3">Date</th>
                <th scope="col" class="uppercase px-6 py-3">Before</th>
                <th scope="col" class="uppercase px-6 py-3">After</th>
                <th scope="col" class="uppercase px-6 py-3">Chain</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50"
                v-for="items in higherProducts"
                :key="items.transaction"
              >
                <td class="px-6 py-4">
                  <a
                    target="_blank"
                    :href="
                      'https://beepr.'+country+'/products/' +
                      items.ean +
                      '/' +
                      items.name
                        .toLowerCase()
                        .trim()
                        .replace(/\./g, '')
                        .replace(/ø/g, 'oe')
                        .replace(/æ/g, 'ae')
                        .replace(/å/g, 'aa')
                        .replace(/[^a-z0-9]+/g, '-')
                    "
                    >{{ (items.importName) ? items.importName : items.name }}</a
                  >
                </td>
                <td class="px-6 py-4">
                  {{ items.date.split("-")[2] }}-{{ items.date.split("-")[1] }}-{{
                    items.date.split("-")[0]
                  }}
                </td>
                <td class="px-6 py-4">
                  {{
                    Intl.NumberFormat("da-DK", { style: "currency", currency: "DKK" }).format(
                      items.before
                    )
                  }}
                </td>
                <td class="px-6 py-4">
                  {{
                    Intl.NumberFormat("da-DK", { style: "currency", currency: "DKK" }).format(
                      items.after
                    )
                  }}
                </td>
                <td class="px-6 py-4">
                  <img
                    :src="require('../assets/img/' + items.chain.toLowerCase() + '.webp')"
                    width="30"
                    alt=""
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div id="lower" class="mt-2 bg-white dark:bg-gray-800 p-5 w-full rounded-md box-border shadow">
        <h2 class="font-bold text-lg text-gray-800 dark:text-gray-200 float-left">
          Products with lower price
        </h2>
        <download-csv
          class="float-right"
          :data="lowerProducts"
          :name="selectedPathSuffix + '-lower.csv'"
        >
          <Icon icon="iwwa:csv" height="24px" />
        </download-csv>

        <p class="text-gray-400 font-lexend font-normal inline-flex" style="width: inherit">
          This is a list of all products with lower normalprice in any chain
        </p>
        <div class="wrapping-table mt-10" style="overflow: auto">
          <table
            class="w-full text-sm text-left text-gray-500 dark:text-gray-400 lg:overflow-auto overflow-x-scroll"
          >
            <thead
              class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
            >
              <tr>
                <th scope="col" class="uppercase px-6 py-3">Product</th>
                <th scope="col" class="uppercase px-6 py-3">Date</th>
                <th scope="col" class="uppercase px-6 py-3">Before</th>
                <th scope="col" class="uppercase px-6 py-3">After</th>
                <th scope="col" class="uppercase px-6 py-3">Chain</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50"
                v-for="items in lowerProducts"
                :key="items.transaction"
              >
                <td class="px-6 py-4">
                  <a
                    target="_blank"
                    :href="
                      'https://beepr.'+country+'/products/' +
                      items.ean +
                      '/' +
                      items.name
                        .toLowerCase()
                        .trim()
                        .replace(/\./g, '')
                        .replace(/ø/g, 'oe')
                        .replace(/æ/g, 'ae')
                        .replace(/å/g, 'aa')
                        .replace(/[^a-z0-9]+/g, '-')
                    "
                    >{{ (items.importName) ? items.importName : items.name }}</a
                  >
                </td>
                <td class="px-6 py-4">
                  {{ items.date.split("-")[2] }}-{{ items.date.split("-")[1] }}-{{
                    items.date.split("-")[0]
                  }}
                </td>
                <td class="px-6 py-4">
                  {{
                    Intl.NumberFormat("da-DK", { style: "currency", currency: "DKK" }).format(
                      items.before
                    )
                  }}
                </td>
                <td class="px-6 py-4">
                  {{
                    Intl.NumberFormat("da-DK", { style: "currency", currency: "DKK" }).format(
                      items.after
                    )
                  }}
                </td>
                <td class="px-6 py-4">
                  <img
                    :src="require('../assets/img/' + items.chain.toLowerCase() + '.webp')"
                    width="30"
                    alt=""
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="mt-2 lg:flex block lg:gap-2">
      <div id="new" class="mt-2 bg-white dark:bg-gray-800 p-5 w-full rounded-md box-border shadow">
        <h2 class="font-bold text-lg text-gray-800 dark:text-gray-200 float-left">New products</h2>
        <download-csv
          class="float-right"
          :data="newProducts"
          :name="selectedPathSuffix + '-new.csv'"
        >
          <Icon icon="iwwa:csv" height="24px" />
        </download-csv>
        <p class="text-gray-400 font-lexend font-normal inline-flex" style="width: inherit">
          This is a list of all new products in any chain
        </p>
        <div class="wrapping-table mt-10" style="overflow: auto">
          <table
            class="w-full text-sm text-left text-gray-500 dark:text-gray-400 lg:overflow-auto overflow-x-scroll"
          >
            <thead
              class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
            >
              <tr>
                <th scope="col" class="uppercase px-6 py-3">Product</th>
                <th scope="col" class="uppercase px-6 py-3">Date</th>
                <!--                 <th scope="col" class="uppercase px-6 py-3">Before</th>
 -->
                <th scope="col" class="uppercase px-6 py-3">Price</th>
                <th scope="col" class="uppercase px-6 py-3">Chain</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50"
                v-for="items in newProducts"
                :key="items.transaction"
              >
                <td class="px-6 py-4">
                  <a
                    target="_blank"
                    :href="
                      'https://beepr.'+country+'/products/' +
                      items.ean +
                      '/' +
                      items.name
                        .toLowerCase()
                        .trim()
                        .replace(/\./g, '')
                        .replace(/ø/g, 'oe')
                        .replace(/æ/g, 'ae')
                        .replace(/å/g, 'aa')
                        .replace(/[^a-z0-9]+/g, '-')
                    "
                    >{{ (items.importName) ? items.importName : items.name }}</a
                  >
                </td>
                <td class="px-6 py-4">
                  {{ items.date.split("-")[2] }}-{{ items.date.split("-")[1] }}-{{
                    items.date.split("-")[0]
                  }}
                </td>
                <!--                 <td class="px-6 py-4">
                  {{
                    Intl.NumberFormat("da-DK", { style: "currency", currency: "DKK" }).format(
                      items.before
                    )
                  }}
                </td>
 -->
                <td class="px-6 py-4">
                  {{
                    Intl.NumberFormat("da-DK", { style: "currency", currency: "DKK" }).format(
                      items.after
                    )
                  }}
                </td>
                <td class="px-6 py-4">
                  <img
                    :src="require('../assets/img/' + items.chain.toLowerCase() + '.webp')"
                    width="30"
                    alt=""
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div id="gone" class="mt-2 bg-white dark:bg-gray-800 p-5 w-full rounded-md box-border shadow">
        <h2 class="font-bold text-lg text-gray-800 dark:text-gray-200 float-left">
          Discontinued products
        </h2>
        <download-csv
          class="float-right"
          :data="goneProducts"
          :name="selectedPathSuffix + '-gone.csv'"
        >
          <Icon icon="iwwa:csv" height="24px" />
        </download-csv>
        <p class="text-gray-400 font-lexend font-normal inline-flex" style="width: inherit">
          This is a list of all discontinued products in any chain
        </p>
        <div class="wrapping-table mt-10" style="overflow: auto">
          <table
            class="w-full text-sm text-left text-gray-500 dark:text-gray-400 lg:overflow-auto overflow-x-scroll"
          >
            <thead
              class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
            >
              <tr>
                <th scope="col" class="uppercase px-6 py-3">Product</th>
                <th scope="col" class="uppercase px-6 py-3">Date</th>
                <!--                 <th scope="col" class="uppercase px-6 py-3">Before</th>
                <th scope="col" class="uppercase px-6 py-3">After</th>
 -->
                <th scope="col" class="uppercase px-6 py-3">Chain</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50"
                v-for="items in goneProducts"
                :key="items.transaction"
              >
                <td class="px-6 py-4">
                  <a
                    target="_blank"
                    :href="
                      'https://beepr.'+country+'/products/' +
                      items.ean +
                      '/' +
                      items.name
                        .toLowerCase()
                        .trim()
                        .replace(/\./g, '')
                        .replace(/ø/g, 'oe')
                        .replace(/æ/g, 'ae')
                        .replace(/å/g, 'aa')
                        .replace(/[^a-z0-9]+/g, '-')
                    "
                    >{{ (items.importName) ? items.importName : items.name }}</a
                  >
                </td>
                <td class="px-6 py-4">
                  {{ items.date.split("-")[2] }}-{{ items.date.split("-")[1] }}-{{
                    items.date.split("-")[0]
                  }}
                </td>
                <!--                 <td class="px-6 py-4">
                  {{
                    Intl.NumberFormat("da-DK", { style: "currency", currency: "DKK" }).format(
                      items.before
                    )
                  }}
                </td>
                <td class="px-6 py-4">
                  {{
                    Intl.NumberFormat("da-DK", { style: "currency", currency: "DKK" }).format(
                      items.after
                    )
                  }}
                </td>
 -->
                <td class="px-6 py-4">
                  {{ items.chain }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    </div>

    <a href="#top" class="btn">
    <div class="grid place-content-center mt-4">
      <button class="btn">Go to top</button>
    </div>
    </a>
  </div>
</template>

<script>
// @ is an alias to /src
import { Icon } from "@iconify/vue";
import { mapGetters } from "vuex";
import BaseProgress from "./components/BaseProgress.vue";

export default {
  name: "Report",
  data() {
    return {
      sortBy: "name",
      sortAsc: true,
      // for more guide apexchart.js
      // https://apexcharts.com/docs/chart-types/line-chart/
    };
  },
  components: {
    Icon,
    BaseProgress
  },
  methods: {
    updateSort(newSort) {
      if (newSort == this.sortBy) {
        this.sortAsc = !this.sortAsc;
      } else {
        this.sortBy = newSort;
        this.sortAsc = true;
      }
    },

    toggleView() {
      this.$router.push({ name: "clienteans" });
    },
  },
  computed: {
    ...mapGetters({
      country: "country",
      beepruser: "beepruser",
      client: "client",
      clientData: "clientData",
      offer: "offer",
      lower: "lower",
      higher: "higher",
      gone: "gone",
      new: "new",
      currYear: "currYear",
      currMon: "currMon",
      selectedPathSuffix: "selectedPathSuffix",
      loading: "loading"
    }),

    selectedPath: {
      get() {
        return this.$store.state.beeprStore.selectedPath;
      },
      set(value) {
        this.$store.commit("SET_LOADING", true);
        this.$store.dispatch("setSelectedPath", value);
        //this.$store.dispatch("setData");
        this.$store.dispatch("setData", "offer");
        this.$store.dispatch("setData", "lower");
        this.$store.dispatch("setData", "higher");
        this.$store.dispatch("setData", "gone");
        this.$store.dispatch("setData", "new");
      },
    },

    yearmon: {
      get() {
        return this.$store.state.beeprStore.yearmon;
      },
      set(value) {
        this.$store.dispatch("setYearmon", value);
        this.$store.dispatch("setData", "offer");
        this.$store.dispatch("setData", "lower");
        this.$store.dispatch("setData", "higher");
        this.$store.dispatch("setData", "gone");
        this.$store.dispatch("setData", "new");
      },
    },

    offerProducts() {
      if (this.sortBy == "name" || this.sortBy == "brand" || this.sortBy == "chain") {
        if (this.sortAsc) {
          return this.offer[this.selectedPathSuffix + "-offer"]
            ? this.offer[this.selectedPathSuffix + "-offer"].sort((a, b) =>
                a[this.sortBy].localeCompare(b[this.sortBy], "da")
              )
            : [];
        } else {
          return this.offer[this.selectedPathSuffix + "-offer"]
            ? this.offer[this.selectedPathSuffix + "-offer"].sort((a, b) =>
                b[this.sortBy].localeCompare(a[this.sortBy], "da")
              )
            : [];
        }
      } else {
        if (this.sortAsc) {
          return this.offer[this.selectedPathSuffix + "-offer"]
            ? this.offer[this.selectedPathSuffix + "-offer"].sort(
                (a, b) => a[this.sortBy] - b[this.sortBy]
              )
            : [];
        } else {
          return this.offer[this.selectedPathSuffix + "-offer"]
            ? this.offer[this.selectedPathSuffix + "-offer"].sort(
                (a, b) => b[this.sortBy] - a[this.sortBy]
              )
            : [];
        }
      }
    },

    offerProductsCount() {
      return this.offer[this.selectedPathSuffix + "-offer"]
        ? this.offer[this.selectedPathSuffix + "-offer"].length
        : 0;
    },

    lowerProducts() {
      if (this.sortBy == "name" || this.sortBy == "brand" || this.sortBy == "chain") {
        if (this.sortAsc) {
          return this.lower[this.selectedPathSuffix + "-lower"]
            ? this.lower[this.selectedPathSuffix + "-lower"].sort((a, b) =>
                a[this.sortBy].localeCompare(b[this.sortBy], "da")
              )
            : [];
        } else {
          return this.lower[this.selectedPathSuffix + "-lower"]
            ? this.lower[this.selectedPathSuffix + "-lower"].sort((a, b) =>
                b[this.sortBy].localeCompare(a[this.sortBy], "da")
              )
            : [];
        }
      } else {
        if (this.sortAsc) {
          return this.lower[this.selectedPathSuffix + "-lower"]
            ? this.lower[this.selectedPathSuffix + "-lower"].sort(
                (a, b) => a[this.sortBy] - b[this.sortBy]
              )
            : [];
        } else {
          return this.lower[this.selectedPathSuffix + "-lower"]
            ? this.lower[this.selectedPathSuffix + "-lower"].sort(
                (a, b) => b[this.sortBy] - a[this.sortBy]
              )
            : [];
        }
      }
    },

    lowerProductsCount() {
      return this.lower[this.selectedPathSuffix + "-lower"]
        ? this.lower[this.selectedPathSuffix + "-lower"].length
        : 0;
    },

    higherProducts() {
      if (this.sortBy == "name" || this.sortBy == "brand" || this.sortBy == "chain") {
        if (this.sortAsc) {
          return this.higher[this.selectedPathSuffix + "-higher"]
            ? this.higher[this.selectedPathSuffix + "-higher"].sort((a, b) =>
                a[this.sortBy].localeCompare(b[this.sortBy], "da")
              )
            : [];
        } else {
          return this.higher[this.selectedPathSuffix + "-higher"]
            ? this.higher[this.selectedPathSuffix + "-higher"].sort((a, b) =>
                b[this.sortBy].localeCompare(a[this.sortBy], "da")
              )
            : [];
        }
      } else {
        if (this.sortAsc) {
          return this.higher[this.selectedPathSuffix + "-higher"]
            ? this.higher[this.selectedPathSuffix + "-higher"].sort(
                (a, b) => a[this.sortBy] - b[this.sortBy]
              )
            : [];
        } else {
          return this.higher[this.selectedPathSuffix + "-higher"]
            ? this.higher[this.selectedPathSuffix + "-higher"].sort(
                (a, b) => b[this.sortBy] - a[this.sortBy]
              )
            : [];
        }
      }
    },

    higherProductsCount() {
      return this.higher[this.selectedPathSuffix + "-higher"]
        ? this.higher[this.selectedPathSuffix + "-higher"].length
        : 0;
    },

    goneProducts() {
      if (this.sortBy == "name" || this.sortBy == "brand" || this.sortBy == "chain") {
        if (this.sortAsc) {
          return this.gone[this.selectedPathSuffix + "-gone"]
            ? this.gone[this.selectedPathSuffix + "-gone"].sort((a, b) =>
                a[this.sortBy].localeCompare(b[this.sortBy], "da")
              )
            : [];
        } else {
          return this.gone[this.selectedPathSuffix + "-gone"]
            ? this.gone[this.selectedPathSuffix + "-gone"].sort((a, b) =>
                b[this.sortBy].localeCompare(a[this.sortBy], "da")
              )
            : [];
        }
      } else {
        if (this.sortAsc) {
          return this.gone[this.selectedPathSuffix + "-gone"]
            ? this.gone[this.selectedPathSuffix + "-gone"].sort(
                (a, b) => a[this.sortBy] - b[this.sortBy]
              )
            : [];
        } else {
          return this.gone[this.selectedPathSuffix + "-gone"]
            ? this.gone[this.selectedPathSuffix + "-gone"].sort(
                (a, b) => b[this.sortBy] - a[this.sortBy]
              )
            : [];
        }
      }
    },

    goneProductsCount() {
      return this.gone[this.selectedPathSuffix + "-gone"]
        ? this.gone[this.selectedPathSuffix + "-gone"].length
        : 0;
    },

    newProducts() {
      if (this.sortBy == "name" || this.sortBy == "brand" || this.sortBy == "chain") {
        if (this.sortAsc) {
          return this.new[this.selectedPathSuffix + "-new"]
            ? this.new[this.selectedPathSuffix + "-new"].sort((a, b) =>
                a[this.sortBy].localeCompare(b[this.sortBy], "da")
              )
            : [];
        } else {
          return this.new[this.selectedPathSuffix + "-new"]
            ? this.new[this.selectedPathSuffix + "-new"].sort((a, b) =>
                b[this.sortBy].localeCompare(a[this.sortBy], "da")
              )
            : [];
        }
      } else {
        if (this.sortAsc) {
          return this.new[this.selectedPathSuffix + "-new"]
            ? this.new[this.selectedPathSuffix + "-new"].sort(
                (a, b) => a[this.sortBy] - b[this.sortBy]
              )
            : [];
        } else {
          return this.new[this.selectedPathSuffix + "-new"]
            ? this.new[this.selectedPathSuffix + "-new"].sort(
                (a, b) => b[this.sortBy] - a[this.sortBy]
              )
            : [];
        }
      }
    },

    newProductsCount() {
      return this.new[this.selectedPathSuffix + "-new"]
        ? this.new[this.selectedPathSuffix + "-new"].length
        : 0;
    },
  },
  mounted() {
    this.$store.commit("SET_LOADING", true);
        //this.$store.dispatch("setSelectedPath", value);
        //this.$store.dispatch("setData");
        this.$store.dispatch("setData", "offer");
        this.$store.dispatch("setData", "lower");
        this.$store.dispatch("setData", "higher");
        this.$store.dispatch("setData", "gone");
        this.$store.dispatch("setData", "new");

  },
};
</script>

<style>
#gone, #new, #higher, #lower {
  scroll-margin-top: 100px;
}
</style>
