import { createRouter, createWebHistory } from "vue-router";

//import Home from "../views/Home.vue";
import Client from "../views/Client.vue";
import ClientEans from "../views/ClientEans.vue";
import Brochure from "../views/Brochure.vue";
import Prices from "../views/Prices.vue";
import PricesExt from "../views/PricesExt.vue";
import History from "../views/History.vue";
import ShortHistory from "../views/ShortHistory.vue";
//import FullReport from "../views/FullReport.vue";
import MonthReport from "../views/MonthReport.vue";
import ChainDetails from "../views/ChainDetails.vue";
import EanDetails from "../views/EanDetails.vue";
import Report from "../views/Report.vue";
import Competition from "../views/Competition.vue";
import Segments from "../views/Segments.vue";
import { analytics } from "@/firebaseDb";
//import Product from "../views/Product.vue";

var appname = "Beepr BI";

const routes = [
  // Routes
/*   {
    path: "/",
    name: "home",
    component: Home,
    meta: { title: appname },
  },
 */{
    path: "/",
    name: "home",
    component: ClientEans,
    meta: { title: "Home" },
  },
  {
    path: "/brochure",
    name: "brochure",
    component: Brochure,
    meta: { title: "Brochure" },
  },
/*   {
    path: "/prices",
    name: "prices",
    component: Prices,
    meta: { title: "Prices" },
  },
 */
  {
    path: "/prices",
    name: "prices",
    component: PricesExt,
    meta: { title: "Prices" },
  },
  {
    path: "/history",
    name: "history",
    component: History,
    meta: { title: "History" },
  },
  {
    path: "/recenthistory",
    name: "recenthistory",
    component: ShortHistory,
    meta: { title: "RecentHistory" },
  },
  {
    path: "/report",
    name: "report",
    component: Report,
    meta: { title: "Report" },
  },
/*   {
    path: "/product",
    name: "product",
    component: Product,
    meta: { title: "Product " + appname },
  },
 */  {
    path: "/chaindetails",
    name: "chaindetails",
    component: ChainDetails,
    meta: { title: "Chain Details" },
  },
  {
    path: "/offers",
    name: "offers",
    component: Client,
    meta: { title: "Summary" },
  },
  {
    path: "/competition",
    name: "competition",
    component: Competition,
    meta: { title: "Competition" },
  },
  {
    path: "/segments",
    name: "segments",
    component: Segments,
    meta: { title: "Segments" },
  },
  {
    path: "/eandetails",
    name: "eandetails",
    component: EanDetails,
    meta: { title: "EAN Details" },
  },
/*   {
    path: "/fullreport",
    name: "fullreport",
    component: FullReport,
    meta: { title: "Report " + appname },
  },
 */  {
    path: "/monthreport",
    name: "monthreport",
    component: MonthReport,
    meta: { title: "Beepr Report" },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,

  linkExactActiveClass: "exact-active",
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  analytics.logEvent("screen_view", {
    screen_name: to.meta.title,
  });

  next();
});

export default router;
