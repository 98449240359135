<template>
  <div
    v-show="isOpen"
    style="min-height: fit-content"
    class="fixed z-30 inset-0 overflow-y-auto bg-black bg-opacity-50"
  >
    <div class="flex items-start justify-center min-h-screen pt-24 text-center w-full">
      <div class="bg-white rounded-lg text-left overflow-hidden shadow-xl p-8 w-5/6">
        <h2 v-if="monthsback" class="float-left font-bold text-lg text-gray-800 dark:text-gray-200">
          Size vs Avg Price per Unit over the past {{ monthsback }} months
        </h2>

        <button
          type="button"
          @click="closemodal()"
          class="float-right text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm mb-3"
        >
          <svg
            class="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>

        <div class="clear-both">
          <div class="bg-gray-200 p-5 w-full rounded-md box-border shadow">
            <p class="text-gray-400 font-lexend font-normal">
              {{ name }}
            </p>
            <br />

            <div
              v-if="!series || series.length == 0"
              class="flex flex-wrap p-4"
              style="height: 480px"
            >
              <base-progress
                :percentage="contentProgress"
                class="mx-2 mb-2"
                indeterminate
              ></base-progress>
            </div>

            <apexchart
              type="scatter"
              height="450"
              :key="series.length"
              :options="chartOptions"
              :series="series"
            ></apexchart>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--</section>-->
</template>

<script>
// @ is an alias to /src
import { Icon } from "@iconify/vue";
import BaseProgress from "./BaseProgress.vue";

//import { mapGetters } from "vuex";

export default {
  name: "ScatterBrandModal",
  props: {
    isOpen: Boolean,
    //labels: Array,
    series: Array,
    name: String,
    monthsback: Number,
  },
  mounted() {},

  watch: {
    series: function (newVal, oldVal) {
      // watch it
      //console.log(oldVal)
      //console.log(newVal)

      let tmp2 = newVal.map((obj) => obj.data);
      //console.log(tmp2)
      let tmp3 = tmp2
        .flat()
        .map((obj) => obj.x)
        .sort((a, b) => a - b);
      //console.log(tmp3);

      this.chartOptions = {
        ...this.chartOptions,
        ...{
          series: newVal,
          xaxis: {
            //min: tmp3[0]-10,
            //max: tmp3[tmp3.length-1]+10,
            show: true,
            labels: {
              formatter: function (val) {
                return val && typeof val == "number" ? val.toFixed(2) + " kr." : val + " kr.";
              },
            },
          },
        },
      };
    },
  },

  data() {
    return {
      contentProgress: 20,

      chartOptions: {
        chart: {
          type: "scatter",
          animations: {
            enabled: false,
          },
          zoom: {
            enabled: false,
          },
        },
        tooltip: {
          custom: ({ series, seriesIndex, dataPointIndex, w }) => {
            const hoverXaxis = w.globals.seriesX[seriesIndex][dataPointIndex];
            const hoverIndexes = w.globals.seriesX.map((seriesX) => {
              return seriesX.findIndex((xData) => xData === hoverXaxis);
            });

            var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
            //console.log(data);
            let hoverList = "";
            hoverIndexes.forEach((hoverIndex, seriesEachIndex) => {
              if (hoverIndex >= 0 && series[seriesEachIndex][hoverIndex] > 0) {
                hoverList += `<span>${w.globals.seriesNames[seriesEachIndex]}: ${Intl.NumberFormat(
                  "da-DK",
                  {}
                ).format(series[seriesEachIndex][hoverIndex].toFixed(2))} ${data.uom}</span><br />`;
              }
            });
            //console.log(w);
            //console.log(series);
            //console.log(w.globals.series);
            //console.log(w.globals.seriesX);
            //console.log(hoverIndexes);
            //console.log(hoverXaxis);

            const formatHoverX = Intl.NumberFormat("da-DK", {}).format(hoverXaxis.toFixed(2));

            return `<div class="card">
        <div class="card-header p-1">Brand: ${data.brand}</div>
        <div class="card-header p-1">Name: ${data.name}</div>
        <div class="card-header p-1">Chain: ${
          data.chain[0].toUpperCase() + data.chain.slice(1)
        }</div>
        <div class="card-header p-1">Price: ${Intl.NumberFormat("da-DK", {}).format(
          data.price.toFixed(2)
        )}</div>
        <div class="card-header p-1">PPU: ${Intl.NumberFormat("da-DK", {}).format(
          data.x.toFixed(2)
        )}</div>
        <div class="card-body p-1">
          ${hoverList}
        </div>
      </div>`;
          },
        },
        colors: [
          "#800000",
          "#3CB44B",
          "#000075",
          "#F032E6",
          "#E6194B",
          "#F58231",
          "#42D4F4",
          "#4363D8",
          "#911EB4",
          "#FABED4",
          "#FFD8B1",
          "#469990",
          "#AAFFC3",
          "#000000",
          "#DCBEFF",
          "#9A6324",
          "#808000",
          "#FFFAC8",
        ],
        //colors: ["#056BF6", "#D2376A"],
        xaxis: {
          show: true,
          labels: {
            formatter: function (val) {
              return val && typeof val == "number" ? val.toFixed(2) + " kr." : val + " kr.";
            },
          },
        },
        yaxis: {
          show: true,
          labels: {
            formatter: function (val) {
              return val && typeof val == "number" ? val.toFixed(2) + " units" : val;
            },
          },
        },
        markers: {
          size: 15,
        },
        /*         fill: {
          type: "image",
          opacity: 0.7,
          image: {
            src: [require("../../assets/img/bilka.png"), require("../../assets/img/coop.png"), require("../../assets/img/føtex.png"), require("../../assets/img/minkobmand.png"), require("../../assets/img/nemlig.png"), require("../../assets/img/netto.png"), require("../../assets/img/rema.png")],
            width: 30,
            height: 30,
          },
        },
 */ legend: {
          labels: {
            useSeriesColors: true,
          },
          markers: {
            customHTML: [
              function () {
                return "";
              },
              function () {
                return "";
              },
            ],
          },
        },
      },
    };
    // end chart data line
  },
  components: {
    Icon,
    BaseProgress,
  },
  methods: {
    closemodal() {
      this.$emit("closemodal", false);
    },
  },
};
</script>
