<template>
  <div
    v-show="ean && isOpen[ean]" style="min-height: fit-content;" 
    class="fixed z-30 inset-0 overflow-y-auto bg-black bg-opacity-50"
  >
  <div class="flex items-start justify-center min-h-screen pt-24 text-center w-full">

    <div class="bg-white rounded-lg text-left overflow-hidden shadow-xl p-8 w-5/6">

    <h2 class="float-left font-bold text-lg text-gray-800 dark:text-gray-200">Price Development</h2>

    <button
      type="button"
      @click="closemodal()"
      class="float-right text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm mb-3"
    >
      <svg
        class="w-5 h-5"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
          clip-rule="evenodd"
        ></path>
      </svg>
    </button>

    <div class="clear-both">
      <div class="bg-gray-200 p-5 w-full rounded-md box-border shadow">
        <p class="text-gray-400 font-lexend font-normal">
          {{ name }}
        </p>
        <br />
        
        <div v-if="!series || series.length == 0" class="flex flex-wrap p-4" style="height: 380px">
            <base-progress :percentage="contentProgress" class="mx-2 mb-2" indeterminate></base-progress>
        </div>
        
        <apexchart
          v-if="series && series.length>0"
          width="100%"
          height="380"
          type="line"
          :options="optionsArea"
          :series="series"
          :labels="labels"
          :sparkline="{
            enabled: true,
          }"
        ></apexchart>
      </div>
    </div>
    </div>
  </div>
  </div>
  <!--</section>-->
</template>

<script>
// @ is an alias to /src
import { Icon } from "@iconify/vue";
import BaseProgress from "./BaseProgress.vue";

//import { mapGetters } from "vuex";

export default {
  name: "ChartModal",
  props: {
    isOpen: Object,
    labels: Array,
    series: Array,
    ean: String,
    name: String,
  },
  mounted() {},

  watch: {
    series: function (newVal, oldVal) {
      // watch it
      //console.log("Prop changed: ", newVal, " | was: ", oldVal);
    },
    labels: function (newVal, oldVal) {
      // watch it
      //console.log('Prop changed: ', newVal, ' | was: ', oldVal)
      this.optionsArea = {
        ...this.optionsArea,
        ...{
          xaxis: {
            categories: newVal,
            labels: {
                hideOverlappingLabels: true,
              formatter: function (val) {
                return val
                },
            },
          },
        },
      };
    },
  },
  data() {
    return {
        contentProgress: 20,
      // for more guide apexchart.js
      // https://apexcharts.com/docs/chart-types/line-chart/

      // chart data area
      optionsArea: {
        /*               xaxis: {
                    categories: this.labels,
                     labels: {
                        formatter: function(val) {
                            return (val && val.length == 6) ? val[4]+val[5]+'/202'+val[3] : (val && val.length == 10) ? val[8]+val[9] : val
                        }
                    }
                 },
                 */
        colors: ['#800000', '#3CB44B', '#000075', '#F032E6', '#E6194B', '#F58231', '#A9A9A9', '#FFE119', '#BFEF45', '#42D4F4', '#4363D8', '#911EB4', '#FABED4', '#FFD8B1', '#469990', '#AAFFC3', '#000000', '#DCBEFF', '#9A6324', '#808000', '#FFFAC8'],
        fontFamily: "Segoe UI, sans-serif",
        stroke: {
          curve: "smooth",
        },
        markers: {
          size: 0,
        },
        yaxis: {
          show: true,
          labels: {
            formatter: function (val) {
              return (val && typeof(val) == 'number') ? val.toFixed(2) + " kr." : val;
            },
          },
        },
        /*                 fill: {
                    type: "gradient",
                    gradient: {
                        shadeIntensity: 1,
                        opacityFrom: 0.7,
                        opacityTo: 0.9,
                        stops: [0, 90, 100],
                    },
                },
 */
      },

      chart: {
        fontFamily: "lexend, sans-serif",
      },
    };
    // end chart data line
  },
  components: {
    Icon,
    BaseProgress
  },
  methods: {
    closemodal() {
      this.$emit("closemodal", false);
    },
  },
};
</script>
