<template>
  <div class="bg-white p-5 w-full rounded-md box-border shadow">
    <div v-if="!series || series.length == 0" class="flex flex-wrap p-4" style="height: 380px">
      <base-progress :percentage="contentProgress" class="mx-2 mb-2" indeterminate></base-progress>
    </div>

    <apexchart
      v-if="series && series.length > 0"
      width="100%"
      height="380"
      type="line"
      :options="optionsArea"
      :series="series"
      :sparkline="{
        enabled: true,
      }"
    ></apexchart>
  </div>
</template>

<script>
// @ is an alias to /src
import { Icon } from "@iconify/vue";
import BaseProgress from "./BaseProgress.vue";

//import { mapGetters } from "vuex";

export default {
  name: "ChartModal",
  props: {
    isOpen: Object,
    labels: Array,
    series: Array,
    ean: String,
    name: String,
    ticks: Number,
  },
  mounted() {},

  watch: {
    series: function (newVal, oldVal) {
      // watch it
      //console.log("Prop changed: ", newVal, " | was: ", oldVal);
    },
    labels: function (newVal, oldVal) {
      // watch it
      //console.log('Prop changed: ', newVal, ' | was: ', oldVal)
/*        this.optionsArea = {
        ...this.optionsArea,
        ...{
          xaxis: {
            type: "datetime",
            //tickPlacement: 'on',
            //tickAmount: 25,
            //categories: newVal,
            show: true,
          labels: {
            hideOverlappingLabels: true,
            formatter: function (value) {
              return (
              new Date(value).getDate() + "/" + (new Date(value).getMonth() + 1) + "/" + new Date(value).getUTCFullYear()
              );
            }
          }
          },
        },
      };
 */    },
  },
  data() {
    return {
      contentProgress: 20,

      // chart data area
      optionsArea: {
        colors: [
          "#800000",
          "#3CB44B",
          "#000075",
          "#F032E6",
          "#E6194B",
          "#F58231",
          "#A9A9A9",
          "#FFE119",
          "#BFEF45",
          "#42D4F4",
          "#4363D8",
          "#911EB4",
          "#FABED4",
          "#FFD8B1",
          "#469990",
          "#AAFFC3",
          "#000000",
          "#DCBEFF",
          "#9A6324",
          "#808000",
          "#FFFAC8",
        ],
        fontFamily: "quicksand, sans-serif",
        stroke: {
          curve: "smooth",
        },
        markers: {
          size: 0,
        },

        xaxis: {
          type: "datetime", // <--- HERE
          tickAmount: this.ticks,
          //tickPlacement: 'on',  
          show: true,
          labels: {
            hideOverlappingLabels: true,
            formatter: function (value) {
              return (
              new Date(value).getDate() + "/" + (new Date(value).getMonth() + 1) + "/" + new Date(value).getUTCFullYear()
              );
            }
          }
        },
        dataLabels: {
          enabled: false
        },
  /*         xaxis: {
            type: "datetime",
            tickPlacement: 'on',
            tickAmount: 25,
            //categories: this.labels,
            labels: {
              hideOverlappingLabels: true,
              formatter: function (val) {
                return val;
              },
            },
          },
   */      yaxis: {
          tickAmount: 10,
          show: true,
          labels: {
            hideOverlappingLabels: true,
            formatter: function (val) {
              return val && typeof val == "number" ? val.toFixed(2) + " kr." : val;
            },
          },
        },
      
      chart: {
        fontFamily: "quicksand, sans-serif",
        toolbar: {
          export: {
            csv: {
              columnDelimiter: ';',
            },
          },
          autoSelected: 'zoom' 
        },

      },
    },
    };
    // end chart data line
  },
/*   methods: {
      onChartResize(event) {
        this.$refs.oilProductionProfile.updateOptions({
        xaxis: { tickAmount: Math.ceil(event.srcElement.innerWidth / 140) },
      });
    },
  },
  created() {
    window.addEventListener("resize", this.onChartResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onChartResize);
  },
 */  components: {
    Icon,
    BaseProgress,
  },
};
</script>
