import { db } from "../../firebaseDb";
import {
  doc,
  getDoc,
  setDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
//import * as Vue from "vue";

const state = {
  beepruser: {
    loggedIn: false,
    data: null,
    uid: "",
  },
};
const getters = {
  beepruser(state) {
    return state.beepruser;
  },
};
const actions = {
  fetchUser({ commit }, user) {
    commit("SET_LOGGED_IN", user !== null);
    return new Promise((resolve, reject) => {
      try {
        if (user) {
          commit("SET_UID", user.uid);

          const docRef = doc(db, "users", user.uid);
          getDoc(docRef).then((docSnap) => {
            if (docSnap.exists()) {
              const beepruser = docSnap.data();
              beepruser.uid = user.uid;
              commit("SET_CLIENT", beepruser.client);
              commit("SET_USER", beepruser);
              resolve(beepruser);
            } else {
              console.log("No such user in fetch user!");
              resolve({});
            }
          });
        } else {
          resolve({});
        }
      } catch (error) {
        //console.log(error);
        commit("SET_USER", null);
        resolve({});
      }
    });
  },

  toggleFav({ commit }, payload) {

    const datadocRef = doc(db, payload.yearmon + "/" + payload.client + "/favs/" + payload.uid);

    const docRef = doc(db, "users", payload.uid);
    if (payload.added) {
      setDoc(datadocRef, { [payload.uid]: arrayUnion(payload.product) }, { merge: true});
      updateDoc(docRef, { favs: arrayUnion(payload.value) });
      commit("TOGGLE_FAV", payload);
    } else {
      updateDoc(datadocRef, { [payload.uid]: arrayRemove(payload.product) });
      updateDoc(docRef, { favs: arrayRemove(payload.value) });
      commit("TOGGLE_FAV", payload);
    }
  },

  toggleSubscribe({ commit }, payload) {
    const docRef = doc(db, "users", payload.uid);
    if (payload.added) {
      updateDoc(docRef, { subs: arrayUnion(payload.value) });
      commit("TOGGLE_SUBSCRIBE", payload);
    } else {
      updateDoc(docRef, { subs: arrayRemove(payload.value) });
      commit("TOGGLE_SUBSCRIBE", payload);
    }
  },

};
const mutations = {
  SET_UID(state, value) {
    state.beepruser.uid = value;
  },
  SET_LOGGED_IN(state, value) {
    state.beepruser.loggedIn = value;
  },
  SET_USER(state, data) {
    state.beepruser.data = data;
  },
  TOGGLE_FAV(state, payload) {
    if (payload.added) {
      if (state.beepruser.data.favs) {
        state.beepruser.data.favs.push(payload.value);
      } else {
        //Vue.set(state.beepruser.data, "subs", []);
        state.beepruser.data["favs"] = [];
        state.beepruser.data.favs.push(payload.value);
      }
    } else {
      let index = state.beepruser.data.favs.indexOf(payload.value);
      if (index > -1) {
        state.beepruser.data.favs.splice(index, 1);
      }
    }
  },
  TOGGLE_SUBSCRIBE(state, payload) {
    if (payload.added) {
      if (state.beepruser.data.subs) {
        state.beepruser.data.subs.push(payload.value);
      } else {
        //Vue.set(state.beepruser.data, "subs", []);
        state.beepruser.data["subs"] = [];
        state.beepruser.data.subs.push(payload.value);
      }
    } else {
      let index = state.beepruser.data.subs.indexOf(payload.value);
      if (index > -1) {
        state.beepruser.data.subs.splice(index, 1);
      }
    }
  },

};
export default {
  state,
  getters,
  actions,
  mutations,
};
