<template>
  <div class="dashboard p-4">
    <div class="wrapper-button mt-3">
      <select
        v-model="selectedPath"
        id="cats"
        class="w-full lg:w-3/12 float-none lg:float-left bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 mr-5 mb-5"
      >
        <option value="" disabled>Please select category</option>
        <option :value="'favs:' + beepruser.uid">My Favorites</option>
        <option
          v-for="eangroup in clientData.eans"
          :key="eangroup"
          :value="'eans:' + eangroup.replace(/[\W_]+/g, '')"
        >
          {{ eangroup.charAt(0).toUpperCase() + eangroup.slice(1) }}
        </option>
        <option
          v-for="path in clientData.paths"
          :key="path"
          :value="'paths:' + path.replace(/[\W_]+/g, '')"
        >
          {{ path }}
        </option>
        <option
          v-for="brand in clientData.brands"
          :key="brand"
          :value="'brands:' + brand.replace(/[\W_]+/g, '')"
        >
          {{ brand }}
        </option>
      </select>
    </div>

    <Multiselect
      v-if="chains.length > 0"
      class="w-full md:w-2/12 mb-5 float-left"
      v-model="selectedChains"
      mode="tags"
      placeholder="Filter chains"
      :close-on-select="false"
      :filter-results="true"
      :min-chars="10000"
      :resolve-on-load="false"
      :infinite="false"
      :clear-on-search="false"
      :delay="0"
      :searchable="true"
      :options="chains"
      :limit="100"
    />

    <Multiselect
      v-if="brands.length > 0"
      class="w-full md:w-2/12 md:ml-5 mb-5 float-left"
      v-model="selectedBrands"
      mode="tags"
      placeholder="Filter brands"
      :close-on-select="false"
      :filter-results="true"
      :min-chars="10000"
      :resolve-on-load="false"
      :infinite="false"
      :clear-on-search="false"
      :delay="0"
      :searchable="true"
      :options="brands"
    />

  <Multiselect
    v-if="weeks.length > 0" class="w-full md:w-2/12 md:ml-5 mb-5 float-left"
    v-model="selectedWeeks"
    mode="tags"
    placeholder="Filter weeks"
    :close-on-select="false"
    :filter-results="true"
    :min-chars="10000"
    :resolve-on-load="false"
    :infinite="false"
    :clear-on-search="false"
    :delay="0"
    :searchable="true"
    :options="weeks"
  />

    <!--   <Multiselect
    v-if="units.length > 1" class="w-full md:w-2/12 md:ml-5 mb-5 float-left"
    v-model="selectedUnits"
    mode="tags"
    placeholder="Filter units"
    :close-on-select="false"
    :filter-results="true"
    :min-chars="10000"
    :resolve-on-load="false"
    :infinite="false"
    :clear-on-search="false"
    :delay="0"
    :searchable="true"
    :options="units"
  />

  <Multiselect
    v-if="uoms.length > 1" class="w-full md:w-2/12 md:ml-5 mb-5 float-left"
    v-model="selectedUoms"
    mode="tags"
    placeholder="Filter UOM"
    :close-on-select="false"
    :filter-results="true"
    :min-chars="10000"
    :resolve-on-load="false"
    :infinite="false"
    :clear-on-search="false"
    :delay="0"
    :searchable="true"
    :options="uoms"
  />
 -->
    <div class="mt-5 bg-white dark:bg-gray-800 p-5 w-full rounded-md box-border shadow clear-both">
      <!--       <h2 class="font-bold float-left text-lg text-gray-800 dark:text-gray-200">
        Current Brochure Offers
      </h2>
 -->
      <!--       <download-excel
        class="float-right"
        :data="formatNumbers"
        :fields="json_fields2"
        :name="selectedPathSuffix + '.xls'"
      >
        <Icon icon="iwwa:csv" height="24px" />
      </download-excel>
 -->
      <div
        v-if="ppb && Object.keys(ppb).length > 0"
        class="w-full md:w-5/12 float-left clear-both my-5 mx-10"
      >
        <barchart :labels="chainData.labels" :series="chainData.series" name="Offers by chain" :key="`p-${selectedPath}-${chainData.labels}`" />
      </div>

      <div
        v-if="ppb && Object.keys(ppb).length > 0"
        class="w-full md:w-5/12 float-right my-5 mx-10"
      >
        <barchart :labels="priceData.labels" :series="priceData.series" name="Offers by price" :key="`b-${selectedPath}-${priceData.labels}`" />
      </div>

      <div v-if="ppb && Object.keys(ppb).length > 0" class="wrapping-table mt-10">
        <table
          class="w-full text-sm text-left text-gray-500 dark:text-gray-400 lg:overflow-auto overflow-x-scroll"
        >
          <thead
            class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 sticky top-0"
          >
            <tr>
              <th
                style="cursor: pointer"
                scope="col"
                class="uppercase px-6 py-3"
                @click="updateSort('name')"
              >
                <svg
                  v-if="sortBy == 'name' && this.sortAsc"
                  style="float: left"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-sort-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"
                  />
                </svg>

                <svg
                  v-else-if="sortBy == 'name' && !this.sortAsc"
                  style="float: left"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-sort-down"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"
                  />
                </svg>
                Product
              </th>
              <th
                style="cursor: pointer"
                scope="col"
                class="uppercase px-6 py-3"
                @click="updateSort('brand')"
              >
                <svg
                  v-if="sortBy == 'brand' && this.sortAsc"
                  style="float: left"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-sort-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"
                  />
                </svg>

                <svg
                  v-else-if="sortBy == 'brand' && !this.sortAsc"
                  style="float: left"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-sort-down"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"
                  />
                </svg>

                Brand
              </th>

              <th
                style="cursor: pointer"
                scope="col"
                class="uppercase px-6 py-3"
                @click="updateSort('normUnit')"
              >
                <svg
                  v-if="sortBy == 'normUnit' && this.sortAsc"
                  style="float: left"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-sort-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"
                  />
                </svg>

                <svg
                  v-else-if="sortBy == 'normUnit' && !this.sortAsc"
                  style="float: left"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-sort-down"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"
                  />
                </svg>

                Units
              </th>
              <th scope="col" class="uppercase px-6 py-3">UoM</th>

              <th scope="col" class="uppercase px-6 py-3">Offers</th>
              <!--               <th scope="col" class="uppercase px-6 py-3">Price</th>
              <th scope="col" class="uppercase px-6 py-3">From</th>
              <th scope="col" class="uppercase px-6 py-3">Until</th>
 -->
              <!--               <th style="cursor: pointer" scope="col" class="uppercase px-6 py-3" @click="updateSort('ppu')">
                <svg v-if="sortBy == 'ppu' && this.sortAsc" style="float: left" xmlns="http://www.w3.org/2000/svg"
                  width="16" height="16" fill="currentColor" class="bi bi-sort-up" viewBox="0 0 16 16">
                  <path
                    d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z" />
                </svg>

                <svg v-else-if="sortBy == 'ppu' && !this.sortAsc" style="float: left" xmlns="http://www.w3.org/2000/svg"
                  width="16" height="16" fill="currentColor" class="bi bi-sort-down" viewBox="0 0 16 16">
                  <path
                    d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z" />
                </svg>

                Price per unit
              </th>
              <th scope="col" class="uppercase px-6 py-3">Chains</th>
 -->
              <th scope="col" class="uppercase px-6 py-3">Graph</th>
              <th
                v-if="clientData && clientData.competition"
                scope="col"
                class="uppercase px-6 py-3"
              >
                Comp.
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50"
              v-for="items in allProducts"
              :key="items.ean"
            >
              <td class="px-6 py-4">
                <a
                  v-if="
                    beepruser &&
                    beepruser.data &&
                    beepruser.data.email &&
                    beepruser.data.email.includes('@beepr.'+country)
                  "
                  class="float-left mr-2"
                  :href="'https://products.beepr.'+country+'/edit/' + items.ean"
                  target="_blank"
                >
                  <Icon icon="bytesize:edit" height="20px" />
                </a>

                <a
                  target="_blank"
                  :href="
                    'https://beepr.' +
                    country +
                    '/products/' +
                    items.ean +
                    '/' +
                    items.name
                      .toLowerCase()
                      .trim()
                      .replace(/\./g, '')
                      .replace(/ø/g, 'oe')
                      .replace(/æ/g, 'ae')
                      .replace(/å/g, 'aa')
                      .replace(/[^a-z0-9]+/g, '-')
                  "
                  >{{ items.importName ? items.importName : items.name }}</a
                >
                <br />
                <span
                  v-if="!selectedPath.includes('paths') && items.path && items.path.length > 5"
                  style="font-size: 9px; color: gray"
                  >{{ items.path }}</span
                >
              </td>

              <td class="px-6 py-4">
                {{ items.brand }}
              </td>

              <td class="px-6 py-4">
                {{ Intl.NumberFormat("da-DK", {}).format(items.normUnit) }}
              </td>
              <td class="px-6 py-4">
                {{ items.normUom }}
              </td>

              <td class="px-6 py-4">
                <p class="h-8" v-for="o in items.ppbObj" :key="items.ean + o.chain">
                  <img
                    :src="require('../assets/img/' + o.chain + '.webp')"
                    width="24"
                    style="float: left"
                    class="mr-2"
                    alt=""
                  />
                  {{ o.from + " - " + o.until }}
                  <span class="font-bold">{{ o.price }}</span>
                  <br />
                </p>
              </td>

              <td class="px-6 py-4">
                <button
                  @click="
                    isOpen = {};
                    isOpen[items.ean] = true;
                  "
                  type="button"
                >
                  <Icon icon="codicon:graph-line" height="22px" />
                </button>
                <product-modal
                  :isOpen="isOpen"
                  @closemodal="closeModal(items.ean)"
                  :ean="items.ean"
                  :name="items.name"
                />
              </td>

              <td v-if="clientData && clientData.competition" class="px-6 py-4">
                <router-link
                  class="float-left mr-2"
                  @click="allowedEAN = items.ean"
                  :to="'/competition/?ean=' + items.ean"
                >
                  <Icon icon="mdi:podium-gold" height="22px" />
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-else>There have been no offers in brochures for this category</div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { Icon } from "@iconify/vue";
import { mapGetters } from "vuex";
import ProductModal from "./ProductModal.vue";
import Multiselect from "@vueform/multiselect";
import Barchart from "./components/brochurechart.vue";

export default {
  name: "Brochure",
  data() {
    return {
      currProducts: [],
      brands: [],
      chains: [],
      units: [],
      uoms: [],
      weeks: [],
      selectedBrands: [],
      selectedChains: [],
      selectedUnits: [],
      selectedUoms: [],
      selectedWeeks: [],
      sortBy: "ppu",
      sortAsc: true,
      labels: [],
      series: [],
      isOpen: {},
    };
  },
  components: {
    Icon,
    Multiselect,
    ProductModal,
    Barchart,
  },
  watch: {
    clientData(newData, old) {
      if (newData) { 
        if (this.beepruser && this.beepruser.uid && this.beepruser.data && this.beepruser.data.favs && this.beepruser.data.favs.length > 0 ) {
          this.selectedPath = "favs:" + this.beepruser.uid;
        }else if (newData.eans && newData.eans.length > 0) {
          this.selectedPath = "eans:" + newData.eans[0].replace(/[\W_]+/g, "");
        } else if (newData.paths && newData.paths.length > 0) {
          this.selectedPath = "paths:" + newData.paths[0].replace(/[\W_]+/g, "");
        } else if (newData.brands && newData.brands.length > 0) {
          this.selectedPath = "brands:" + newData.brands[0].replace(/[\W_]+/g, "");
        }
      }
    },
  },
  methods: {
    /*     getPPBInfo(myppb, ean) {
      //let today = new Date()
      let ppbObj = [];
      if (myppb) {        
      Object.keys(myppb).forEach((p) => {
        myppb[p].forEach((o) => {
          let price = Intl.NumberFormat("da-DK", { style: "currency", currency: "DKK" }).format(o.Price.toFixed(2));
          const from = o.From.toDate().toLocaleDateString("da-DK");
          const until = o.Until.toDate().toLocaleDateString("da-DK");
          //if(o.Until.toDate() > today) {
            ppbObj.push({chain: p, from: from, until: until, price: price})
          //}
          //texts.push(p + ' ' + from + ' until ' + until + ' ' + price);
        })
      })
    }
    //if (ppbObj.length == 0) {
        //console.log("to delete " + ean);
        //delete this.ppb[ean];
        //console.log(this.ppb)
    //}
      return ppbObj;
    },  
 */ closeModal(ean) {
      this.isOpen[ean] = false;
      this.series = [];
      this.labels = [];
    },
    updateSort(newSort) {
      if (newSort == this.sortBy) {
        this.sortAsc = !this.sortAsc;
      } else {
        this.sortBy = newSort;
        this.sortAsc = true;
      }
    },
    filterChains(chains) {
      return chains.filter((a) => a !== "postme");
    },
  },
  computed: {
    ...mapGetters({
      country: "country",
      beepruser: "beepruser",
      client: "client",
      clientData: "clientData",
      newcurrent: "newcurrent",
      notfound: "notfound",
      noprice: "noprice",
      currYear: "currYear",
      currMon: "currMon",
      productprices: "productprices",
      selectedPathSuffix: "selectedPathSuffix",
      ppb: "ppb",
    }),

    priceData() {
      console.log("Calling price data");
      let tmpObj = {};
      let tmpArr = [];
      let labels = [];
      //console.log(this.ppb)
      this.currProducts.forEach((p) => {
        if (p.ppbObj) {
          p.ppbObj.forEach((o) => {
            //p.ppbObj[c].forEach((o) => {
            //let price = o.price;
            tmpObj[o.price] = tmpObj[o.price] + o.days || o.days;
            //})
          });
        }
      });

      //console.log(tmpObj);

      Object.keys(tmpObj)
        .sort((a, b) => a - b)
        .forEach((key2) => {
          tmpArr.push(tmpObj[key2]);
          labels.push(key2);
        });

      let out = { data: tmpArr };
      //this.barseries.push(out);
      return { series: [out], labels: labels };
      //return [out];
    },

    chainData() {
      let tmpObj = {};
      let tmpArr = [];
      let labels = [];
      //console.log(this.ppb)
      this.currProducts.forEach((p) => {
        if (p.ppbObj) {
          p.ppbObj.forEach((o) => {
            let chain = o.chain[0].toUpperCase() + o.chain.slice(1);
            tmpObj[chain] = tmpObj[chain] + o.days || o.days;
          });
        }
      });

      Object.keys(tmpObj)
        .sort((a, b) => a - b)
        .forEach((key2) => {
          tmpArr.push(tmpObj[key2]);
          labels.push(key2);
        });

      let out = { data: tmpArr };
      //this.barseries.push(out);
      return { series: [out], labels: labels };
      //return [out];
    },

    json_fields2() {
      let tmp = {
        EAN: "ean",
        Product: "name",
        Brand: "brand",
        "Lowest price": "price",
        Offer: "offer",
        Unit: "normUnit",
        "Unit of measure": "normUom",
        "Price-per-unit": "ppu",
        Chains: "chains",
      };
      return tmp;
    },

    formatNumbers() {
      let products = JSON.parse(JSON.stringify(this.allProducts));
      products.map((p) => {
        p.ean = `'${p.ean}'`;
        p.name = p.importName ? p.importName : p.name;
        //p.Name = `${p.Name +" "}`;
        //p.Brand = `${p.Brand + " "}`;
        //p.chains = `${p.chains+ " "}`;
        //p.NormalizedUom = `${p.NormalizedUom+ " "}`;
        //p.LowestPrice = Math.round(p.LowestPrice * 100) / 100;
        //p.PPU = Math.round(p.PPU * 100) / 100;
        //p.NormalizedUnit = Math.round(p.NormalizedUnit * 100) / 100;
        p.price = Intl.NumberFormat("da-DK", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(p.price.toFixed(2));
        p.ppu = p.ppu
          ? Intl.NumberFormat("da-DK", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(p.ppu.toFixed(2))
          : "0,00";
        p.normUnit = p.normUnit
          ? Intl.NumberFormat("da-DK", {
              minimumFractionDigits: 1,
              maximumFractionDigits: 2,
            }).format(p.normUnit)
          : "0,00";
      });
      //console.log(products)
      return products;
    },

    selectedPath: {
      get() {
        return this.$store.state.beeprStore.selectedPath;
      },
      set(value) {
        this.$store.dispatch("setSelectedPath", value);
        this.$store.dispatch("resetNewCurrent");
        this.$store.dispatch("setBrochureCurrent");
      },
    },

    allowedEAN: {
      get() {
        return this.$store.state.beeprStore.allowedEAN;
      },
      set(value) {
        this.$store.dispatch("setAllowedEAN", value);
      },
    },

    allProducts() {
      console.log("Calling allProducts")
      let products = {};

      if (this.sortBy == "name" || this.sortBy == "brand") {
        if (this.sortAsc) {
          products = this.newcurrent[this.selectedPathSuffix]
            ? this.newcurrent[this.selectedPathSuffix].sort((a, b) =>
                a[this.sortBy].localeCompare(b[this.sortBy], "da")
              )
            : [];
        } else {
          products = this.newcurrent[this.selectedPathSuffix]
            ? this.newcurrent[this.selectedPathSuffix].sort((a, b) =>
                b[this.sortBy].localeCompare(a[this.sortBy], "da")
              )
            : [];
        }
      } else {
        if (this.sortAsc) {
          products = this.newcurrent[this.selectedPathSuffix]
            ? this.newcurrent[this.selectedPathSuffix].sort(
                (a, b) => a[this.sortBy] - b[this.sortBy]
              )
            : [];
        } else {
          products = this.newcurrent[this.selectedPathSuffix]
            ? this.newcurrent[this.selectedPathSuffix].sort(
                (a, b) => b[this.sortBy] - a[this.sortBy]
              )
            : [];
        }
      }

      //console.log(Object.keys(this.ppb).length)
      //console.log(this.ppb);
      if (this.ppb && Object.keys(this.ppb).length > 0) {
        products = products.filter((o) => Object.keys(this.ppb).includes(o.ean));
      }

      let weeks = {};
      products.forEach((p, i) => {
        let ppbObj = [];
        //console.log(this.ppb);
        if (this.ppb[p.ean]) {
          //console.log(this.ppb[p.ean]);

          Object.keys(this.ppb[p.ean]).forEach((c) => {
            this.ppb[p.ean][c].forEach((o) => {
              let price = Intl.NumberFormat("da-DK", { style: "currency", currency: "DKK" }).format(
                o.Price.toFixed(2)
              );
              const from = o.From.toDate().toLocaleDateString("da-DK");
              const until = o.Until.toDate().toLocaleDateString("da-DK");
              //if(o.Until.toDate() > today) {

                //console.log(this.selectedWeeks);
                //console.log(o.Week)
                //console.log(this.selectedWeeks.includes(''+o.Week))
              if (this.selectedWeeks.length > 0) {
                if(this.selectedWeeks.includes(''+o.Week)) {
                  //console.log(this.selectedWeeks);
                  ppbObj.push({
                    chain: c,
                    from: from,
                    until: until,
                    price: price,
                    days: o.Days,
                    week: o.Week,
                    year: o.Year,
                  });
                }                                
              } else {
                ppbObj.push({
                  chain: c,
                  from: from,
                  until: until,
                  price: price,
                  days: o.Days,
                  week: o.Week,
                  year: o.Year,
                });                
              }

              weeks[o.Week] = true;
              //}
              //texts.push(p + ' ' + from + ' until ' + until + ' ' + price);
            });
          });
          //console.log(i);
          //console.log(products);
          products[i]["ppbObj"] = ppbObj;
        }
      });

      this.weeks = Object.keys(weeks);

      //console.log(products);

      let tmp2 = products.map(function (obj) {
        return obj.brand;
      });
      this.brands = [...new Set(tmp2)].sort();

      /*       tmp2 = products.map(function (obj) { return obj.normUnit; });
      this.units = [...new Set(tmp2)].sort();

      tmp2 = products.map(function (obj) { return obj.normUom; });
      this.uoms = [...new Set(tmp2)].sort();
 */
      tmp2 = products.map(function (obj) {
        return [...obj.chains];
      });
      this.chains = [...new Set(tmp2.flat())].sort();

      if (this.selectedChains.length > 0) {
        products = products.filter((o) => o.chains.some((r) => this.selectedChains.includes(r)));
      }

      if (this.selectedBrands.length > 0) {
        products = products.filter((o) => this.selectedBrands.includes(o.brand));
      }

      /*       if (this.selectedUnits.length > 0) {
        products = products.filter((o) => this.selectedUnits.includes(o.normUnit));
      }

      if (this.selectedUoms.length > 0) {
        products = products.filter((o) => this.selectedUoms.includes(o.normUom));
      }
 */
      this.currProducts = products;
      return products;
    },
  },
  mounted() {
    this.$store.dispatch("setBrochureCurrent");
    this.$store.commit("CLEAR_PPB");
  },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
