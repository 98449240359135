<template>
  <div class="p-4">

    <div v-if="admin" class="wrapper-button mt-1">
      <select
        v-model="level"
        id="levels"
        class="w-full lg:w-1/6 float-none lg:float-left bg-white border border-gray-300 text-gray-900 text-sm rounded-md block p-2.5 mr-5"
      >
      <option value="" disabled>Please select level</option>
        <option value="Cat1">
          Category 1
        </option>
        <option value="Cat2">
          Category 2
        </option>
      </select>
    </div>

    <Multiselect
    v-if="allCategories.length > 0" class="w-full md:w-1/2 mb-5 float-left"
    v-model="selectedCategories"
    mode="tags"
    placeholder="Filter categories"
    :close-on-select="false"
    :filter-results="true"
    :min-chars="10000"
    :resolve-on-load="false"
    :infinite="false"
    :clear-on-search="false"
    :delay="0"
    :searchable="true"
    :options="allCategories"
    :limit="100"
  />

 <div class="clear-both">&nbsp;</div>
    <div class="mt-1 bg-white dark:bg-gray-800 p-5 w-full rounded-md box-border shadow">
      <h2 class="font-bold text-lg text-gray-800 dark:text-gray-200 float-left">
        All products in category
      </h2>
      <download-csv
        class   = "float-right"
        :data   = "allProducts"
        name    = "report.csv">
        <Icon icon="iwwa:csv" height="24px"/>
      </download-csv>
<!--       <p class="text-gray-400 font-lexend font-normal">All the products in the selected category</p>
 -->      <div class="wrapping-table mt-10 block" style="overflow: auto; max-height: 75vh">
        <table
          class="w-full text-sm text-left text-gray-500 dark:text-gray-400 lg:overflow-auto overflow-x-scroll"
        >
          <thead
            class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 sticky top-0"
          >
            <tr>
              <th
                style="cursor: pointer"
                scope="col"
                class="uppercase px-6 py-3"
                @click="updateSort('category')"
              >
                <svg
                  v-if="sortBy == 'category' && this.sortAsc"
                  style="float: left"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-sort-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"
                  />
                </svg>

                <svg
                  v-else-if="sortBy == 'category' && !this.sortAsc"
                  style="float: left"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-sort-down"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"
                  />
                </svg>
                Category
              </th>

              <th
                style="cursor: pointer"
                scope="col"
                class="uppercase px-6 py-3"
                @click="updateSort('13mnd-change')"
              >
                <svg
                  v-if="sortBy == '13mnd-change' && this.sortAsc"
                  style="float: left"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-sort-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"
                  />
                </svg>

                <svg
                  v-else-if="sortBy == '13mnd-change' && !this.sortAsc"
                  style="float: left"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-sort-down"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"
                  />
                </svg>
                {{ (country=="dk") ? '1 year change' : 'Change' }}
              </th>

              <th
                v-if="country=='dk'"
                style="cursor: pointer"
                scope="col"
                class="uppercase px-6 py-3"
                @click="updateSort('6mnd-change')"
              >
                <svg
                  v-if="sortBy == '6mnd-change' && this.sortAsc"
                  style="float: left"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-sort-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"
                  />
                </svg>

                <svg
                  v-else-if="sortBy == '6mnd-change' && !this.sortAsc"
                  style="float: left"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-sort-down"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"
                  />
                </svg>
                6 month change
              </th>

              <th
                v-for="(m,i) in getMonths"
                :key="m"
                style="cursor: pointer"
                scope="col"
                class="uppercase px-6 py-3"
                @click="updateSort(m+'-change')"
              >
                <svg
                  v-if="sortBy == m+'-change' && this.sortAsc"
                  style="float: left"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-sort-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"
                  />
                </svg>

                <svg
                  v-else-if="sortBy == m+'-change' && !this.sortAsc"
                  style="float: left"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-sort-down"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"
                  />
                </svg>
                {{ (6-i)}} mnd ago
              </th>
              <th scope="col" class="uppercase px-6 py-3">#Products</th>

              <th scope="col" class="uppercase px-6 py-3">Graph</th>
              <th scope="col" class="uppercase px-6 py-3">Products</th>
            </tr>
          </thead>
          <tbody>

         <tr
              class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50"
              v-for="items in allProducts"
              :key="items.category"
            >
              <td v-if="items.category == 'All'" class="px-6 py-4">
                {{ items.category }}
              </td>
              <td v-else-if="level == 'Cat2'" class="px-6 py-4">
                {{ items.category }}
              </td>
              <td v-else class="px-6 py-4" style="cursor: pointer;" @click="level = 'Cat2'; catFilter = items.category">
                {{ items.category }}
              </td>

              <td class="px-6 py-4" :style="(items['13mnd-change'] == 0) ? '': (items['13mnd-change'] > 0) ? 'color:red' : 'color:green'">
                {{
                  Intl.NumberFormat("da-DK", { style:"percent", maximumFractionDigits: 1 }).format(
                    (items['13mnd-change'])
                  )
                }}
              </td>

              <td v-if="country=='dk'" class="px-6 py-4" :style="(items['6mnd-change'] == 0) ? '': (items['6mnd-change'] > 0) ? 'color:red' : 'color:green'">
                {{
                  Intl.NumberFormat("da-DK", { style:"percent", maximumFractionDigits: 1 }).format(
                    (items['6mnd-change'])
                  )
                }}
              </td>
              
              <td class="px-6 py-4" v-for="m in getMonths" :key="m" :style="(!items[m+'-change'] || items[m+'-change'] == 0) ? '': (items[m+'-change'] > 0) ? 'color:red' : 'color:green'">
                {{ (items[m+'-avg']) ? Intl.NumberFormat("da-DK", { style: "currency", currency: "DKK"}).format(items[m+'-avg']) : '-'}}
                ({{ (items[m+'-change']) ? Intl.NumberFormat("da-DK", { style:"percent", maximumFractionDigits: 1}).format(items[m+'-change']) : '-' }})
              </td>

              <td class="px-6 py-4">
                {{ items.number }}
              </td>
              <td class="px-6 py-4">
                <button
                  @click="
                    labels = Object.keys(prices[items.category.replace(/[\W_]+/g, '')]);
                    series = getPrices(items.category.replace(/[\W_]+/g, ''));
                    isOpen = {};
                    isOpen[items.category.replace(/[\W_]+/g, '')] = true;
                  "
                  class="text-black"
                  type="button"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    role="img"
                    width="20px"
                    height="20px"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 16 16"
                  >
                    <g fill="currentColor">
                      <path d="M15 13v1H1.5l-.5-.5V0h1v13h13Z" />
                      <path
                        d="M13 3.207L7.854 8.354h-.708L5.5 6.707l-3.646 3.647l-.708-.708l4-4h.708L7.5 7.293l5.146-5.147h.707l2 2l-.707.708L13 3.207Z"
                      />
                    </g>
                  </svg>
                </button>
                <percent-chart-modal
                  :isOpen="isOpen"
                  @closemodal="isOpen[items.category.replace(/[\W_]+/g, '')] = false"
                  :labels="labels"
                  :series="series"
                  :ean="items.category.replace(/[\W_]+/g, '')"
                  :name="items.category"
                />
              </td>
              <td v-if="items.category == 'All'" class="px-6 py-4"></td>
              <td v-else class="px-6 py-4">
                <router-link class="underline" @click="selectedBeeprPath = level+items.category.replace(/[\W_]+/g, '')" :to="{name:'eandetails'}">
                  <Icon icon="ic:round-list-alt" height="24px" />
              </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { Icon } from "@iconify/vue";
import Multiselect from "@vueform/multiselect/src/Multiselect";
import { mapGetters } from "vuex";
import PercentChartModal from "./components/percentchartmodal.vue";

export default {
  name: "Dashboard",
/*   metaInfo() {
    return {
      title: "Beepr Report"
    };
  },
 */  data() {
    return {
      selectedPath: "report",
      allCategories: [],
      selectedCategories: [],
      sortBy: "category",
      sortAsc: true,
      labels: [],
      series: [],
      isOpen: {},
      catFilter: "",
    };
    // end chart data line
  },
  components: {
    Icon,
    PercentChartModal,
    Multiselect
},
  methods: {
    getDaysInMonth(year, month) {
      return new Date(year, month, 0).getDate();
    },
    addMonths(input, months) {
      const date = new Date(input);
      date.setDate(1);
      date.setMonth(date.getMonth() + months);
      date.setDate(
        Math.min(input.getDate(), this.getDaysInMonth(date.getFullYear(), date.getMonth() + 1))
      );
      return date;
    },

    getPrices(category) {
      //console.log(category);
      //console.log(this.prices);
      //console.log(Object.keys(this.prices[category]));
      //console.log(this.prices[category]);

      let prices = this.prices[category];
      let output = [];
      let tmpobj = [];
      Object.keys(prices)
        .sort()
        .forEach((key) => {
          Object.keys(prices[key]).forEach((key2) => {
            (tmpobj[key2] = tmpobj[key2] || []).push(prices[key][key2]);
          });
        });

      Object.keys(tmpobj)
        .sort()
        .forEach((key) => {
          //console.log(key);
          //console.log(tmpobj[key]);
          let out = { name: key, data: tmpobj[key] };
          //if (tmpobj[key][0] > 0 || tmpobj[key][25] > 0) {
            output.push(out);
          //}
        });
        //console.log(output);
      return output;
      //return [{name: 'test', data: [prices['2022-06-01']['Minkobmand'],9,9,9,9,9,9,9]}]
    },
    updateSort(newSort) {
      if (newSort == this.sortBy) {
        this.sortAsc = !this.sortAsc;
      } else {
        this.sortBy = newSort;
        this.sortAsc = true;
      }
    },
  },
  computed: {
    ...mapGetters({
      country: "country",
      beepruser: "beepruser",
      client: "client",
      clientData: "clientData",
      current: "current",
      prices: "prices",
      currYear: "currYear",
      currMon: "currMon",
    }),

    selectedBeeprPath: {
      get() {
        return this.$store.state.beeprStore.selectedBeeprPath;
      },
      set(value) {
        this.$store.dispatch("setSelectedBeeprPath", value);
        this.$store.dispatch("resetPrices");
        this.$store.dispatch("setPrices");
      },
     },

/*      selectedPath: {
      get() {
        return this.$store.state.beeprStore.selectedPath;
      },
      set(value) {
        this.$store.dispatch("setSelectedPath", value);
        this.$store.dispatch("resetPrices");
        this.$store.dispatch("setPrices", value);
      },
     },
 */
     level: {
      get() {
        return this.$store.state.beeprStore.level;
      },
      set(value) {
        this.$store.dispatch("setLevel", value);
      },
    },

    getMonths() {
      let months = [];
      for (let index = 1; index <= 6; index++) {
        let dateObj = new Date();
        let newDate = this.addMonths(dateObj, -index);
        let month = ("0" + (newDate.getMonth() + 1)).slice(-2);
        let year = newDate.getFullYear();
        months.push(year + month);
      }
      return months.reverse();
    },

    yearmon: {
      get() {
        return this.$store.state.beeprStore.yearmon;
      },
      set(value) {
        this.$store.dispatch("setYearmon", value);
        this.$store.dispatch("resetCurrent");
        this.$store.dispatch("setCurrent");
        this.$store.dispatch("resetPrices");
        this.$store.dispatch("setPrices");
      },
    },

    allProducts() {
      let tmp = [];
      if (this.sortBy == "category") {
        if (this.sortAsc) {
          tmp = this.current[this.selectedPath]
              ? this.current[this.selectedPath].sort((a, b) =>
                  a[this.sortBy]
                    .localeCompare(b[this.sortBy], "da")
                ).filter((o) => o.number > 9)
            : [];
        } else {
          tmp = this.current[this.selectedPath]
              ? this.current[this.selectedPath].sort((a, b) =>
                  b[this.sortBy].localeCompare(a[this.sortBy], "da")
                ).filter((o) => o.number > 9)
            : [];
        }
      } else {
        if (this.sortAsc) {
          tmp = this.current[this.selectedPath]
              ? this.current[this.selectedPath].sort((a, b) => a[this.sortBy] - b[this.sortBy]).filter((o) => o.number > 9)
            : [];
        } else {
          tmp = this.current[this.selectedPath]
              ? this.current[this.selectedPath].sort((a, b) => b[this.sortBy] - a[this.sortBy]).filter((o) => o.number > 9)
            : [];
        }
      }

      let toReturn = [];

      if (this.level == "Cat1") {
        toReturn = tmp.filter((o) => !o.category.includes("/")); 
      } else {
        if (this.catFilter != "") {
          //console.log(this.catFilter);
          toReturn = tmp.filter((o) => o.category.includes(this.catFilter+"/")); 
        } else {
          toReturn = tmp.filter((o) => o.category.includes("/"));
        }
      }
      //console.log(toReturn)

      let tmp2 = toReturn
          .map(function (obj) {
            return obj.category;
          });
      this.allCategories = [...new Set(tmp2)].sort();

      if (this.selectedCategories.length > 0) {
        toReturn = toReturn.filter((o) => this.selectedCategories.includes(o.category));
      }

      return toReturn;
    },

    allProductsCount() {
      return this.current[this.selectedPath] ? this.current[this.selectedPath].length : 0;
    },
    admin() {
      //console.log(this.beepruser.data.client)
      if (this.clientData && this.clientData.beeprreport) {
        //console.log("Push to report");
        //this.$router.push({ name: "report"});
        return true;
      } else {
        this.$router.push({ name: "home"});
        return false;
      }
    },
  },
  mounted() {
    //this.$store.commit("SET_CLIENT", "beepr");
    //this.selectedPath = "report";
    this.$store.dispatch("setCurrent");
    this.$store.dispatch("setPrices");

/*     this.$store.dispatch("setSelectedPath", "report");
    this.$store.dispatch("resetPrices");
    this.$store.dispatch("setPrices", "report");
 *//*     if (this.clientData) {
      this.selectedPath = "report";
    }
 */  },
};
</script>

<style>
</style>