<template>
  <div v-show="isOpen" style="min-height: fit-content"
    class="fixed z-30 inset-0 overflow-y-auto bg-black bg-opacity-50">
    <div class="flex items-start justify-center min-h-screen pt-2 text-center w-full">
      <div class="bg-white rounded-lg text-left overflow-hidden shadow-xl p-8 w-5/6">
        <h2 class="float-left font-bold text-lg text-gray-800 dark:text-gray-200">
          Market Share
        </h2>

        <button type="button" @click="closemodal()"
          class="float-right text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm mb-3">
          <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"></path>
          </svg>
        </button>

        <div class="clear-both">
          <div class="bg-gray-200 p-5 w-full rounded-md box-border shadow">
            <p class="text-gray-400 font-lexend font-normal float-left">
              {{ name }}
            </p>

            <div @click="showHistory = !showHistory"
              class="w-14 h-8 float-right bg-gray-300 rounded-full p-1 duration-300 ease-in-out"
              :class="{ 'bg-green-400': showHistory }">
              <div class="bg-white w-6 h-6 rounded-full shadow-md transform duration-300 ease-in-out"
                :class="{ 'translate-x-6': showHistory }"></div>
            </div>
            <p class="float-right mt-1 mr-5">Month by month</p>

            <br />
            <br />

            <div v-if="!series || series.length == 0" class="flex flex-wrap p-4" style="height: 480px">
              <base-progress :percentage="contentProgress" class="mx-2 mb-2" indeterminate></base-progress>
            </div>

            <apexchart style="margin-left: 10vw" height="750"
              v-if="!showHistory && series && series.length > 0 && labels && labels.length > 0" type="donut" width="700"
              :key="series.length" :options="chartOptions" :series="series" :labels="labels"></apexchart>

            <apexchart type="bar" height="750"
              v-if="showHistory && histSeries && histSeries.length > 0 && histLabels && histLabels.length > 0"
              :key="histSeries.length" :labels="histLabels" :options="histChartOptions" :series="histSeries">
            </apexchart>

          </div>
        </div>
      </div>
    </div>
  </div>
  <!--</section>-->
</template>

<script>
// @ is an alias to /src
import { Icon } from "@iconify/vue";
import BaseProgress from "./BaseProgress.vue";

//import { mapGetters } from "vuex";

export default {
  name: "PieModal",
  props: {
    isOpen: Boolean,
    labels: Array,
    series: Array,
    name: String,
    histLabels: Array,
    histSeries: Array,
    bestIndex: Number,
  },
  mounted() { },

  watch: {
    labels: function (newVal, oldVal) {
      // watch it
      //console.log('Prop changed: ', newVal, ' | was: ', oldVal)
      this.chartOptions = {
        ...this.chartOptions,
        ...{
          labels: newVal,
          legend: {
            formatter: function (val, opts) {
              return newVal[opts.seriesIndex] + ": " + opts.w.globals.series[opts.seriesIndex] + " stk"
            }
          },
        }
      }
    },

    histLabels: function (newVal, oldVal) {
      //console.log('Prop changed: ', newVal, ' | was: ', oldVal)
      this.histChartOptions = {
        ...this.histChartOptions,
        ...{
          labels: newVal,
          xaxis: {
            position: "top",
            categories: newVal
          },
        }
      }
    },
    bestIndex: function (newVal, oldVal) {
      //console.log('Prop changed: ', newVal, ' | was: ', oldVal)
      this.histChartOptions = {
        ...this.histChartOptions,
        ...{
            dataLabels: {
          enabled: true,
          enabledOnSeries: [newVal],
          offsetY: 650,
          style: {
            fontSize: '12px',
            colors: ["#304758"]
          },
          formatter: function (value, { seriesIndex, dataPointIndex, w }) {
            //console.log(this.bestIndex, seriesIndex, dataPointIndex, w.globals.series.length);
            //if(seriesIndex == w.globals.series.length-1){
              return w.globals.stackedSeriesTotals[dataPointIndex];
            //}
          }
          }
        }
      }
    }
  },
  data() {
    return {
      showHistory: false,
      contentProgress: 20,

      chartOptions: {
        chart: {
          width: 700,
          height: 750,
          type: 'donut',
        },
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 270,
            donut: {
              labels: {
                show: true,
                total: {
                  show: true,
                  showAlways: true,
                  label: 'Total',
                  fontSize: '22px',
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  fontWeight: 600,
                  color: '#373d3f',
                }
              }
            },
          },
        },
        colors: ['#800000', '#3CB44B', '#000075', '#F032E6', '#E6194B', '#F58231', '#A9A9A9', '#FFE119', '#BFEF45', '#42D4F4', '#4363D8', '#911EB4', '#FABED4', '#FFD8B1', '#469990', '#AAFFC3', '#000000', '#DCBEFF', '#9A6324', '#808000', '#FFFAC8'],
        labels: this.labels,
        //labels: [ ],
        //dataLabels: {
        //  enabled: false
        //},
        fill: {
          type: 'gradient',
        },
        legend: {
          formatter: function (val, opts) {
            return opts.w.globals.labels[opts.seriesIndex] + " - " + opts.w.globals.series[opts.seriesIndex] + " stk"
          }
        },
/*         responsive: [{
          breakpoint: 1000,
          options: {
            chart: {
              width: 400
            },
            legend: {
              position: 'bottom'
            }
          }
        }],
 */      },

      histChartOptions: {
        chart: {
          type: 'bar',
          height: 750,
          stacked: true,
          toolbar: {
            show: true
          },
          zoom: {
            enabled: true
          }
        },
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0
            }
          }
        }],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
          },
        },

        /*              dataLabels: {
            enabled: true,
            enabledOnSeries: [this.histLabels.length-1],
            textAnchor: "left",
            formatter: function (_val, opt) {
              let series = opt.w.config.series
              let idx = opt.dataPointIndex
              const total = series.reduce((total, self) => total + self.data[idx], 0)
              return total
            },
            style: {
              colors: ["#000"]
            }
          },
         */
        dataLabels: {
          enabled: true,
          enabledOnSeries: [this.bestIndex],
          offsetY: 650,
          style: {
            fontSize: '12px',
            colors: ["#304758"]
          },
          formatter: function (value, { seriesIndex, dataPointIndex, w }) {
            //console.log(this.bestIndex, seriesIndex, dataPointIndex, w.globals.series.length);
            //if(seriesIndex == w.globals.series.length-1){
              return w.globals.stackedSeriesTotals[dataPointIndex];
            //}
          }
        },
        colors: ['#800000', '#3CB44B', '#000075', '#F032E6', '#E6194B', '#F58231', '#A9A9A9', '#FFE119', '#BFEF45', '#42D4F4', '#4363D8', '#911EB4', '#FABED4', '#FFD8B1', '#469990', '#AAFFC3', '#000000', '#DCBEFF', '#9A6324', '#808000', '#FFFAC8'],
        labels: this.histLabels,
        xaxis: {
          position: "top",
          categories: this.histLabels
        },
        legend: {
          position: 'right',
          offsetY: 40,
        },
        fill: {
          opacity: 1
        }
      },
    }
  },
  components: {
    Icon,
    BaseProgress,
  },
  methods: {
    closemodal() {
      this.showHistory = false;
      this.$emit("closemodal", false);
    },
  },
};
</script>
