<template>
  <!-- this header -->
    <div id="top" class="wrap-header flex items-center justify-between flex-wrap">

	<nav class="flex items-center justify-between flex-wrap bg-gray-900 p-6 fixed w-full z-20 top-0">
		<div class="flex items-center text-white float-left">
      <router-link :to="client == 'beepr' ? { name: 'report' } : { name: 'home' }">
          <img class="w-1/2 md:w-1/2" src="../assets/img/beepr-logo_1.svg" />
      </router-link>
	</div>

		<div class="block lg:hidden float-right">
			<button id="nav-toggle" class="flex items-center px-3 py-2 border rounded text-gray-500 border-gray-600 hover:text-white hover:border-white">
				<svg class="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
			</button>
		</div>

		<div class="w-full flex-grow lg:flex lg:items-center lg:w-auto hidden lg:block pt-6 lg:pt-0" id="nav-content">
			<ul class="list-reset lg:flex justify-end flex-1 items-center">

        <li class="mr-3">
          <router-link @click="selectLink()" :class="(($route.name == 'home') ? 'text-white underline underline-offset-8' : 'text-gray-300') + ' block py-2 px-4 0 hover:bg-green-500 hover:text-white'" :to="{ name: 'home' }">
            Home
          </router-link>
        </li>
        
        <li class="mr-3">
          <router-link @click="selectLink()" :class="(($route.name == 'prices') ? 'text-white underline underline-offset-8' : 'text-gray-300') + ' block py-2 px-4 0 hover:bg-green-500 hover:text-white'" to="/prices">
            Prices
          </router-link>
        </li>
        
        <li class="mr-3">
          <router-link @click="selectLink()" :class="(($route.name == 'offers') ? 'text-white underline underline-offset-8' : 'text-gray-300') + ' block py-2 px-4 0 hover:bg-green-500 hover:text-white'" to="/offers">
            Summary
          </router-link>
        </li>

        <li class="mr-3">
          <router-link @click="selectLink()" :class="(($route.name == 'segments') ? 'text-white underline underline-offset-8' : 'text-gray-300') + ' block py-2 px-4 0 hover:bg-green-500 hover:text-white'" to="/segments">
            Segments
          </router-link>
        </li>

        <li class="mr-3">
          <router-link @click="selectLink()" :class="(($route.name == 'recenthistory') ? 'text-white underline underline-offset-8' : 'text-gray-300') + ' block py-2 px-4 0 hover:bg-green-500 hover:text-white'" to="/recenthistory">
            History
          </router-link>
        </li>

        <li class="mr-3">
          <router-link
            @click="selectLink()"
            v-if="clientData && clientData.beeprreport"
            :class="(($route.name == 'monthreport') ? 'text-white underline underline-offset-8' : 'text-gray-300') + ' block py-2 px-4 0 hover:bg-green-500 hover:text-white'"
            to="/monthreport"
          >
            Beepr Report
          </router-link>
          <p v-else :class="(($route.name == 'monthreport') ? 'text-white underline underline-offset-8' : 'text-gray-600') + ' block py-2 px-4 0 hover:bg-green-500 hover:text-white'">
            <Icon icon="material-symbols:lock" height="15px" class="float-left mr-2 mt-1" />
            Beepr Report
          </p>
        </li>
        <li class="mr-5">
          <router-link
            @click="selectLink()"
            v-if="clientData && clientData.beeprreport"
            :class="(($route.name == 'chaindetails') ? 'text-white underline underline-offset-8' : 'text-gray-300') + ' block py-2 px-4 0 hover:bg-green-500 hover:text-white'"
            to="/chaindetails"
          >
            Chain Details
          </router-link>
          <p v-else :class="(($route.name == 'chaindetails') ? 'text-white underline underline-offset-8' : 'text-gray-600') + ' block py-2 px-4 0 hover:bg-green-500 hover:text-white'">
            <Icon icon="material-symbols:lock" height="15px" class="float-left mr-2 mt-1" />
            Chain Details
          </p>
        </li>

<!-- 				<li class="mr-3">
					<a class="inline-block py-2 px-4 text-white no-underline" href="#">Active</a>
				</li>
				<li class="mr-3">
					<a class="inline-block text-gray-600 no-underline hover:text-gray-200 hover:text-underline py-2 px-4" href="#">link</a>
				</li>
				<li class="mr-3">
					<a class="inline-block text-gray-600 no-underline hover:text-gray-200 hover:text-underline py-2 px-4" href="#">link</a>
				</li>
				<li class="mr-3">
					<a class="inline-block text-gray-600 no-underline hover:text-gray-200 hover:text-underline py-2 px-4" href="#">link</a>
				</li>
 -->
        <li class="mt-3 md:mt-0">
          <button
            v-if="!beepruser || !beepruser.loggedIn"
            data-modal-toggle="authentication-modal"
            class="bg-transparent hover:bg-green-500 text-white font-semibold hover:text-white py-2 px-4 border border-green-500 hover:border-transparent rounded"
          >
            Login
          </button>
          <button
            v-else
            @click="logout"
            class="bg-transparent hover:bg-green-500 text-white font-semibold hover:text-white py-2 px-4 border border-green-500 hover:border-transparent rounded"
          >
            Logout
          </button>

        </li>
			</ul>
		</div>
	</nav>


<!--    <header class="bg-white dark:bg-gray-800 p-2 border-b-2 dark:border-gray-700">
    <div class="wrap-header flex items-center justify-between flex-wrap">
      <router-link class="w-1/5" :to="client == 'beepr' ? { name: 'report' } : { name: 'home' }">
        <div class="flex flex-no-shrink items-center">
          <img style="max-width: 200px" src="../assets/img/beepr-logo_1.svg" class="ml-1" alt="" />
          <h2 class="lg:block hidden px-20 p-0"></h2>
        </div>
      </router-link>

      <div class="mr-1 flex">
        <button
          v-if="!beepruser || !beepruser.loggedIn"
          data-modal-toggle="authentication-modal"
          class="bg-transparent hover:bg-green-500 text-gray font-semibold hover:text-white py-2 px-4 border border-green-500 hover:border-transparent rounded"
        >
          Login
        </button>

        <button v-else @click="menuToggle" @blur="menuToggleBlur">
          <div class="user-avatar flex hover:bg-gray-100 p-1 cursor-pointer rounded-md">
            {{ client.toUpperCase() }}
            <span class="text-md ml-2 mt-1 text-gray-300"><Icon icon="bi:caret-down-fill" /></span>
          </div>
        </button>

        <transition name="fade">
          <div
            id="dropdownSmall"
            v-show="menu"
            class="block absolute right-3 mt-10 z-10 w-44 border dark:border-gray-700 bg-white rounded divide-y dark:divide-gray-700 divide-gray-100 shadow"
          >
            <div class="py-3 px-4 text-sm text-gray-900">
              <div class="font-medium truncate">MENU</div>
            </div>
            <ul
              class="py-1 text-sm text-gray-700"
              aria-labelledby="dropdownSmallButton"
            >
              <li>
                <router-link class="block py-2 px-4 0 hover:bg-green-500 hover:text-white" :to="client == 'beepr' ? { name: 'report' } : { name: 'home' }">
                  Home
                </router-link>
              </li>
              <li>
                <router-link class="block py-2 px-4 0 hover:bg-green-500 hover:text-white" to="/offers">
                  Product summary
                </router-link>
              </li>
              <li>
                <router-link
                  v-if="clientData && clientData.beeprreport"
                  class="block py-2 px-4 0 hover:bg-green-500 hover:text-white"
                  to="/monthreport"
                >
                  Beepr Report
                </router-link>
                <p v-else class="block py-2 px-4 0 hover:bg-green-500 hover:text-white text-gray-400">
                  <Icon icon="material-symbols:lock" height="15px" class="float-left mr-2 mt-1" />
                  Beepr Report
                </p>
              </li>
              <li>
                <router-link
                  v-if="clientData && clientData.beeprreport"
                  class="block py-2 px-4 0 hover:bg-green-500 hover:text-white"
                  to="/chaindetails"
                >
                  Chain Details
                </router-link>
                <p v-else class="block py-2 px-4 0 hover:bg-green-500 hover:text-white text-gray-400">
                  <Icon icon="material-symbols:lock" height="15px" class="float-left mr-2 mt-1" />
                  Chain Details
                </p>
              </li>
            </ul>
            <div class="py-1" @click.native="logout">
              <p
                class="block py-2 px-4 text-sm text-gray-700 hover:bg-green-500 hover:text-white cursor-pointer"
              >
                Sign out
              </p>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </header>
 -->
    </div>
  
</template>

<script>
import { Icon } from "@iconify/vue";
import { mapGetters } from "vuex";
import { auth } from "../firebaseDb";

export default {
  data() {
    return {
      menu: false,
    };
  },
  components: {
    Icon,
    //LoginModal
  },
  methods: {
    logout() {
      auth.logout();
      this.$store.dispatch("cleanUp");
      this.$router.go(0);
    },
    menuToggle: function () {
      this.menu = !this.menu;
    },
    menuToggleBlur: function () {
      this.menu = false;
    },
    selectLink(){
      document.getElementById("nav-content").classList.add("hidden");
    }
/*     sidebarToggle: function () {
      document.querySelector(".flex-sidebar").classList.remove("hidden");
    },
 */  },
  computed: {
    ...mapGetters({
      beepruser: "beepruser",
      client: "client",
      clientData: "clientData",
    }),
  },
  mounted() {
    document.getElementById('nav-toggle').onclick = function(){
			document.getElementById("nav-content").classList.toggle("hidden");
		}
  }
};


</script>
