<template>

  <div class="bg-white p-5 w-full rounded-md box-border shadow">
    <div v-if="!series || series.length == 0" class="flex flex-wrap p-4" style="height: 380px">
      <base-progress :percentage="contentProgress" class="mx-2 mb-2" indeterminate></base-progress>
    </div>

    <apexchart
      :key="`seg-${ean}`"
      v-if="series && series.length > 0"
      type="line"
      height="280"
      :options="optionsArea"
      :series="series"
      :labels="labels"
    ></apexchart>
  </div>
</template>

<script>
// @ is an alias to /src
import { Icon } from "@iconify/vue";
import BaseProgress from "./BaseProgress.vue";

//import { mapGetters } from "vuex";

export default {
  name: "SegmentChart",
  props: {
    isOpen: Object,
    labels: Array,
    series: Array,
    ean: String,
    name: String,
  },
  mounted() {},

/*    watch: {
    name: function (newVal, oldVal) {
      //console.log(newVal);
      this.optionsArea = {
        ...this.optionsArea,
        ...{
          title: {
            text: newVal
          }
        },
      };
    },

    labels: function (newVal, oldVal) {
      //console.log(newVal);
      this.optionsArea = {
        ...this.optionsArea,
        ...{
          xaxis: {
          categories: newVal,
        },
        },
      };
    },
 

   series: function (newVal, oldVal) {
    //console.log(newVal);

      this.optionsArea = {
        ...this.optionsArea,
        ...{
          series: newVal,
        },
      };
    },    
  },
 */  
  data() {
    return {
      contentProgress: 20,

      // chart data area
      optionsArea: {
        chart: {
          height: 280,
          type: 'line',
          fontFamily: "Quicksand, sans-serif",
        },
        colors: [
        "#3CB44B",
          "#4363D8",
          "#911EB4",
          "#FABED4",
          "#FFD8B1",
          "#469990",
          "#AAFFC3",
          "#000000",
          "#DCBEFF",
          "#9A6324",
          "#808000",
          "#FFFAC8",
        ],
        title: {
          text: this.name
        },
        yaxis: [
          { 
            categories: this.labels,
            labels: {
              formatter: function (val) {
                return Math.round(val)
              },
              style: {
                fontSize: "10px"
              }
            }
          },
          { 
            opposite: true,
            categories: this.labels,
            labels: {
              formatter: function (val) {
                return val.toString().replace(/\./, ',') + " kr."
              },
              style: {
                fontSize: "10px"
              }
            }
          },
        ],
        xaxis: {
          categories: this.labels,
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: '12px',
          },
          formatter: function (val, {seriesIndex}) {
             if (seriesIndex == 0) {
              return Math.round(val)
            } else {
              return (val && typeof(val) == 'number') ? Intl.NumberFormat("da-DK", { style:'currency', currency:"DKK" }).format(val) : val;
            }
               
            }
            // 
            //},
        },
        tooltip: {
          y: [
            {
            formatter: function (val) {
              return val
            },
            //title: {
            //  formatter: function (seriesName) {
            //    return "Number of products: "
            //  }
            //},
          },
          {
            formatter: function (val) {
              return (val && typeof(val) == 'number') ? Intl.NumberFormat("da-DK", { type:'currency'}).format(val) + " kr." : val;
            },
          }          
        ],
       },
       fontFamily: "Quicksand, sans-serif",
      },

    };
    // end chart data line
  },
  components: {
    Icon,
    BaseProgress,
  },
};
</script>
