<template>
  <div v-show="ean && isOpen[ean]" class="fixed z-30 inset-0 overflow-y-auto bg-black bg-opacity-50">
    <div class="flex items-start justify-center min-h-screen pt-2 text-center w-full">

      <div class="bg-white rounded-lg text-left overflow-hidden shadow-xl p-8 w-11/12">

        <h2 class="float-left font-bold text-lg text-gray-800 dark:text-gray-200">Price Development</h2>

        <button type="button" @click="closemodal()"
          class="float-right text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm mb-3">
          <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"></path>
          </svg>
        </button>


        <div v-if="product && product.Name"
          class="w-full md:w-1/2 float-left clear-both bg-white h-48 rounded-md box-border shadow">
          <div class="outer-wrapper float-left pt-5">
            <div class="frame">
              <a target="_blank" :href="'https://beepr.' + country + '/products/' +
                product.ID +
                '/' +
                product.Name
                  .toLowerCase()
                  .trim()
                  .replace(/\./g, '')
                  .replace(/ø/g, 'oe')
                  .replace(/æ/g, 'ae')
                  .replace(/å/g, 'aa')
                  .replace(/[^a-z0-9]+/g, '-')
              ">
                <img class="product-img" :src="product ? getImageByEAN(product.ID, '300') : ''" alt="Image not found"
                  style="max-height: 120px"
                  onerror="this.src='https://firebasestorage.googleapis.com/v0/b/beepr-9cfab.appspot.com/o/products%2Fempty.png?alt=media&token=b1d2052b-ea43-4ba3-b315-81d55601ddae';" />
              </a>
            </div>
          </div>

          <p class="pt-12">{{ (product.ImportName) ? product.ImportName : product.Name }} &dash; {{
            formatNumberDec(product.Units, 2, 2)
          }} {{
  product.Uom
}}</p>
          <br />
          <span style="font-size: medium; color:gray">EAN: {{ product.ID }}</span>
          <br />
          <span v-if="(product.Path && product.Path.length > 5)" style="font-size: medium; color:gray">Category: {{
            product.Path
          }}</span>
        </div>

        <div class="w-full md:w-5/12 float-right bg-white rounded-md box-border shadow py-5 pl-5 mr-5 wrapping-table"
          style="display: grid; max-height: 24rem;">
          <div class="h-10 font-bold" style="font-family: Helvetica, Arial, sans-serif; font-size: 14px; color: black">
            Today's Prices</div>
          <div style="display: grid; overflow-y: auto; max-height: 20rem;">
            <table v-if="product.Prices">
              <tbody style="vertical-align: top">
                <div class="table-row" style="height: 40px"
                  v-for="c in Object.keys(product.Prices).sort(function (a, b) { return product.Prices[a].Price - product.Prices[b].Price })"
                  :key="c">
                  <div class="table-cell px-2 pt-1">
                    <img :src="require('../assets/img/' + c + '.webp')" width="28" style="float: left" class="ml-1"
                      alt="" />
                  </div>
                  <div class="table-cell align-top pt-2 ">
                    <a target="_blank" :href="product.Prices[c].Deeplink">{{ c[0].toUpperCase() + c.slice(1) }}</a>
                  </div>

                  <div class="table-cell align-top pt-2 line-through text-red-500 text-right"
                    v-if="product.Prices[c].Discount.Saved > 0 && product.Prices[c].Discount.MinQuantity < 2">
                    {{ formatNumberDec(product.Prices[c].Price + product.Prices[c].Discount.Saved, 2, 2) }} kr.
                  </div>
                  <div class="table-cell align-top pt-2 text-green-500 text-right"
                    v-else-if="product.Prices[c].Discount.MinQuantity > 1">
                    {{
                      product.Prices[c].Discount.MinQuantity + ' for '
                        + formatNumberDec(product.Prices[c].Discount.MinQuantity *
                          (product.Prices[c].Price - product.Prices[c].Discount.SavedPerItem), 2, 2)
                    }} kr.
                  </div>
                  <div class="table-cell align-top pt-2 text-grey-500 text-right"
                    v-else-if="product.Prices[c].Brochure && product.Prices[c].OfferFrom">
                    {{ product.Prices[c].OfferFrom + ' - ' + product.Prices[c].OfferUntil }}
                  </div>
                  <div class="table-cell" v-else></div>

                  <div class="table-cell align-top pt-2 pr-5 text-right">
                    {{ formatNumberDec(product.Prices[c].Price, 2, 2) }} kr.
                  </div>
                </div>
              </tbody>
            </table>
          </div>
        </div>

        <div v-if="highest > 0"
          class="w-full md:w-1/2 float-left bg-white h-44 rounded-md box-border shadow pt-5 px-12 mr-5 mt-4"
          style="display: grid">
          <div class="todays-price-cn -active pt-5">
            <div class="todays-price">
              <div class="figure-wrapper">
                <div class="fig -min">
                  <div>Lowest<br>{{ formatNumberDec(lowest, 2, 2) }} kr.</div>
                </div>
                <div class="fig-above"
                  :style="'left: calc(' + (((usually - lowest) / (highest - lowest) * 100)) + '% - 60px)'">
                  <div>Usually <br />{{ formatNumberDec(usually, 2, 2) }} kr.</div>
                </div>
                <div class="fig -max">
                  <div>Highest<br>{{ formatNumberDec(highest, 2, 2) }} kr.</div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <Multiselect v-if="chainsWithPrice.length > 0 && highest > 0" class="w-full md:w-1/2 mt-5 float-left"
          v-model="selectedChains" placeholder="Filter chain" :options="chainsWithPrice" />

        <Multiselect v-if="highest > 0" class="w-full md:w-5/12 mt-5 float-right mr-5" v-model="selectedPriceCutoff"
          placeholder="Price Drops larger than" :options="priceCutoff" />

        <div v-if="highest > 0" class="">
          <div class="w-full md:w-1/2 float-left clear-both my-5 mr-5">
            <barchart v-if="barseries" :labels="barlabels" :series="barseries" :ean="ean" :name="title"
              :key="`pl-${ean}`" />
          </div>

          <div class="w-full md:w-5/12 float-right my-5 mr-5">
            <pricedrop v-if="(dropseries && dropseries[selectedPriceCutoff])" :labels="droplabels[selectedPriceCutoff]"
              :series="dropseries[selectedPriceCutoff]" :ean="ean" :key="`pd-${ean}`"
              :name="'Price Drops > ' + selectedPriceCutoff + ' %'" />
          </div>
        </div>

        <div v-if="highest > 0" class="w-full clear-both">
          <chart v-if="series" :labels="labels" :series="series" :ean="ean" :ticks="ticks" />
        </div>

      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { Icon } from "@iconify/vue";
import { mapGetters } from "vuex";
import Chart from "./components/chart.vue";
import Barchart from "./components/barchart.vue";
import Pricedrop from "./components/pricedrop.vue";
import Multiselect from "@vueform/multiselect";
import { analytics } from '@/firebaseDb';

export default {
  name: "ProductModal",
  props: {
    isOpen: Object,
    ean: String,
    name: String,
  },
  data() {
    return {
      title: "All Chains",
      //ean: "",
      chains: [],
      selectedChains: [],
      chainsWithPrice: [],
      labels: [],
      series: [],
      barlabels: [],
      barseries: [],
      droplabels: {},
      dropseries: {},
      priceCutoff: [0, 10, 20, 50],
      selectedPriceCutoff: 10,
      lowest: 0,
      usually: 0,
      highest: 0,
      ticks: 0
    };
  },
  components: {
    Icon,
    Chart,
    Barchart,
    Pricedrop,
    Multiselect,
  },
  watch: {
    isOpen(newData, old) {
      if (Object.keys(newData)[0] == this.ean) {
        //console.log(this.ean)
        //console.log(newData)
        analytics.logEvent("view_item", {
          ean: Object.keys(newData)[0],
        });

        this.$store.dispatch("setProduct", Object.keys(newData)[0]);
        this.getPrices(Object.keys(newData)[0], this.allchains).then((o) => (this.series = o));
      }
    },
    selectedChains(newData, old) {
      let chains = "";
      if (newData) {
        chains = newData;
        //} else {
        //  chains = this.allchains;
      }

      this.series = [];
      this.labels = [];
      this.barseries = [];
      this.barlabels = [];

      var dateObj = new Date(
        new Date().getFullYear(),
        new Date().getMonth() - 12,
        new Date().getDate()
      );
      var day = ("0" + dateObj.getDate()).slice(-2);
      var month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
      var year = dateObj.getFullYear();
      let date = year + "-" + month + "-" + day;

      let output = [];
      let tmpobj = [];
      let lastDate = {};
      let lastPrice = {};
      let barObject = [];
      Object.keys(this.productprices["prices"])
        .sort()
        .forEach((key) => {
          Object.keys(this.productprices["prices"][key]).forEach((key2) => {
            //if (!key2.includes("Normal") && !key2.includes("MinQ") && !key2.includes("Multi") && !key2.includes("Offer") && this.productprices["prices"][key]["X"] > date) {
              if (this.productprices["prices"][key]["X"] > date) {
              //(tmpobj[key2] = tmpobj[key2] || []).push(this.productprices["prices"][key][key2]);
              let tmp = new Date(this.productprices["prices"][key]["X"].replaceAll("-", ".")).getTime();

              (tmpobj[key2] = tmpobj[key2] || []).push( {x: tmp , y:this.productprices["prices"][key][key2]});

              let Difference_In_Time, Difference_In_Days, date1, date2;
              if (key2 != "X" && this.productprices["prices"][key][key2] > -1) {
                if (lastDate[key2]) {
                  date1 = new Date(lastDate[key2]);
                  date2 = new Date(this.productprices["prices"][key]["X"]);
                  // To calculate the time difference of two dates
                  Difference_In_Time = date2.getTime() - date1.getTime();
                  // To calculate the no. of days between two dates
                  Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
                  lastDate[key2] = this.productprices["prices"][key]["X"];
                } else {
                  date1 = new Date(date);
                  date2 = new Date(this.productprices["prices"][key]["X"]);
                  // To calculate the time difference of two dates
                  Difference_In_Time = date2.getTime() - date1.getTime();
                  // To calculate the no. of days between two dates
                  Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
                  lastDate[key2] = this.productprices["prices"][key]["X"];

                  //lastPrice[key2] = "2 for " +this.productprices["prices"][key][key2];
                  lastPrice[key2] = this.productprices["prices"][key][key2];
                }

                /*                 if (key2 == "Nemlig" && lastPrice[key2] == "6"){
                                  console.log(key2 + " : " + date1 + " : " + date2 + " : " + Difference_In_Days)
                                }
                 */
                //if(lastPrice[key2].includes("for 0")) {
                if (lastPrice[key2] > 0) {
                  barObject[key2] = barObject[key2] || [];
                  barObject["All Chains"] = barObject["All Chains"] || [];

                  barObject[key2][lastPrice[key2]] = barObject[key2][lastPrice[key2]] + Difference_In_Days || Difference_In_Days;
                  barObject["All Chains"][lastPrice[key2]] = barObject["All Chains"][lastPrice[key2]] + Difference_In_Days || Difference_In_Days;
                }
                //lastPrice[key2] = "2 for " +this.productprices["prices"][key][key2];
                lastPrice[key2] = this.productprices["prices"][key][key2];
              }
            }
          });
        });

      let tmpArr = [];
      if (chains == "") {
        this.title = "All Chains";
      } else {
        this.title = (chains == "føtex") ? "Føtex" : chains[0].toUpperCase() + chains.slice(1);
        //console.log(this.title)
      }
      /*         Object.keys(barObject)
                .sort()
                .forEach((key) => {
       */
      let lastMax = 0;
      this.lowest = 0;
      console.log(barObject);

      if (barObject[this.title]) {
        Object.keys(barObject[this.title])
          .sort((a, b) => a - b)
          .forEach((key2) => {
            this.highest = key2;
            if (this.lowest == 0) { this.lowest = key2 };
            if (barObject[this.title][key2] > lastMax) {
              lastMax = barObject[this.title][key2];
              this.usually = key2;
            }

            //let chain = key == "Føtex" ? "føtexhome" : key.toLocaleLowerCase();
            //if ((key != "Postme" && chains.includes(chain)) || chains.length == 0) {
            //let tmpArr = Object.values(out["data"]).filter((v) => v > 0);
            tmpArr.push(barObject[this.title][key2]);
            this.barlabels.push(key2);
            //}
          });
        //});
      }
      let out = { data: tmpArr };
      this.barseries.push(out);
      //console.log(this.barlabels);
      //console.log(this.barseries);


      let sum = 0;
      let sumCount = 0;

      Object.keys(tmpobj)
        .sort()
        .forEach((key) => {
          let out = { name: key, data: tmpobj[key] };
          //let chain = key == "Føtex" ? "fotexhome" : key.toLocaleLowerCase();
          let chain = key;
          //console.log(key);
          //console.log(chain);
          //console.log(chains);

          if (key == "X") {
            this.labels = tmpobj[key];
            this.ticks = tmpobj[key].length -1;
          } else if ((key != "Postme" && chains.includes(chain)) || chains.length == 0) {
            let tmpArr = Object.values(out["data"]).filter((v) => v.y > 0);
            sum += tmpArr.reduce((partialSum, a) => partialSum + a.y, 0);
            sumCount += tmpArr.reduce((partialSum, a) => partialSum + 1, 0);
            output.push(out);
          }
        });

      //console.log(sum + " : " + sumCount);
      if (sumCount > 0) {
        let avgArr = [];
        let avg = sum / sumCount;

        for (let index = 0; index < this.labels.length; index++) {
          avgArr[index] = { x:this.labels[index].x , y:avg };
        }
        let out = { name: "AVG", data: avgArr };
        output.push(out);
      }

      this.series = output;
      //console.log(this.series);
    },
  },

  methods: {
    async getPrices(ean, chains) {
      if (this.selectedChains && this.selectedChains.length > 0) {
        chains = this.selectedChains;
      }
      this.labels = [];
      var dateObj = new Date(
        new Date().getFullYear(),
        new Date().getMonth() - 12,
        new Date().getDate()
      );
      var day = ("0" + dateObj.getDate()).slice(-2);
      var month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
      var year = dateObj.getFullYear();
      let date = year + "-" + month + "-" + day;

      return new Promise((resolve, reject) => {
        this.$store.dispatch("setProductPrices", ean).then(() => {
          let output = [];
          let tmpobj = [];
          let barObject = {};
          let priceDrop = {};
          let lastDate = {};
          let lastPrice = {};
          //console.log(this.productprices["prices"])
          Object.keys(this.productprices["prices"])
            .sort()
            .forEach((key) => {
              Object.keys(this.productprices["prices"][key]).forEach((key2) => {

                if (this.productprices["prices"][key]["X"] > date) {
                  //console.log(this.productprices["prices"][key]["X"]);
                  let tmp = new Date(this.productprices["prices"][key]["X"].replaceAll("-", ".")).getTime();
                  //console.log(this.allchains);
                  //(tmpobj[key2] = tmpobj[key2] || []).push(this.productprices["prices"][key][key2]);

                  (tmpobj[key2] = tmpobj[key2] || []).push( {x: tmp , y:this.productprices["prices"][key][key2]});

                  let Difference_In_Time, Difference_In_Days;
                  //if (key2 != "X" && this.allchains.includes(key2.toLowerCase()) && !key2.includes("Normal") && !key2.includes("MinQ") && !key2.includes("Multi") && !key2.includes("Offer") && this.productprices["prices"][key][key2] > -1) {
                    if (key2 != "X" && this.productprices["prices"][key][key2] > -1) {
                    if (lastDate[key2]) {
                      var date1 = new Date(lastDate[key2]);
                      var date2 = new Date(this.productprices["prices"][key]["X"]);
                      //console.log(date1 + " : " + date2);
                      // To calculate the time difference of two dates
                      Difference_In_Time = date2.getTime() - date1.getTime();
                      // To calculate the no. of days between two dates
                      Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
                      lastDate[key2] = this.productprices["prices"][key]["X"];

                      if (this.productprices["prices"][key][key2] < lastPrice[key2] && this.productprices["prices"][key][key2] > 0) {
                        priceDrop[0] = priceDrop[0] || {};
                        priceDrop[10] = priceDrop[10] || {};
                        priceDrop[20] = priceDrop[20] || {};
                        priceDrop[50] = priceDrop[50] || {};
                        priceDrop[0][key2] = priceDrop[0][key2] + 1 || 1;
                        if ((this.productprices["prices"][key][key2] / lastPrice[key2]) < 0.9) {
                          priceDrop[10][key2] = priceDrop[10][key2] + 1 || 1;
                        }
                        if ((this.productprices["prices"][key][key2] / lastPrice[key2]) < 0.8) {
                          priceDrop[20][key2] = priceDrop[20][key2] + 1 || 1;
                        }
                        if ((this.productprices["prices"][key][key2] / lastPrice[key2]) < 0.5) {
                          priceDrop[50][key2] = priceDrop[50][key2] + 1 || 1;
                        }
                      }
                    } else {
                      //var date1 = new Date(date);
                      //var date2 = new Date(this.productprices["prices"][key]["X"]);
                      // To calculate the time difference of two dates
                      //Difference_In_Time = date2.getTime() - date1.getTime();
                      // To calculate the no. of days between two dates
                      //Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
                      lastDate[key2] = this.productprices["prices"][key]["X"];
                      lastPrice[key2] = this.productprices["prices"][key][key2];
                    }

                    if (lastPrice[key2] > 0) {
                      barObject[key2] = barObject[key2] || [];
                      barObject["All Chains"] = barObject["All Chains"] || {};
                      barObject[key2][lastPrice[key2]] = barObject[key2][lastPrice[key2]] + Difference_In_Days || Difference_In_Days;
                      barObject["All Chains"][lastPrice[key2]] = barObject["All Chains"][lastPrice[key2]] + Difference_In_Days || Difference_In_Days;
                      barObject["SUM"] = barObject["SUM"] + Difference_In_Days || Difference_In_Days;
                    }

                    lastPrice[key2] = this.productprices["prices"][key][key2];

                  }
                }
              });
            });
            //console.log(barObject)
          this.chainsWithPrice = Object.keys(barObject).sort().filter(name => name != "SUM" && name != "All Chains");
          let tmpArr = [];
          let lastMax = 0;
          if (barObject["All Chains"]) {
            Object.keys(barObject["All Chains"])
              .sort((a, b) => a - b)
              .forEach((key2) => {
                this.highest = key2;
                if (this.lowest == 0) { this.lowest = key2 };
                if (barObject["All Chains"][key2] > lastMax) {
                  lastMax = barObject["All Chains"][key2];
                  this.usually = key2;
                }
                //let per = Number.parseFloat((barObject["All Chains"][key2]/barObject["SUM"])*100.00).toLocaleString("da-DK", {maximumFractionDigits: 2, minimumFractionDigits: 2,});
                tmpArr.push(barObject["All Chains"][key2]);
                this.barlabels.push(key2);
              });
          }

          let out = { data: tmpArr };
          this.barseries.push(out);

          //console.log(this.barseries);
          //console.log(this.barlabels);

          let sum = 0;
          let sumCount = 0;

          Object.keys(tmpobj)
            .sort()
            .forEach((key) => {
              //console.log(key);
              //console.log(chains);
              let out = { name: key, data: tmpobj[key] };
              let chain = key == "Føtex" ? "føtex" : key.toLocaleLowerCase();
              //console.log(chain);
              //console.log(chains)
              if (key == "X") {
                this.labels = tmpobj[key];
                this.ticks = tmpobj[key].length -1;
              } else if ((key != "Postme" && chains.includes(chain)) || chains.length == 0) {
                let tmpArr = Object.values(out["data"]).filter((v) => v.y > 0);
                sum += tmpArr.reduce((partialSum, a) => partialSum + a.y, 0);
                sumCount += tmpArr.reduce((partialSum, a) => partialSum + 1, 0);
                output.push(out);
              }
            });

          if (sumCount > 0) {
            let avgArr = [];
            let avg = sum / sumCount;
            for (let index = 0; index < this.labels.length; index++) {
              avgArr[index] = { x:this.labels[index].x , y:avg };
            }
            let out = { name: "AVG", data: avgArr };
            output.push(out);
          }
          //this.dropseries = priceDrop;
          //console.log(priceDrop)
          Object.keys(priceDrop)
            .forEach((key) => {
              tmpArr = [];
              //console.log(priceDrop[key])
              Object.keys(priceDrop[key])
                .sort()
                .forEach((key2) => {
                  tmpArr.push(priceDrop[key][key2]);
                  (this.droplabels[key] = this.droplabels[key] || []).push(key2);
                });
              out = { data: tmpArr };
              (this.dropseries[key] = this.dropseries[key] || []).push(out);
            });
          //console.log(this.dropseries)
          //console.log(sumCount)
          //console.log(sum)
          //console.log(output)
          resolve(output);
        });
      });
    },
    filterChains(chains) {
      return chains.filter((a) => a !== "postme");
    },
    formatNumberDec(value, min, max) {
      return Number.parseFloat(value).toLocaleString("da-DK", {
        maximumFractionDigits: max,
        minimumFractionDigits: min,
      });
    },
    getImageByEAN(ean, width) {
      let bucket = "beepr-9cfab"
      if (this.country != "dk") {
        bucket = "beepr-" + this.country
      }

      if (ean != null) {
        return "https://firebasestorage.googleapis.com/v0/b/" + bucket + ".appspot.com/o/products%2F" +
          ean +
          ".jpg?alt=media&token=b1d2052b-ea43-4ba3-b315-81d55601ddae";
        //return "https://beepr-9cfab.firebaseapp.com/cdn/image/width=" + width + "/" + ean + ".jpg";
      } else {
        //return "https://beepr-9cfab.firebaseapp.com/cdn/image/width=" + width + "/empty.png";
        return "https://firebasestorage.googleapis.com/v0/b/" + bucket + ".appspot.com/o/products%2Fempty.png?alt=media&token=b1d2052b-ea43-4ba3-b315-81d55601ddae";
      }
    },
    closemodal() {
      this.chains = [];
      this.chainsWithPrice = [];
      this.labels = [];
      this.series = [];
      this.barlabels = [];
      this.barseries = [];
      this.droplabels = {};
      this.dropseries = {};
      this.lowest = 0;
      this.usually = 0;
      this.highest = 0

      this.$emit("closemodal", false);
    },
  },
  computed: {
    ...mapGetters({
      country: "country",
      beepruser: "beepruser",
      client: "client",
      clientData: "clientData",
      currYear: "currYear",
      currMon: "currMon",
      productprices: "productprices",
      product: "product",
      allchains: "allchains",
    }),
  },
  mounted() {
    //console.log("Test: " +this.ean);
    //if (this.$route.query.ean && this.$route.query.ean.length > 0) {
    //if (this.ean && this.ean.length > 0) {
    //this.ean = this.$route.query.ean;
    //this.$store.dispatch("setProduct", this.ean);
    //this.getPrices(this.ean, this.allchains).then((o) => (this.series = o));
    //}
  },
};
</script>

<style>
.outer-wrapper {
  display: inline-block;
  margin: 10px;
}

.frame {
  width: 120px;
  height: 120px;
  vertical-align: middle;
  text-align: center;
  display: table-cell;
}

.pricebar {
  font-family: inherit;
  font-weight: 400;
  text-decoration: none;
  color: #000;
  line-height: 130%;
  font-size: 1.4rem;
  box-sizing: border-box;
  padding: 15px;
  display: block;
  margin: 0;
}

.todays-price-cn.-active {
  display: block;
  margin: 0 10px;
}

.todays-price {
  width: 100%;
  background: #000;
  background: linear-gradient(90deg, green 0%, orange 27.777777777778%, red 100%);
  height: 10px;
  position: relative;
  margin: 50px 0;
}

.todays-price .fig.-min {
  left: 0;
}

.todays-price .fig {
  position: absolute;
  top: -25px;
  border-left: 1px dashed;
  border-color: #979797;
  padding: 4px;
  padding-top: 40px;
}

.todays-price .fig-above {
  position: absolute;
  top: -46px;
  border-right: 1px dashed;
  border-left: none;
  border-color: #979797;
  padding-right: 4px;
  padding-top: 0px;
  height: 80px;
}

.todays-price .figure-wrapper>div:last-child {
  border-right: 1px dashed;
  border-left: none;
  left: auto !important;
  right: 0;
}


.todays-price .pointer-pos {
  position: absolute;
  bottom: 23px;
}

.todays-price .pointer-fig {
  background: #282828;
  position: relative;
  font-size: 1.1rem;
  color: #fff;
  padding: 10px 0;
  border-radius: 10px;
  width: 50px;
  text-align: center;
  font-weight: 500;
  letter-spacing: 1px;
}
</style>

<style src="@vueform/multiselect/themes/default.css">

</style>
