<template>

  <div class="bg-white p-5 w-full rounded-md box-border shadow">
    <div v-if="!series || series.length == 0" class="flex flex-wrap p-4" style="height: 380px">
      <base-progress :percentage="contentProgress" class="mx-2 mb-2" indeterminate></base-progress>
    </div>

    <apexchart
      :key="`plg-${ean}`"
      v-if="series && series.length > 0"
      type="bar"
      height="380"
      :options="optionsArea"
      :series="series"
      :labels="labels"
    ></apexchart>
  </div>
</template>

<script>
// @ is an alias to /src
import { Icon } from "@iconify/vue";
import BaseProgress from "./BaseProgress.vue";

//import { mapGetters } from "vuex";

export default {
  name: "BarChart",
  props: {
    isOpen: Object,
    labels: Array,
    series: Array,
    ean: String,
    name: String,
  },
  mounted() {},

   watch: {
    name: function (newVal, oldVal) {
      //console.log(newVal);
      this.optionsArea = {
        ...this.optionsArea,
        ...{
          title: {
            text: newVal
          }
        },
      };
    },

    labels: function (newVal, oldVal) {
      //console.log(newVal);
      this.optionsArea = {
        ...this.optionsArea,
        ...{
          xaxis: {
          categories: newVal,
/*           labels: {
            formatter: function (val) {
              return val + " kkr."
            },
          }
 */        },
        },
      };
    },
 

   series: function (newVal, oldVal) {
    //console.log(newVal);

      this.optionsArea = {
        ...this.optionsArea,
        ...{
          series: newVal,
/*           yaxis: {
          //categories: newVal,
          labels: {
            formatter: function (val) {
              return val + " kr."
            }
          }
        },
 */        },
      };
    },    
  },
  data() {
    return {
      contentProgress: 20,

      // chart data area
      optionsArea: {
        //series: this.series,
        //labels: this.labels,
        colors: [
        "#3CB44B",
          "#800000",
          "#000075",
          "#F032E6",
          "#E6194B",
          "#F58231",
          "#A9A9A9",
          "#FFE119",
          "#BFEF45",
          "#42D4F4",
          "#4363D8",
          "#911EB4",
          "#FABED4",
          "#FFD8B1",
          "#469990",
          "#AAFFC3",
          "#000000",
          "#DCBEFF",
          "#9A6324",
          "#808000",
          "#FFFAC8",
        ],
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
          }
        },
        //dataLabels: {
        //  enabled: false
        //},
        title: {
          text: this.name
        },
        yaxis: {
          categories: this.labels,
          labels: {
            //formatter: function (val) {
            //  return val.toString().replace(/\./, ',') + " kr."
            //},
            style: {
              fontSize: "12px"
            }
          }
        },
        xaxis: {
          categories: this.labels,
          labels: {
            formatter: function (val) {
              return val + " days"
            }
          }
        },
/*         series: {
          xaxis: {
            formatter: function(value, opts) {
              const sum = opts.series[0].reduce((a, b) => a + b, 0);
              const percent = (value / sum) * 100;
              return percent.toFixed(0) + '%'
            },
          },
          },
 */        dataLabels: {
          enabled: true,
          offsetX: -6,
          style: {
            fontSize: '12px',
            colors: ['#fff']
          }
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " days"
            },
            title: {
        formatter: function (seriesName) {
          return "Period"
        }
          },
      }
        },
/*         tooltip: {
          enabled: false,
        },
 */        fontFamily: "Quicksand, sans-serif",
      },

      chart: {
        fontFamily: "Quicksand, sans-serif",
      },
    };
    // end chart data line
  },
  components: {
    Icon,
    BaseProgress,
  },
};
</script>
