import { initializeApp } from "firebase/app";
import { getFirestore, doc, setDoc, getDoc } from "firebase/firestore";
import { getAnalytics, logEvent, setCurrentScreen, setUserId, setUserProperties } from "firebase/analytics"
import {
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  signOut,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  //onAuthStateChanged,
} from "firebase/auth";

import store from "./store/store";
import router from "./router";

let firebaseConfig = {}
let pfirebaseConfig = {}

if (window && window.location && window.location.hostname && window.location.hostname == "client.beepr.se") {
  store.commit("SET_COUNTRY", "se")
  firebaseConfig = {
    apiKey: "AIzaSyD6JCqr_6YVrlxpX2qRPyciQheukITaAf4",
    authDomain: "beepr-client-se.firebaseapp.com",
    projectId: "beepr-client-se",
    storageBucket: "beepr-client-se.appspot.com",
    messagingSenderId: "391720414729",
    appId: "1:391720414729:web:13da9008cb9dfe6c8512d8",
    measurementId: "G-Z00PB1ELFK"
  };
  pfirebaseConfig = {
    apiKey: "AIzaSyAM2XNrofMcVBaRGjMQkGE0-W5i1U0sIEY",
    authDomain: "beepr-se.firebaseapp.com",
    projectId: "beepr-se",
    storageBucket: "beepr-se.appspot.com",
    messagingSenderId: "391482439066",
    appId: "1:391482439066:web:908f503a3e87e16bd5b623",
    measurementId: "G-Q45GZXXWB5"
  };
} else if (window && window.location && window.location.hostname && window.location.hostname == "client.beepr.no") {
  store.commit("SET_COUNTRY", "no")
  firebaseConfig = {
    apiKey: "AIzaSyBFEpoKDwgUWynTRJ2Fit86zLE7M3HOpWo",
    authDomain: "beepr-client-no.firebaseapp.com",
    projectId: "beepr-client-no",
    storageBucket: "beepr-client-no.appspot.com",
    messagingSenderId: "247578858878",
    appId: "1:247578858878:web:a16aa4593ffa6599e34f2f",
    measurementId: "G-0D899JFMN6"
  };
  pfirebaseConfig = {
    apiKey: "AIzaSyAn04rF4QmlWuYdBDALdTmILtPL_k0YzFk",
    authDomain: "beepr-no.firebaseapp.com",
    projectId: "beepr-no",
    storageBucket: "beepr-no.appspot.com",
    messagingSenderId: "485055288234",
    appId: "1:485055288234:web:5c7bcdd2f717231d9c11d3",
    measurementId: "G-LND6BBYKC3"
  };
 } else if(window && window.location && window.location.hostname && window.location.hostname == "localhost") {
  firebaseConfig = {
    apiKey: "AIzaSyD-4kD2cA6sQ-ew1WjkuGod_C4fuyRRohc",
    authDomain: "beepr-client.firebaseapp.com",
    projectId: "beepr-client",
    storageBucket: "beepr-client.appspot.com",
    messagingSenderId: "146147973446",
    appId: "1:146147973446:web:b4013f4616f760fcedad47",
    measurementId: "G-WKFT2LTZWS"
  };

  pfirebaseConfig = {
    apiKey: "AIzaSyDoDlGCCfYJGz-dl2POI4CJi66wNU6mhPc",
    authDomain: "beepr-9cfab.firebaseapp.com",
    databaseURL: "https://beepr-9cfab.firebaseio.com",
    projectId: "beepr-9cfab",
    storageBucket: "beepr-9cfab.appspot.com",
    messagingSenderId: "289470049594",
    appId: "1:289470049594:web:9c6ccc1248cd4704bdb403",
  };

} else {
  firebaseConfig = {
    apiKey: "AIzaSyD-4kD2cA6sQ-ew1WjkuGod_C4fuyRRohc",
    authDomain: "beepr-client.firebaseapp.com",
    projectId: "beepr-client",
    storageBucket: "beepr-client.appspot.com",
    messagingSenderId: "146147973446",
    appId: "1:146147973446:web:b4013f4616f760fcedad47",
    measurementId: "G-WKFT2LTZWS"
  };

  pfirebaseConfig = {
    apiKey: "AIzaSyDoDlGCCfYJGz-dl2POI4CJi66wNU6mhPc",
    authDomain: "beepr-9cfab.firebaseapp.com",
    databaseURL: "https://beepr-9cfab.firebaseio.com",
    projectId: "beepr-9cfab",
    storageBucket: "beepr-9cfab.appspot.com",
    messagingSenderId: "289470049594",
    appId: "1:289470049594:web:9c6ccc1248cd4704bdb403",
  };
}

const firebaseApp = initializeApp(firebaseConfig);
const pfirebaseApp = initializeApp(pfirebaseConfig, "products");

const provider = new GoogleAuthProvider();

const theauth = getAuth(firebaseApp);
export const db = getFirestore(firebaseApp);
export const pdb = getFirestore(pfirebaseApp);
const theanalytics = getAnalytics(firebaseApp);

export const analytics = {
  analytics: theanalytics,
  setUserId(id) {
    setUserId(theanalytics, id);
  },
  setUserProperties(props) {
    setUserProperties(theanalytics, props)
  },
  logEvent(name, params) {
    logEvent(theanalytics, name, params);
  },
  setCurrentScreen(name){
    setCurrentScreen(theanalytics, name);
  }
}

export const auth = {
  auth: theauth,
  signUpWithEmail(name, email, password) {
    return new Promise((resolve, reject) => {
      createUserWithEmailAndPassword(theauth, email, password)
        .then((res) => {
          //New User Document
          const docRef = doc(db, "users", res.user.uid);

          setDoc(docRef, {
            Navn: name,
            Email: email,
            DefaultShoppingList: res.user.uid,
          })
            .then(() => {
              store
                .dispatch("fetchUser", res.user)
                .then((beepruser) => {
                  if (beepruser) {
                    resolve("ok");
                  }
                })
                .catch((error) => {
                  console.log("1:" + error.message);
                  resolve(error.message);
                });
            })
            .catch((error) => {
              console.log("2:" + error.message);
              resolve(error.message);
            });
        })
        .catch((error) => {
          console.log("3:" + error.message);
          resolve(error.message);
        });
    });
  },
  login() {
    signInWithPopup(theauth, provider)
      .then((res) => {
        const docRef = doc(db, "users", res.user.uid);
        getDoc(docRef).then((docSnap) => {
          if (docSnap.exists()) {
            store
              .dispatch("fetchUser", res.user)
              .then((beepruser) => {
                if (beepruser) {
                  router.push({ name: "home" }).catch((err) => {
                    throw new Error(`Problem handling router push: ${err}.`);
                  });
                }
              })
              .catch((error) => {
                console.log(error.message);
              });
          } else {
            setDoc(docRef, {
              name: res.user.displayName,
              email: res.user.email,
              client: "ota",
            }).then(() => {
              store
                .dispatch("fetchUser", res.user)
                .then((beepruser) => {
                  if (beepruser) {
                    router.push({ name: "home" }).catch((err) => {
                      throw new Error(`Problem handling router push: ${err}.`);
                    });
                  }
                })
                .catch((error) => {
                  console.log(error.message);
                });
            });
          }
        });
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        console.log(errorCode, errorMessage, email, credential);
      });
  },
  loginEmail(email, password) {
    return new Promise((resolve, reject) => {
      signInWithEmailAndPassword(theauth, email, password)
        .then((res) => {
          //store.commit("SET_USER", res.user);
          store.dispatch("fetchUser", res.user).then((beepruser) => {
            if (beepruser) {
              //console.log(beepruser);
              //if(beepruser.data && beepruser.data.client && beepruser.data.client == "beepr") {
              //router.push({name:"report"}).catch((err) => {
              //  throw new Error(`Problem handling router push: ${err}.`);
              //});
              resolve("ok");
              //} else {
              //router.push({name:"home"}).catch((err) => {
              //  throw new Error(`Problem handling router push: ${err}.`);
              //});
              //resolve("ok");
              //}
            } else {
              console.log("No beepruser");
              console.log(beepruser);
            }
          });
        })
        .catch((error) => {
          resolve(error.message);
        });
    });
  },
  resetEmail(email) {
    return new Promise((resolve, reject) => {
      sendPasswordResetEmail(theauth, email)
        .then(() => {
          resolve("ok");
        })
        .catch((error) => {
          resolve(error.message);
        });
    });
  },
  logout() {
    signOut(theauth)
      .then(function () {
        store.commit("SET_USER", {}, false);
        router.push("/").catch((err) => {
          throw new Error(`Problem handling router push: ${err}.`);
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  },
};
